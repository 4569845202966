import React, { useCallback, useContext, useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { Form, FormPropsContext } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormInput } from "../../../components/form/input/input.component";
import { FormUpload, UploadPreviewUrl } from "../../../components/form/input/upload.component";
import { FormSection } from "../../../components/form/section.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { getUrl } from "../../../components/form/util";
import { Currency } from "../../../entities/currency.entity";
import { Settings } from "../../../entities/settings.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType } from "../../../enums/form.enum";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsRoute } from "../routes";
import Timezones from '../timezone.data';

const timezones = Timezones.map(tz => ({ id: tz, name: tz }))

/**
 * Update general settings
 */
interface Props {
  formMode?: FormMode;
  onAfterSave?: (entity: Settings) => void;
}

export function SettingsSectionGeneral({ formMode, onAfterSave }: Props) {

  const { t } = useTranslation('settings');
  const [entity,setEntity] = useState<any>("");
  useEffect(()=>{
    getSettingDetails();
  },[])

  const getSettingDetails =async()=>{
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    setEntity(data.id)
  };
 
  const onBeforeSave =  (_: FormMode, form: Settings) => {
  
   console.log("form data before",form);
   form.entityId = entity
    form.generalTimezone = (form.generalTimezone as any)?.id;
  }

  return (
    <Form<Settings>
      endpoint={Endpoint.SETTINGS}
      returnLink={SettingsRoute.General}
      onBeforeSave={onBeforeSave}
      formMode={formMode}
      // entityId={entity}
      fileEndpoint={Endpoint.SETTINGS_FILES}
      onAfterSave={ onAfterSave }
      // formFiles={[
      //   { required: true, count: 1, originalName: 'companyLogo', name: "newCompanyLogo", desc: t("general.generalSection.logoPictureLabel") },
      // ]}
    >
      <SectionsWrapper>
        <FormHeader type={FormHeaderType.Title} title={t("general.title")} fullTitle={true} />
        <FormSections>
          <GeneralSection />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper>
        <FormHeader type={FormHeaderType.Controls} />
      </SectionsWrapper>
    </Form>
  );
}

const GeneralSection = () => {

  const childProps = useFormContext();
  const { t } = useTranslation('settings');
  const formProps = useFormContext();
  const { mode } = useContext(FormPropsContext);

  const [settings, setSettings] = useState<Settings>();
  const [currencies, setCurrencies] = useState<Currency[]>([]);


  const ref = useCallback(async() => { 
      const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
      data.generalTimezone = { id: data.generalTimezone as string, name: data.generalTimezone as string };
    
      childProps.reset(data); 
      setSettings(data); 
  }, [childProps, settings]) 

  useEffect(() => {
    ref(); 
  }, []); 

  const getProfilePicUrls = (): UploadPreviewUrl[] | undefined => {
    const form: Settings = childProps.getValues() as Settings;   
      return [getProfilePic(form)]; 
  }

  const getProfilePic = (setting: Settings): UploadPreviewUrl => {
    const company = setting?.companyLogo;   
    return {
      thumb: getUrl(`/settings/${settings?.id}/companyLogo/${company?.id}/admin_single.${company?.extension}`),
      full: getUrl(`/settings/${settings?.id}/companyLogo/${company?.id}/admin_large.${company?.extension}`),
    }
  } 

  const showlogo = () => {
    const urls = getProfilePicUrls();
    if (!urls) {
      return null
    }

    return <div className="row" style={{ background: process.env.REACT_APP_BKG, padding: 20, borderRadius: 5, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
      <img width={150} src={ urls[0].thumb } />
    </div>
  }

  return (
    <FormSection
      title={t("general.generalSection.title")}
      subtitle=""
    >
      <div className="row">
        <div className="col-sm-6">
          <FormInput
            name="generalCompany"
            label={t("general.generalSection.companyNameLabel")}
            placeholder=""
            type="text"
            maxLength={100}
            className="row"
            tabIndex={1}
            autoFocus
            validation={{ required: true }}
            {...childProps}
          />

          { showlogo() }

        </div>

        {/* <FormUpload
          // urls={getProfilePicUrls()}
          label={t("general.generalSection.logoPictureLabel")}
          description={t("general.generalSection.logoPictureDescription")}
          name="newCompanyLogo"
          originalName="companyLogo"
          className="col-md-4 col-sm-12"
          validation={{ required: true }}
          tabIndex={4}
          {...childProps}
        /> */}
      </div> 
    </FormSection>
  );
}
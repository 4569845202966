import Axios, { AxiosError, AxiosResponse } from "axios";
import { EventEmitter } from "events";
import { io, Socket } from 'socket.io-client';
import { v4 as uuid } from "uuid";
import { Settings } from "../entities/settings.entity";
import { User } from "../entities/user.entity";
import { ApiLogsSource } from "../enums/api-log.enum";
import { AuthService } from "./auth.service";
import i18n from "./i18n";
import { SettingsService } from "./settings.service";
// Endpoints

export enum Endpoint {
  ADDTWILIOCLIENTID = "user/addTwilioClientId",
  API_LOGS = 'apilogs',
  API_LOGS_TIMELINE = 'apilogs/timeline',
  API_LOGS_REQ_DATA = 'apilogs/reqData',
  ARTICLE = "article",
  ARTICLE_LIST = "article/list",
  AUDIT = 'audit/list',
  AUDIT_DATA = 'audit/getAudit',
  AUDIT_ENTITY = 'audit/getEntity',
  BalanceSheet = "report/getColumnData",
  Booking = "booking",
  CALL = 'call',
  CANCEL_BOOKING = "booking/cancelBooking",
  CONSENT = "consent",
  CONSENT_LIST = "consent/list",
  CORPORATE_ACCOUNT = "corporate/account",
  CORPORATE_ACCOUNT_LOGO = "corporateAccount/files",
  CORPORATE_ACTIVATE = "corporate/activateAccount",
  CORPORATE_DEACTIVATE = "corporate/deactivateAccount",
  CORPORATE_LIST = 'corporate/list',

  CORPORATE_USER_ACCOUNT = "corporateUser",
  CORPORATE_USER_ACTIVATE = "corporateUser/activateUser",
  CORPORATE_USER_DEACTIVATE = "corporateUser/deactivateUser",
  CORPORATE_USER_LIST = 'corporateUser/list',

  C2_INIT = "c2/init",
  C2_TRIP_SEARCH = "c2/search/trips",
  C2_SESSION_SEARCH = "c2/search/sessions",
  C2_DRIVER_SEARCH = "c2/search/drivers",
  C2_TRANSACTION_SEARCH = "c2/search/transactions",
  C2_TRIP_DETAIL_BY_ID = "c2/trip",

  CUSTOM_TOLLS="customTolls",
  CUSTOM_TOLLS_LIST = 'customTolls/list',
  CUSTOM_TOLLS__DELETE_COUNT = "customTolls/deletedCount",

  DASHBOARD_BOOKING = "dashboard/booking",
  DASHBOARD_COMPLETION = "dashboard/completion",
  DASHBOARD_REVENUESTATISTICS = "dashboard/revenue",
  DASHBOARD_SESSION = "dashboard/session",
  DASHBOARD_STATISTICS = "dashboard",
  DASHBOARD_TRACK_SESSION_GPS  = "dashboard/gpsSubscribe",
  DASHBOARD_TRIP = "dashboard/trip",

  DRIVER = "driver",
  DRIVERBYCODE = "driver/code",
  DRIVER_ENTITIES_COUNT = "driver/entitiesCount",
  DRIVER_APPROVAL_COUNT = 'driver/driverApprovalCount',
  DRIVER_DELETED_COUNT = "driver/deletedCount",
  DRIVER_Device_Get_AUTH = "driver/getDeviceAuthCode",
  DRIVER_FILES = "driver/files",
  DRIVER_FILE_TYPES = 'driver/fileTypes',
  DRIVER_FIND_AREA = "driver/findInArea",
  DRIVER_IMPORT = "driver/import",
  DRIVER_LIST = "driver/list",
  DRIVER_COUNTS = "driver/counts",
  DRIVER_TRANSACTIONS = "driver/transactions",
  DRIVER_PAYOUT = "driver/payout",
  DRIVER_PAYOUT_PENDING = "driver/payout/pending",
  DRIVER_PAY = "driver/verifyStripeDocuments",
  DRIVER_RECOVER = "driver/recoverDriver",
  DRIVER_STATISTICS = "driver/stats",
  DRIVER_RENTAL_AGREEMENT = "driver/rentalAgreement",

  EMEGENCY_MODIFY = "emergency/modify-request",
  EMERGENCY_LIST = "emergency/list",
  EMERGENCY_SAVE_RECORING = "emergency/saveRecording",
  Emergency = "emergency",
  Emergency_Alert = "emergency/alert",
  ENROLL_INVITE = 'driver/enrollInvite',
  FARE = "fare",
  FARE_ASSIGN = "fare/assign",
  FARE_DELETED_COUNT = "fare/deletedCount",
  FARE_ESTIMATE = "fare/estimate",
  FARE_GET_ZONE = "fare/getZone",
  FARE_LIST = "fare/list",
  FARE_LIST_ASSIGNMENTS = "fare/list/assignments",
  FARE_RECOVER = "fare/recoverFare",
  FEATURE = "feature",
  FEATURE_LIST = "feature/list",
  FEATURE_PUBLIC_LIST = "feature/register/list",
  FILE_DELETE = "driver/deleteImage",
  CORPORATE_FILE_DELETE = "corporate/deleteImage",
  FLEET = "fleet",
  FLEET_LIST = "fleet/list",
  FLEET_STATISTICS = "fleet/stats",
  Forgot_Password = "user/forgotPassword",
  GET_BOOKING = "booking/bookingByCode",
  GET_DRIVER_VEHICLE = 'driver/getVehicleFromSession',
  GET_IP = "driver/ip",
  GLOBAL_SEARCH = "dashboard/globalSearch",
  GPS = "gps",
  GPS_LIST = 'gps/list',
  GPS_TRACK = "gps/gpsTrack",
  GPS_DETAIL = "gps/details",
  GROUP = "group",
  GROUP_DELETED_COUNT = "group/deletedCount",
  GROUP_LIST = "group/list",
  GROUP_RECOVER = "group/recoverGroup",
  GROUP_STATISTICS = "group/stats",
  GEO_REFERENCE = "report/geoReport",
  HOTSPOT = "hotspot",
  HOTSPOT_LIST = "hotspot/list",
  ICE = "emergency/ice",
  INCIDENT = "incident",
  INCIDENT_LIST = "incident/list",
  INSPECTION = "inspection",
  INSPECTION_LIST = "inspection/list",
  LOGIN = "user/login",
  NETWORK = "network",
  NETWORK_DELETED_COUNT = "network/deletedCount",
  NETWORK_LIST = "network/list",
  NETWORK_RECOVER = "network/recoverNetwork",
  NETWORK_STATISTICS = "network/stats",
  OPERATOR = "operator",
  OPERATOR_ACTIVATE = "operator/activateOperator",
  OPERATOR_CONSENT = "operatorConsent",
  OPERATOR_CONSENT_LIST = "operatorConsent/list",
  OPERATOR_DEACTIVATE = "operator/deactivateOperator",
  OPERATOR_DELETED_COUNT = "operator/deletedCount",
  OPERATOR_LIST = "operator/list",
  OPERATOR_RECOVER = "operator/recoverOperator",
  OPERATOR_STATISTICS = "operator/stats",
  PASSENGER = "passenger",
  PASSENGER_BY_PHONE_NUMBER = "passenger/getByPhoneNumber",
  PASSENGER_CALL_HISTORY = "passenger/callHistory",
  PASSENGER_CALL_TOKEN = "passenger/callToken",
  PASSENGER_LIST = "passenger/list",
  PASSENGER_PAST_RIDES = "passenger/pastRides",
  PROTOCOL = "protocol",
  PROTOCOL_LIST = "protocol/list",
  Password_Recovery = "user/passwordRecovery",
  REGISTER = "user/register",
  REQUEST = "request",
  REQUEST_AUDIT = "request/audit",
  REQUEST_CATEGORY = "requestCategory",
  REQUEST_CATEGORY_LIST = "requestCategory/list",
  REQUEST_LIST = "request/list",
  Ride = "report/getRideData",
  SERVER_TIME = "server/time",
  SERVICE = "service",
  SERVICE_DELETED_COUNT = "service/deletedCount",
  SERVICE_FILES = "service/files",
  SERVICE_LIST = "service/list",
  SERVICE_PUBLIC_LIST = "service/public/list",
  SERVICE_RECOVER = "service/recoverService",
  SETTINGS = "settings",
  SETTINGS_CC = "settings/CC",
  SETTINGS_CURRENCIES = "settings/currencies",
  SETTINGS_CURRENCIES_LIST = "settings/currencies/list",
  SETTINGS_FILES = "settings/files",
  SETTINGS_LIST = "settings/list",
  SETTINGS_UPDATE_LANGUAGE = "settings/updateLang",
  SHIFT_COUNT = "transaction/shiftCount",
  STATS = "STATS",
  STATS_DETAILS = "STATS/details",
  SUBSCRIBER = "newsletter",
  SUBSCRIBER_LIST = "newsletter/list",
  Shift = "report/getShiftData",
  TRACK="booking/track",
  TRANSACTION_COUNT = "transaction/listCount",
  TRANSACTION_DEPOSIT  = "transaction/driverDeposit",
  TRANSACTION_GET_CHECKLIST = "transaction/getChecklist",
  TRANSACTION_GPS_LIST = "transaction/listGps",
  TRANSACTION_LIST = "transaction/list",
  TRANSACTION_RIDES_LIST = "transaction/listRide",
  TRANSACTION_RIDES_SUM = "transaction/totalRidesSum",
  TRANSACTION_SHIFT_LIST = "transaction/listShift",
  TRANSACTION_SUM = "transaction/totalSum",
  Trip_Track = "booking/tripTrack",
  UPDATE_SHIFT_STATUS = "report/paymentStatus",
  USER = "user",
  USER_DEVICE = "user/device",
  USER_LIST = "user/list",
  USER_ROLE = "user/role",
  USER_ROLE_LIST = "user/role/list",
  VEHICLE = "vehicle",
  VEHICLE_DELETED_COUNT = "vehicle/deletedCount",
  VEHICLE_FILES = "vehicle/files",
  VEHICLE_FILE_TYPES = 'vehicle/fileTypes',
  VEHICLE_FILE_DELETE = "vehicle/deleteImage",
  VEHICLE_LIST = "vehicle/list",
  VEHICLE_RECOVER = "vehicle/recoverVehicle",
  VEHICLE_RIDES = "vehicle/rides",
  VEHICLE_STATISTICS = "vehicle/stats",
  VEHICLE_TRACK = "vehicle/track",
  VERIFICATION_CODE = "user/verifyDevice",
  ZONE = "zone",
  ZONE_CURRENCIES_LIST = "zone/currencies/list",
  ZONE_DATA = "zone/getData",
  ZONE_DELETED_COUNT = "zone/deletedCount",
  ZONE_LIST = "zone/list",
  ZONE_RECOVER = "zone/recoverZone",
  ZONE_SELECTED = "zone/getSelectedZone",
  UPLOADED_FILE = "driver/saveUploadedFile",
  DELETE_UPLOADED_FILE = "driver/deleteUploadedFile",
  GPS_SHIFT = "gps/shiftGps",
  GPS_THROUGH_DRIVER_CODE = "gps/gpsWithDriverCode"
}

export enum ApiType {
  Rest,
  Socket,
}

type ApiLogData = {
  [key: string]: string | number | undefined,
  _source: ApiLogsSource; travelStart: number; userAgent: string; event: string; requestId: string; requestIp: string;
  meta?: string;
}
class ApiClass extends EventEmitter {
  private user: any = undefined;
  private readonly axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: {
      'accept-language': i18n.language || 'en'
    }
  });

  private socketClient: Socket;
  private secDifference: number = 0;
  constructor() {
    super();

    Axios.interceptors.response.use(
      (response: AxiosResponse) => {
        console.log("INTERCEPTED");
        return response.data;
      },
      (error: AxiosError) => {
        if (
          error.response?.status === undefined ||
          error.response?.status >= 500
        ) {
          console.error("API Server Error", error);
          return;
        }
      }
    );

    this.socketClient = io(process.env.REACT_APP_WS_URL as string, {
      autoConnect: true,
      transports: ['websocket']
    });

    // this.socketClient.connect();
    this._serverEvents();
  }


  async init(isReconnect:boolean = false): Promise<"error" | "initialized"> {
    return new Promise<"error" | "initialized">(async (resolve, reject) => {
      const accessToken = AuthService.getAccessToken();
      if (accessToken) {
        if(!isReconnect){
          this.socketClient.connect();
        }
        const response: { user: User, settings: Settings } = await this._emit("init", {
          accessToken: accessToken,
          lang: "en",
        });
        // this.secDifference = await this.getSecDifference();
        if ('error' in response) {
          resolve("error");
        }
        else {
          AuthService.setUser(response.user);
          SettingsService.setSetting(response.settings);
          // Api.emit('initialized');
          resolve("initialized");
        }
      }
      else resolve("error");
    });
  }

  disconnect() {
    if (this.socketClient) {
      this.socketClient.close();
    }
  }
 
  async getSecDifference(): Promise<number> {
    console.log("Getting Time difference");
    const response = await this.get<any, undefined>(Endpoint.SERVER_TIME, undefined);
    let secDifference = 0;
    if ('error' in response) {
      secDifference = 0;
    }
    else {
      const myTime = new Date().getTime();
      if (response.serverTime > myTime) {
        secDifference = Math.abs(myTime - response.serverTime);
      }
      else if (response.server < myTime) {
        secDifference = Math.abs(myTime - response.serverTime) * -1;
      }
      else secDifference = 0;
    }
    console.log("Difference in secs = ", secDifference);
    return secDifference;
  }

  async get<Response, Params>(endpoint: Endpoint, params?: Params, type = ApiType.Socket): Promise<Response> {
    if (type === ApiType.Socket) {
      const response:any=await this._emit(`get:${endpoint}`, params);
      if(response?.error){
        console.log('This jnis Error',response);
        throw response.error;
      }
      return response
    }
    const response = await this.axios.get(endpoint, { params });
    return response.data;
  }

  async post<Response, Params>(endpoint: Endpoint, data?: Params, type = ApiType.Socket, reqConfig?: any): Promise<Response> {
    if (type === ApiType.Socket) {
      const response: any = await this._emit(`post:${endpoint}`, data);
      if (response?.error) {
        throw new Error(response.error);
      }
      return response;
    }
    if (!reqConfig) {
      const response = await (await this.axios.post(endpoint, data));
      return response.data;
    }
    else {
      {
        const response = await (await this.axios.post(endpoint, data, reqConfig));
        return response.data;
      }
    }
  }

  async patch<Response, Params>(endpoint: Endpoint, data?: Params, type = ApiType.Socket): Promise<Response> {
    if (type === ApiType.Socket) {
      const response: any = await this._emit(`patch:${endpoint}`, data);
      if (response?.error) {
        throw new Error(response.error);
      }
      return response;
    }
    const response = await this.axios.patch(endpoint, data);
    return response.data;
  }

  async delete<Response, Params>(endpoint: Endpoint, data?: Params, type = ApiType.Socket): Promise<Response> {
    if (type === ApiType.Socket) {
      const response: any = await this._emit(`delete:${endpoint}`, data);
      if (response?.error) {
        throw new Error(response.error);
      }
      return response;
    }
    const response = await this.axios.delete(endpoint);
    return response.data;
  }

  async upload<Response, Params>(endpoint: Endpoint, data: Params, progressHandler?: (event: ProgressEvent) => void, type = ApiType.Rest): Promise<Response> {
    if (type === ApiType.Socket) {
      throw new Error("Websocket uploads not supported");
    }
    const headers = { authorization: `Bearer ${AuthService.getAccessToken()}`, 'Content-Type': 'multipart/form-data' };
    const response = await this.axios.post(endpoint, data, {
      headers,
      onUploadProgress: progressHandler,
      timeout: 0,
    });
    return response.data;
  }

  private _serverEvents() {
    this.socketClient.onAny((socketEvent: string, data) => {
      if (/(response:)\w|(error:)\w/g.test(socketEvent) == false) {
        switch (socketEvent) {
          case "emergency_alert":
            this.emit("emergency_alert", data);
            break;
          case "emergency_update":
            this.emit("emergency_update", data);
            break;
          case "call_alert":
            console.log('we Inside call alert:', data)
            this.emit("call_alert", data);
            break;
          case "call_update":
            this.emit("call_update", data);
            break;
          case "booking_alert":
            this.emit("booking_alert", data);
            break;
          case "booking_update":
            this.emit("booking_update", data);
            break;
          case "gps_latest_location":
            this.emit("gps_latest_location", data);
            break;
          case "modify_alert":
            this.emit("modify_alert", data)
            break;
          case "trip_alert":
            this.emit("trip_alert", data)
            break;
          case "session_alert":
            this.emit("session_alert", data);
            break;
          case "session_update":
            this.emit("session_update", data);
            break;
          case "trip_update":
            this.emit("trip_update", data);
            break;
        }
      }
    })
    this.socketClient.io.on('reconnect',()=>{
      this.init(true)
    })
  }

  private _emit<Response, Params>(event: string, data?: Params): Promise<Response> {
    return new Promise<Response>((resolve, reject) => {
      this.user = AuthService.getUser();
      const requestId = ["init", "authenticate"].includes(event) ? event : uuid();
      const completeData: ApiLogData = {
        ...data, event, requestId, requestIp: this.user?.address, _source: ApiLogsSource.ApiCore,
        travelStart: Date.now() + this.secDifference, userAgent: JSON.stringify({ email: this.user?.user?.email })
      }
      this.socketClient.emit(event, completeData);
      this.socketClient.once(`response:${requestId}`, (data) => {
        resolve(data);
      });

      this.socketClient.once(`error:${requestId}`, (error) => {
        console.log({ event: event, error: error });
        if (event = 'patch:driver') {
          resolve(error);
        }
        else{
          reject(error);
        }
      });
    });
  }
}

export const Api = new ApiClass();

import React, { useCallback, useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormPropsContext } from "../../../../components/form/form.component";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormUpload, UploadPreviewUrl } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { getUrl } from "../../../../components/form/util";
import { Settings } from "../../../../entities/settings.entity";
import { Vehicle } from "../../../../entities/vehicle.entity";
import { FormMode } from "../../../../enums/core.enum";
import { urlShow } from "../post.component";

interface props{ 
  FormEditingOnclick?: (e:any)=>void 
  settings:Settings|undefined

}

export function VehiclePostRegistration({FormEditingOnclick,settings}:props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  const { mode } = useContext(FormPropsContext);
  const urlShows = useContext(urlShow) 

  const getProfilePicUrls = useCallback((): UploadPreviewUrl[] | undefined => { 
    const form: Vehicle = formProps.getValues() as Vehicle;
    if (urlShows === false) {
      if (form.id && mode === FormMode.Editing) {
        return [getProfilePic(form)];
      }
    } 
    return undefined;
  }, [urlShows])

  useEffect(() => {
    getProfilePicUrls()
  }, [getProfilePicUrls]) 

  const getProfilePic = (vehicle: Vehicle): UploadPreviewUrl => {
    const pic = vehicle.plateFile;
    return {
      thumb: getUrl(`/vehicle/${vehicle.id}/plate/${pic?.id}/admin_single.${pic?.extension}`),
      full: getUrl(`/vehicle/${vehicle.id}/plate/${pic?.id}/admin_large.${pic?.extension}`),
    }
  }
  
  return (
    <FormSection
      title={ t("vehicle.registrationSection.title") }
      subtitle={ t("vehicle.registrationSection.subTitle") }
    >
      <div className="row">
        {/* <FormUpload
          urls={ getProfilePicUrls() }
          label={ t("vehicle.registrationSection.platePictureLabel") }
          description={ t("vehicle.registrationSection.platePictureDescription") }
          name="newPlateFile"
          originalName="plateFile"
          className="col-md-4 col-sm-12"
          validation={{required:settings?.vehicleRequirementPlatePicture?true:false}}
          FormEditingOnclick = {FormEditingOnclick}
          tabIndex={1}
          { ...formProps }
        /> */}
        <div className="col-md-4 col-sm-12">
          <FormInput
            label={ t("vehicle.registrationSection.plateNumber") }
            name="plate"
            type="text"
            validation={{required:settings?.vehicleRequirementPlateNumber?true:false}}
            tabIndex={2}
            autoFocus
            { ...formProps }
          />
          <FormInput
            label={ t("vehicle.registrationSection.plateNumber2") }
            name="plate2"
            type="text"
            validation={{required:settings?.vehicleRequirementPlateNumber?true:false}}
            tabIndex={4}
            { ...formProps }
          />
        </div>
        <div className="col-md-4 col-sm-12">
          <FormInput
            label={ t("vehicle.registrationSection.vinDigits") }
            name="vin"
            type="text"
            validation={{required:settings?.vehicleRequirementVIN?true:false}}
            tabIndex={3}
            { ...formProps }
          />
          <FormInput
            label={ t("vehicle.registrationSection.registrationExpiry") }
            name="registrationExpiryDate"
            type="date"
            validation={{required:settings?.vehicleRequirementRegistrationExpiry?true:false}}
            tabIndex={5}
            { ...formProps }
          />
        </div>
      </div>
    </FormSection>
  );

}

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAddressInput } from "../../../../components/form/input/address.component";
import { FormInput } from "../../../../components/form/input/input.component";
import { PhoneInputs } from "../../../../components/form/input/phoneNumber";
import { FormSection } from "../../../../components/form/section.component";
import { Settings } from "../../../../entities/settings.entity";

interface Props {
  value: string;
  onChange: any;
  settings: Settings | undefined
}

export function DriverPostPrivate({ value, onChange, settings }: Props) {
  const { t } = useTranslation("forms");
  const formProps = useFormContext();

  return (
    <FormSection
      title={t("driver.privateSection.title")}
      subtitle={t("driver.privateSection.subTitle")}
    >
      <div className="row">
        <FormInput
          label={t("common.email")}
          name="email"
          type="email"
          validation={{ required: settings?.driverRequirementEmail ? true : false }}
          className="col-sm-6"
          tabIndex={0}
          {...formProps}
        />
        <PhoneInputs
          placeholder={t("common.phone")}
          value={value}
          name={`phone`}
          onChange={onChange}
          className="col-sm-6"
          validation={{ required: settings?.driverRequirementPhone ? true : false }}
          tabIndex={0}
          {...formProps}
        />
      </div>
      <div className="row">
        <FormInput
          label={t("common.dob")}
          name="dateOfBirth"
          type="date"
          className="col-sm-6"
          validation={{ required: settings?.driverRequirementDateOfBirth ? true : false }}
          tabIndex={0}
          {...formProps}
        />
        <FormAddressInput
          label={t("driver.privateSection.homeAddress")}
          name="address"
          className="col-sm-6"
          validation={{ required: settings?.driverRequirementAddress ? true : false }}
          tabIndex={0}
          {...formProps}
        />

        { process.env.REACT_APP_ETAGS &&
        <>
          <FormInput
            label={t("driver.privateSection.etag1")}
            name="etag1"
            type="text"
            className="col-sm-6"
            validation={{ required: settings?.driverRequirementEtag1 ? true : false }}
            tabIndex={0}
            {...formProps}
          />

          <FormInput
            label={t("driver.privateSection.etag2")}
            name="etag2"
            type="text"
            className="col-sm-6"
            validation={{ required: settings?.driverRequirementEtag2 ? true : false }}
            tabIndex={0}
            {...formProps}
          />
        </>
        }


      </div>
      { process.env.REACT_APP_BUSSINESS_REGISTRATION_LABEL &&
        <div className="row">
          <FormInput
            label={`${process.env.REACT_APP_BUSSINESS_REGISTRATION_LABEL}`}
            name="businessRegistration"
            type="text"
            className="col-sm-6"
            validation={{ required: settings?.driverRequirementBusinessRegistration ? true : false }}
            tabIndex={0}
            {...formProps}
          />
        </div>
      }

      <div className="row">
        <FormInput
          label={t("driver.privateSection.postalCode")}
          name="postalCode"
          type="text"
          className="col-sm-6"
          validation={{ required: settings?.driverRequirementAddress ? true : false }}
          tabIndex={0}
          {...formProps}
        />
      </div>
    </FormSection>
  );
}

import { RouteProps } from "react-router-dom";
import { DriverList } from "./list/list.component";

export enum CallRoute {
  List = '/call/list',
  Post = '/call/post',
}

export const CallRouteMap = [
  {
    path: CallRoute.List,
    component: DriverList,
  },
] as RouteProps[];

import { DrawingManager, GoogleMap, Polygon } from "@react-google-maps/api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OptionsType } from "react-select";
import { FormSelect } from "../../../components/form/input/select.component";
import { FormSection } from "../../../components/form/section.component";
import { CityOption } from "../post.component";

interface City {
  id: string;
  title: string;
  geojson: Array<any>;
}

interface Props {
  cityloading: boolean;
  cities: Readonly<OptionsType<CityOption>>;
  onCitySelect: (value: any) => Promise<void>;
  onSearchChangeDebounced: (args: any) => void;
  mapCenter: google.maps.LatLngLiteral;
  onMapReady: (_map: google.maps.Map) => void;
  drawingModes: google.maps.drawing.OverlayType[];
  setManualPolygon: (polygon: google.maps.Polygon) => void;
  geography: GeoJSON.Polygon | undefined;
}

export function FindCity({
  cityloading,
  cities,
  onCitySelect,
  onSearchChangeDebounced,
  mapCenter,
  onMapReady,
  drawingModes,
  setManualPolygon,
  geography,
}: Props) {
  const childProps = useFormContext();
  const bounds = new window.google.maps.LatLngBounds();
  const [coords, setCoords] = useState<any>();  
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(mapCenter);
  let arr: any[] = [];
  const { t } = useTranslation("forms");
  const polygonRef:any = useRef(null);
  const listenersRef:any = useRef([]);


  useEffect(() => {
    const coordinates = geography?.coordinates.map((coordinate) => {
      return coordinate;
    });
    const coordinate = coordinates?.map((e) => {
      return e.map((el) => {
        bounds.extend({ lat: el[1], lng: el[0] });
        arr.push({ lat: el[1], lng: el[0] });
        return { lat: el[0], lng: el[1] };
      });
    });

    setCoords(arr);
    setCenter(bounds.getCenter().toJSON());
  }, []);

  const toListItems = (city: CityOption): CityOption => {
    return {
      id: city.id,
      label: city.label,
      geojson: city.geojson,
    };
  };

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath: any = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng:any) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setCoords(nextPath);
      setManualPolygon(polygonRef.current)
    }
  }, [setCoords]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path:any = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis:any) => lis.remove());
    polygonRef.current = null;
  }, []);


  return (
    <FormSection>
      <FormSelect<CityOption>
        label={t("zone.findCityLabel")}
        name="city"
        loading={cityloading}
        items={cities.map(toListItems)}
        validation={{ required: false }}
        showLabel={false}
        labelField="label"
        onChange={onCitySelect}
        onInput={onSearchChangeDebounced}
        tabIndex={16}
        {...childProps}
      />
      <GoogleMap
        mapContainerStyle={{ height: "500px", width: "100%" }}
        center={center}
        zoom={10}
        onLoad={(map: any) => {
          map.fitBounds(bounds);
          setCenter(bounds.getCenter().toJSON());
          onMapReady(map);
        }} 
      >
        {coords?.length>0 ? (
            <>
          <Polygon
            
            path={coords}
            onLoad={onLoad}
            onMouseUp={onEdit}
            onUnmount={onUnmount}
            editable
            options={{
              fillColor: "#000",
              fillOpacity: 0.4,
              strokeColor: "#000",
              strokeOpacity: 1,
              strokeWeight: 1,
              // editable:true,
              
              
            }}
          />  
          
        
        </>
        ) : (
          <DrawingManager
            drawingMode={drawingModes ? drawingModes[0] : null}
            onPolygonComplete={setManualPolygon}
            options={{
              polygonOptions: {
                editable: true,
                draggable: true,
                clickable: true,
                
              },
              drawingControl: true,
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes,
                
              },
            }}
          />
        )}
      </GoogleMap>
    </FormSection>
  );
}

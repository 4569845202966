import React, { useEffect, useState } from 'react';

interface Props {
  title: string;
  subtitle?: string;
  notificationCount?: number;
  loading?: boolean;
  expanded?: boolean;
  filterChildren: React.ReactNode;
  bodyChildren: React.ReactNode;
  onExpandChange?: (expanded: boolean) => void;
  onFilter?: (filter: any) => void;
};

export function MapSection({ title, subtitle, notificationCount = 0, loading, filterChildren, bodyChildren, expanded, onExpandChange, onFilter }: Props) {

  const [showFilters, setShowFilters] = useState(false);
  const [isExpanded, setIsExpanded] = useState(!!expanded);

  useEffect(() => {
    setIsExpanded(!!expanded);
  }, [expanded]);

  useEffect(() => {
    if (!isExpanded) {
      setShowFilters(false);
    }
    onExpandChange && onExpandChange(isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    if (showFilters) {
      setIsExpanded(true);
    }
  }, [showFilters]);

  return (
    <div className='map-section'>
      <h2 className='map-section-title' onClick={() => setIsExpanded(!isExpanded)}>
        <span>
          { !loading && 
          <>
            <span className='badge badge-warning'>{ notificationCount }</span>
            &nbsp;
          </>
          }
          { loading && <span className='loading'></span> }
          { title }
        </span>
        <div>
          <a className='os-icon os-icon-filter' onClick={(e) => { setShowFilters(!showFilters); e.stopPropagation(); }}></a>
          { !isExpanded ?
            <a className='os-icon os-icon-plus' onClick={() => setIsExpanded(!isExpanded)}></a>
            : <a className='os-icon os-icon-minus' onClick={() => setIsExpanded(!isExpanded)}></a>
          }
        </div>
      </h2>
      <form className={`map-section-filters ${!showFilters ? 'hidden' : ''}`} onSubmit={onFilter}>{filterChildren}</form>
      <div className={`map-section-body ${!isExpanded ? 'hidden' : ''}`}>
        { subtitle && <h3 className='map-section-subtitle'>{ subtitle }</h3> }
        {bodyChildren}
      </div>
    </div>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormInput } from "../../../components/form/input/input.component";
import { FormSection } from "../../../components/form/section.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Settings } from "../../../entities/settings.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType } from "../../../enums/form.enum";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsRoute } from "../routes";

/**
 * Update general settings
 */
export function SettingsSectionPayouts() {

  const { t } = useTranslation('payouts');
  const [entity,setEntity] = useState<any>("");
  useEffect(()=>{
  
    a();
  },[])

  const a =async()=>{
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    setEntity(data.id)
  };
 

  const onBeforeSave = (_: FormMode, form: Settings) => {
    form.entityId = entity
    form.payoutCashFee = form.payoutCashFee ? Math.round(form.payoutCashFee * 100) : 0;
    form.payoutBankFee = form.payoutBankFee ? Math.round(form.payoutBankFee * 100) : 0;
    form.payoutSoldoFee = form.payoutSoldoFee ? Math.round(form.payoutSoldoFee * 100) : 0;
  }
  
  return (
    <Form<Settings>
      endpoint={ Endpoint.SETTINGS }
      returnLink = {SettingsRoute.Payout}
      onBeforeSave={ onBeforeSave }
    >
      <SectionsWrapper>
        <FormHeader type={ FormHeaderType.Title } title="Payouts" fullTitle={ true } />
        <FormSections>
          <PayoutFeeSection />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper>
        <FormHeader type={ FormHeaderType.Controls } />
      </SectionsWrapper>
    </Form>
  );
}

const PayoutFeeSection = () => {
  
  const { t } = useTranslation('settings');
  const childProps = useFormContext();

  const [, setSettings] = useState<Settings>();

  const ref = useCallback(()=>{
    const refresh = async () => {
      const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
      data.payoutCashFee = data.payoutCashFee ? Math.round(data.payoutCashFee / 100) : undefined;
      data.payoutBankFee = data.payoutBankFee ? Math.round(data.payoutBankFee / 100) : undefined;
      data.payoutSoldoFee = data.payoutSoldoFee ? Math.round(data.payoutSoldoFee / 100) : undefined;
      childProps.reset(data);
      setSettings(data);
    };
    refresh();
  },[childProps])

  useEffect(() => {
    ref();
  }, []);

  return(
    <FormSection
      title="Payout Fees"
      subtitle="Manage fees for driver payouts"
    >
      <div className="row">
        <FormInput
          name="payoutCashFee"
          label="Cash Fee (%)"
          placeholder=""
          type="number"
          step={ 0.1 }
          className="col-sm-4"
          tabIndex={1}
          autoFocus
          validation={{}}
          { ...childProps }
        />
        <FormInput
          name="payoutBankFee"
          label="Bank Fee (%)"
          placeholder=""
          type="number"
          step={ 0.1 }
          className="col-sm-4"
          tabIndex={2}
          validation={{}}
          { ...childProps }
        />
        <FormInput
          name="payoutSoldoFee"
          label="Soldo Fee (%)"
          placeholder=""
          type="number"
          step={ 0.1 }
          className="col-sm-4"
          tabIndex={3}
          validation={{}}
          { ...childProps }
        />
      </div>
    </FormSection>
  );
}
import { IEntityBase, IEntityCreatedBy, IEntityTimestamp } from "../interfaces/entity.interface";
import { Driver } from "./driver.entity";

export enum PayoutRequestStatus {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
  Complete = "complete",
}

export declare class PayoutRequest implements IEntityBase, IEntityTimestamp, IEntityCreatedBy {
    id: string;
    provider: string;
    status: PayoutRequestStatus;
    driver: Driver | string;
    assignedTime?: Date;
    message?: string;
    createTime: Date;
    updateTime: Date;
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Trip } from "../../../../entities/trip.entity";
import { debounce } from "../../../../util/function.util";
import { Api, Endpoint } from "../../../../services/api.service";
import { Passenger } from "../../../../entities/passenger.entity";
import { Address } from "../../../../entities/address.entity";
import { formatDuration, intervalToDuration } from 'date-fns';
import { Autocomplete } from "@react-google-maps/api";
import { Zone } from "../../../../entities/zone.entity";
import { Currency } from "../../../../entities/currency.entity";
import { BookingRate, BookingStatus, BookingType } from "../../../../entities/booking.entity";
import { useHistory } from "react-router-dom";
import { getGeneralZoneDistanceTitle, getGeneralZoneDistanceValue } from "../../../../util/distance";
import { useLocation } from "react-router-dom";

interface DashboardMapDispatchInterface {
  map: google.maps.Map,
  setPickupPlace: React.Dispatch<React.SetStateAction<google.maps.places.PlaceResult>>,
  setDropoffPlace: React.Dispatch<React.SetStateAction<google.maps.places.PlaceResult>>,
  setPickupMarker: React.Dispatch<React.SetStateAction<boolean>>,
  setDropOffMarker: React.Dispatch<React.SetStateAction<boolean>>,
  distance: number | undefined,
  duration: number | undefined,
  estimate: number,
  service: { value: string | undefined; label: string; } | undefined,
  zoneCurrent: Zone | undefined,
  setZoneCurrent: React.Dispatch<React.SetStateAction<Zone | undefined>>,
  setGeneralCurrency: React.Dispatch<React.SetStateAction<Currency | undefined>>,
  setPhone: React.Dispatch<React.SetStateAction<string>>,
  setPaxFirstName: React.Dispatch<React.SetStateAction<string>>,
  setPaxLastName: React.Dispatch<React.SetStateAction<string>>,
  phone: string,
  paxFirstName: string,
  paxLastName: string,
  setCircle: React.Dispatch<React.SetStateAction<{ lat: number, lng: number } | null>>
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: any
}

export function DashboardMapDispatch({ map, estimate, setCircle, setPickupPlace, setDropoffPlace, setPickupMarker, setDropOffMarker, setGeneralCurrency, setZoneCurrent, setPaxFirstName, setPaxLastName, setPhone, phone, paxFirstName, paxLastName, service, distance, duration, zoneCurrent }: DashboardMapDispatchInterface) {

  let pickupAutocompleteRef = useRef<Autocomplete>(null);
  let dropoffAutocompleteRef = useRef<Autocomplete>(null);
  let history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("number");
  const [paxId, setPaxId] = useState<string>('');

  const [pickupPlaceFormatted, setPickupPlaceFormatted] = useState<Address>();
  const [dropoffPlaceFormatted, setDropoffPlaceFormatted] = useState<Address>();
  const [alerting, setAlerting] = useState(false);
  const [showDispatch, setShowDispatch] = useState(false);
  const [paxPickupAddress, setPaxPickupAddress] = useState<{ address: Address }>();
  const [paxDropofAddress, setPaxDropofAddress] = useState<{ address: Address }>();
  const [paxRecentAddresses, setPaxRecentAddresses] = useState<{ address: Address }[]>();

  useEffect(() => {
    if (phone && phone.length > 10 && !alerting) {
      setShowDispatch(true);
    }
  }, [phone]);

  function onAccept() {
    setAlerting(false);
    setShowDispatch(true);
  }
  function onReject() {
    setAlerting(false);
    setPhone('');
  }

  useEffect(() => {
    if(!param) return;
    const number = getByPhoneNumber(search);
    setPhone(JSON.stringify(number));
}, [param]);

  const getByPhoneNumber = async (p: string) => {
    try {
      if (p && p.length > 6) {
        const { passenger, passengerRecentAddresses, passengerLastRides } = await Api.get<{ passenger: Passenger, passengerRecentAddresses: { address: Address }[], passengerLastRides: Trip[] }, { phoneNumber: string | undefined }>(Endpoint.PASSENGER_BY_PHONE_NUMBER, { phoneNumber: p })
        setPaxFirstName(passenger.firstName);
        setPaxLastName(passenger.lastName);
        setPaxId(passenger.id);
        setPaxRecentAddresses(passengerRecentAddresses);
        for (let i = 0; i < passengerLastRides.length; i++) {
          const item = passengerLastRides[i];
        }
      }
    }
    catch (Err) {
      console.log('Passenger Not exist');
    }
  }


  const onAddressSelect = async (type: 'dropoff' | 'pickup') => {
    const ref: React.RefObject<Autocomplete> = type === 'dropoff' ? dropoffAutocompleteRef : pickupAutocompleteRef;
    const areaTypes = ['locality', 'sublocality_level_1', 'sublocality', 'political'];
    const autocomplete: google.maps.places.Autocomplete | null | undefined = ref.current?.state.autocomplete;
    if (!autocomplete) { return; }
    var city, state, country;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const area = await (autocomplete.getPlace().address_components as AddressComponent[]).find((component: AddressComponent) => {
      for (let areaType of areaTypes) {
        if (component.types.includes(areaType)) {
          return true;
        }
      }
      return false;
    });

    autocomplete.getPlace().address_components?.map((component: AddressComponent) => {
      if ((component.types.indexOf("locality") > -1)) {
        city = component.long_name;
      }

      if ((component.types.indexOf("postal_town") > -1)) {
        city = component.long_name;
      }

      if (component.types.indexOf("administrative_area_level_1") > -1) {
        state = component.short_name;
      }

      if (component.types.indexOf("country") > -1) {
        country = component.long_name;
      }
    });
    const place: google.maps.places.PlaceResult = autocomplete.getPlace();
    if (!place.geometry || !place.geometry.location) { alert('Insufficient Address Information...'); return; }
    if (type === "dropoff") {

      setDropoffPlace(place);

      setDropoffPlaceFormatted({
        lat: place.geometry?.location?.lat() as number,
        lng: place.geometry?.location?.lng() as number,
        text: place.formatted_address || "",
        // area: area.long_name, 
        placeId: place.place_id
      });
      setPaxDropofAddress({
        ...paxDropofAddress, address: {
          ...paxDropofAddress?.address,
          text: place.formatted_address
        } as Address
      })
      setDropOffMarker(false)
    }
    else {
      setPickupPlaceFormatted({
        lat: place.geometry?.location?.lat() as number,
        lng: place.geometry?.location?.lng() as number,
        text: place.formatted_address || "",
        // area: area.long_name, 
        placeId: place.place_id
      });
      setPickupMarker(false);
      setPickupPlace(place);
      getZoneByLocation(place);
      setPaxPickupAddress({
        ...paxPickupAddress, address: {
          ...paxPickupAddress?.address,
          text: place.formatted_address
        } as Address
      })
      findInArea(place)
    }
  }

  const getByPhoneNumberDebounced = useCallback(debounce(getByPhoneNumber, 500), []);

  useEffect(() => {
    getByPhoneNumberDebounced(phone)
  }, [phone]);

  const getZoneByLocation = async (pickupLocation: google.maps.places.PlaceResult) => {
    try {
      if (!pickupLocation.geometry || !pickupLocation.geometry.location) return;
      const data = await Api.post<Zone, { lat: number, lng: number }>(Endpoint.FARE_GET_ZONE, {
        lat: pickupLocation.geometry.location?.lat(),
        lng: pickupLocation.geometry.location?.lng()
      });
      if (!data?.generalCurrency) { alert("Out Of Zone") }
      else {
        setGeneralCurrency(data.generalCurrency);
        setZoneCurrent(data)
      }
    }
    catch (err) {
      console.log('Error Inside Zone:', err);
    }
  }

  const onSelectingRecentAddress = async (item: { address: Address }, type: 'dropoff' | 'pickup') => {
    new google.maps.places.PlacesService(map as google.maps.Map).findPlaceFromQuery({
      query: item.address.text,
      fields: ['all']
    }, (result) => {
      if (!result) return
      const place = result[0]
      if (type === 'pickup') {
        setPickupMarker(false);
        setPickupPlace(place);
        getZoneByLocation(place);
        findInArea(place)
        setPaxPickupAddress(item)
      }
      else {
        setDropoffPlace(place);
        setDropOffMarker(false)
        setPaxDropofAddress(item)

      }
    })
  }

  const findInArea = async (place: google.maps.places.PlaceResult) => {
    try {
      const findRequest = async () => {
        if (!place.geometry || !place.geometry.location) return;
        const gpsData = {
          latitude: place.geometry.location?.lat(),
          longitude: place.geometry.location?.lng(),
        }
        setCircle({ lat: gpsData.latitude, lng: gpsData.longitude })
      }
      findRequest();
      // findInAreaInterval = setInterval(findRequest, 30000);
    }
    catch (err) {
      console.log("FIND IN AREA FAILING DUE TO = ", err);
    }
  }
  const onCreatePassenger = async () => {
    try {
      if (paxFirstName && paxLastName && phone) {
        const response = await Api.post<Passenger, any>(Endpoint.PASSENGER, { firstName: paxFirstName, lastName: paxLastName, phone })
        if (response) {
          setPaxId(response.id)
          return response.id
        }
      }
      return undefined;
    } catch (error) {
      console.log('Passenger Creation Error:', error)
    }
  }

  const durationText = (): string => {
    if (!duration) {
      return 'N/A';
    }
    const epoch = new Date(0)
    const secondsAfterEpoch = new Date(duration * 1000)
    const durationObj = intervalToDuration({
      start: epoch,
      end: secondsAfterEpoch
    })
    return formatDuration(durationObj, { format: ['hours', 'minutes'] });
  }

  const onHandleClick = async (e: any) => {
    e.preventDefault();
    if (!service) { alert('Select a default service first!'); return; }
    if (!zoneCurrent) { alert('Unable to find zone, Please make sure you selected correct location'); return; }
    try {
      let passengerIdData = paxId || await onCreatePassenger();
      if (!passengerIdData) { alert('Please check your passenger details.'); return; }
      //@ts-ignore
      if (!pickupPlaceFormatted || !dropoffPlaceFormatted) return;
      const pickupAddress: Address = {
        lat: pickupPlaceFormatted.lat, lng: pickupPlaceFormatted?.lng, text: pickupPlaceFormatted?.text, area: pickupPlaceFormatted?.area, city: pickupPlaceFormatted?.city,
        state: pickupPlaceFormatted?.state,
        country: pickupPlaceFormatted?.country
      }
      //@ts-ignore
      const dropoffAddress: Address = {
        lat: dropoffPlaceFormatted?.lat, lng: dropoffPlaceFormatted?.lng, text: dropoffPlaceFormatted?.text, area: dropoffPlaceFormatted?.area, city: dropoffPlaceFormatted?.city,
        state: dropoffPlaceFormatted?.state,
        country: dropoffPlaceFormatted?.country
      };
      const estimateValue = estimate// * ((generalCurrency as Currency).subunits)
      //@ts-ignore

      const data = await Api.post<Booking, Booking>(Endpoint.Booking, {
        peerId: '123e4567-e89b-12d3-a456-426614174000',
        distance: distance || 0, duration: durationText(),
        estimate: estimateValue, dropOffAddress: dropoffAddress, pickupAddress: pickupAddress, passenger: passengerIdData,
        service: service.value, status: BookingStatus.Pickup, type: BookingType.Dispatch, rate: BookingRate.Meter,
        zone: (zoneCurrent as Zone).id as string
      });
      history.push(`/security/track/${data.code}`);
      setPaxId('');
      return undefined;
    } catch (error) {
      alert('Something went wrong.')
      console.log('booking error:', error);
      return undefined;
    }
  }

  const distanceText = (): string => {
    if (!distance) {
      return 'N/A';
    }
    return `${roundTo(distance / getGeneralZoneDistanceValue())} ` + `${getGeneralZoneDistanceTitle()}`;
  }

  const estimateText = (): string => {
    console.log('final estimate: ', estimate)
    if (!estimate || isNaN(estimate)) {
      return '...';
    }
    return estimate.toFixed(2).toString()
  }

  const roundTo = (val: number): number => {
    return Math.round((val + Number.EPSILON) * 100) / 100
  }

  return (
    <div className='map-dispatch-wrapper map-flex-grow'>
      <div className={`map-dispatch-alert-wrapper ${alerting ? 'alerting' : ''}`}>
        <input type="phone" placeholder='Dispatch Ready' value={phone} onChange={(e) => setPhone(e.target.value)} />
        {alerting && (
          <div className='map-dispatch-alert'>
            <h2 className='map-dispatch-alert-title'>Incoming Call</h2>
            <div className='map-dispatch-alert-actions'>
              <button className='alert-accept' onClick={onAccept}>Accept</button>
              <button className='alert-reject' onClick={onReject}>Reject</button>
            </div>
          </div>
        )}
      </div>
      {showDispatch && (
        <div className='map-dispatch-form'>
          <div className='map-dispatch-form-field'>
            <label>First Name</label>
            <input data-lpignore type='text' placeholder='' value={paxFirstName} onChange={e => setPaxFirstName(e.currentTarget.value)} />
          </div>
          <div className='map-dispatch-form-field'>
            <label>Last Name</label>
            <input type='text' placeholder='' value={paxLastName} onChange={e => setPaxLastName(e.currentTarget.value)} />
          </div>
          <div className='map-dispatch-form-field'>
            <label>Pickup Address</label>
            <Autocomplete fields={['name', 'geometry', "address_component", "formatted_address"]} ref={pickupAutocompleteRef}
              onLoad={() => {
                pickupAutocompleteRef.current?.state.autocomplete?.addListener('place_changed', () => onAddressSelect('pickup'));
              }}
            >
              <input
                required
                placeholder={''}
                type="search"
                value={paxPickupAddress?.address?.text}
                onChange={(e) => {
                  setPaxPickupAddress({
                    ...paxPickupAddress, address: {
                      ...paxPickupAddress?.address,
                      text: e.target.value
                    } as Address
                  })
                }
                }
              />
            </Autocomplete>
          </div>
          <div className='map-dispatch-form-field'>
            <label>Dropoff Address</label>
            <Autocomplete fields={['name', 'geometry', "address_component", "formatted_address"]} ref={dropoffAutocompleteRef}
              onLoad={() => {
                dropoffAutocompleteRef.current?.state.autocomplete?.addListener('place_changed', () => onAddressSelect('dropoff'));
              }}>
              <input
                required
                placeholder={''}
                type="search"
                value={paxDropofAddress?.address?.text}
                onChange={(e) => {
                  setPaxDropofAddress({
                    ...paxDropofAddress, address: {
                      ...paxDropofAddress?.address,
                      text: e.target.value
                    } as Address
                  })
                }}
              />

            </Autocomplete>
          </div>
          <div className='map-dispatch-book-wrapper'>
            <button className='map-dispatch-book' onClick={(e) => onHandleClick(e)}>Book</button>
            <button className='map-dispatch-book' style={{ backgroundColor: 'white', color: 'black', borderWidth: 2, borderColor: 'black', borderStyle: 'solid' }} onClick={() => { setShowDispatch(false); setPhone('') }}>Cancel</button>
          </div>
          {distance && (<div className={'estimate estimate-main-box'}>
            <div>
              <div className="estimate-box">
                <h4>Distance</h4>
                <p>{distanceText()} </p>
              </div>
              <div className="estimate-box">
                <h4>Duration</h4>
                <p>{durationText()}</p>
              </div>
            </div>
            <div className="estimate-box hero">
              <p> £{estimateText()}</p>
            </div>
          </div>)}
          <div className='map-dispatch-address-lists'>
            <div className='map-dispatch-address-list'>
              <h3>Last Dropoff</h3>
              <ul>
                <li>
                  <div>Sylvester Rd, London E8 1ET, UK</div>
                  <div>
                    <button className='map-dispatch-address-pickup'>Pickup</button>
                    <button className='map-dispatch-address-dropoff'>Dropoff</button>
                  </div>
                </li>
              </ul>
            </div>
            <div className='map-dispatch-address-list'>
              <h3>Frequently Used Addresses</h3>
              <p className="map-dispatch-address-empty">No addresses found</p>
            </div>
            <div className='map-dispatch-address-list'>
              <h3>Recent Addresses</h3>
              <ul>
                {paxRecentAddresses && paxRecentAddresses.map((address) => (
                  <li key={address.address.id} >
                    <div>{address.address.text}</div>
                    <div>
                      <button className='map-dispatch-address-pickup' onClick={() => { onSelectingRecentAddress(address, "pickup"); }}>Pickup</button>
                      <button className='map-dispatch-address-dropoff' onClick={() => { onSelectingRecentAddress(address, "dropoff"); }}>Dropoff</button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
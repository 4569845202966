import axios from "axios";

var polyline = require('@mapbox/polyline');

export function decodePolyLine(value: string) {
    if (value) {
      const decodedValue = polyline.decode(value, 5)
      return decodedValue
    }
  }

  function* chunks(arr:any, n:any) {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  }

  export async function runSnapToRoad(trackLine: any) {
    // Taking path and snapping on the road using google api
    const path = trackLine.getPath()
    var pathValues = [];
    let points: any = [];
    for (var i = 0; i < path.getLength(); i++) {
      pathValues.push(path.getAt(i).toUrlValue());
    }
    // need to convert into chunks as snapToRoad takes 100 points in a api call
    let pathData: any = [...chunks(pathValues, 100)];

    for (var k = 0; k < pathData.length; k++) {
      const result: any = await axios.get(
        "https://roads.googleapis.com/v1/snapToRoads",
        {
          params: {
            interpolate: true,
            key: process.env.REACT_APP_GOOGLE_API_KEY,
            path: pathData[k].join("|"),
          },
        }
      );
      points.push(...result.snappedPoints);
    }

    const result = processSnapToRoadResponse(points);
    trackLine?.setPath(result);
  }

  function processSnapToRoadResponse(data:any) {
    const snappedCoordinates = [];
    const placeIdArray = [];
    for (var i = 0; i < data.length; i++) {
      var latlng = new google.maps.LatLng(
          data[i].location.latitude,
          data[i].location.longitude);
      snappedCoordinates.push(latlng);
      placeIdArray.push(data[i].placeId);
    }
    return snappedCoordinates
  }
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatsComponent } from '../../../components/stats/stats.component';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Network } from '../../../entities/network.entity';
import { getDatesForFilters, StatsFilterPeriod, StatsRequest, StatsResponse } from '../../../interfaces/stats.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { getCurrencyFormatting, getGeneralDistanceFormat, getGeneralTimeFormat } from '../../../util/distance';

interface Props {
  selectedNetwork: Network    
  onRefresh?: () => void,
  setSelectedNetwork?:(network:undefined|Network)=>void
}

export function SelectedItemContent({ selectedNetwork, onRefresh, setSelectedNetwork }: Props) {
  const { t } = useTranslation(['forms', 'dashboard']);
  const [stats, setStats] = useState<StatsResponse>();
  const [time, setTime] = useState<StatsFilterPeriod>(StatsFilterPeriod.Today);

  const refreshStats = useCallback(async (customStart?: Date, customEnd?: Date) => {
    let startDate: Date, endDate: Date, timeline = time;
    if (customStart && customEnd) {
      startDate = customStart; endDate = customEnd; timeline = StatsFilterPeriod.Custom;
    }
    else ({ startDate, endDate } = getDatesForFilters(time));
    const response = await Api.get<StatsResponse, StatsRequest>(Endpoint.STATS, {
      startDate, endDate, timeline, network: selectedNetwork.id
    });

    console.log('stats:', response)
    setStats(response);
  }, [time, selectedNetwork]);

  const onclickRecover = async (selectedNetwork: Network) => {
    console.log('Recover This Network:', selectedNetwork);
    const response = await Api.post<Network, { id: string }>(Endpoint.NETWORK_RECOVER, { id: selectedNetwork.id as string });
    console.log('Recover This Network Response:', response)
    if (onRefresh && setSelectedNetwork) {
      setSelectedNetwork(undefined);
      onRefresh();
    }
  }

  useEffect(() => {
    refreshStats();
  }, [refreshStats])

  function onCustomSelect(startDate: Date, endDate: Date) {
    refreshStats(startDate, endDate);
  }

  return (
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={selectedNetwork.title}
      >
        <ViewContentItems>
        {selectedNetwork.deletedTime ? <div className="alert alert-success borderless">
            <h5 className="alert-heading">Network Deleted</h5>
            <p>Do you want to recover this network data?</p>
            <div className="alert-btn">
              <a className="btn btn-white-gold" href={'#href'} onClick={() => onclickRecover(selectedNetwork)}>
                <i className="os-icon os-icon-ui-92"></i>
                <span>{'Recover Network'}</span>
              </a>
            </div>
          </div> : null
          }
          <ViewContentItem title={t('forms:network.basicSection.title')}>
            {selectedNetwork.title}
          </ViewContentItem>

          <ViewContentItem title={t('forms:network.basicSection.contactPerson')}>
            {selectedNetwork.contactPerson}
          </ViewContentItem>

          <ViewContentItem title={t('forms:network.basicSection.contactPhone')}>
            {selectedNetwork.contactPhone}
          </ViewContentItem>

          <ViewContentItem title={t('forms:network.basicSection.contactEmail')}>
            {selectedNetwork.contactEmail}
          </ViewContentItem>
          <ViewContentItem title={t('forms:network.basicSection.businessRegistration')}>
            {selectedNetwork.businessRegistration}
          </ViewContentItem>

          <ViewContentItem title={t('forms:network.basicSection.address')}>
            {selectedNetwork.address?.text}
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent>
      <div className="col-md-6">
          <StatsComponent currentRange={time} onRangeChange={(e) => setTime(e.target.value)} onCustomSelect={onCustomSelect} data={!stats ? []: [
            { label: t('driver.stats.rides'), value: stats.tripCount, isPositive: stats.tripCount >= stats.prevTripCount, percentage: stats.tripCountPercentage },
            { label: t('driver.stats.distance'), value: getGeneralDistanceFormat(stats.distance), isPositive: stats.distance >= stats.prevDistance, percentage: stats.distancePercentage },
            { label: t('driver.stats.earnings'), value: getCurrencyFormatting(stats.revenue), isPositive: stats.revenue >= stats.prevDistance, percentage: stats.revenuePercentage },
            { label: t('driver.stats.shiftTime'), value: getGeneralTimeFormat(stats.rideTime), isPositive: stats.rideTime >= stats.prevRideTime, percentage: stats.rideTimePercentage }
          ]}/>
      </div>
    </ContentWrapper>
  );
}
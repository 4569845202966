import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use";
import {
  GroupFilter,
  ListFilter,
  StatusFilter
} from "../../../components/list/filter.component";
import {
  ListItem,
  ListItems
} from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { Driver, DriverStatus } from "../../../entities/driver.entity";
import { FormMode } from "../../../enums/core.enum";
import MaleImg from '../../../img/male.png';
import { Api, Endpoint } from "../../../services/api.service";

export enum ProfilePicSize {
  List = "admin_list",
  Single = "admin_single",
}

const groupFilters: GroupFilter[] = [
  { title: "all", icon: "phone-21" },
  // { title: "new", icon: "alert-circle" },
  // { title: "active", icon: "user-check" },
  // { title: "pending-approval", icon: "edit-1" },
  // { title: "pending-data", icon: "newspaper" },
  // { title: "failed", icon: "cancel-circle" },
  // { title: "blocked", icon: "user-x" },
];

const statusFilters: StatusFilter[] = [
  // { title: "online", status: ListItemStatus.Green },
  // { title: "on-trip", status: ListItemStatus.Blue },
  // { title: "offline", status: ListItemStatus.Red },
];

export function DriverList() {
  const { t } = useTranslation("list");
  const { y: pageYOffset } = useWindowScroll();

  const [changeStatus, setChangeStatus] = useState<DriverStatus | undefined>(undefined)
  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(25);
  const [count, setCount] = useState<number>(1);
  const [drivers, setDrivers] = useState<Driver[] | undefined>(undefined);
  const [selectedDriver, setSelectedDriver] = useState<Driver>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [log, setLog] = useState([]);
  const [callData, setCallData] = useState([]);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(
    groupFilters[0]
  );
  const [activeStatusFilter, setActiveStatusFilter] = useState<
    StatusFilter | undefined
  >(undefined);

  const refresh = useCallback(async () => {
    let group: string | undefined = activeGroupFilter?.title;
    let arr: any = [];
    let res1 = await Api.get<any, any>(Endpoint.PASSENGER_CALL_HISTORY, { limit: limit, search: search })
    res1 = JSON.parse(res1);
    res1.forEach((element: any) => {

      if (element.direction == 'inbound') {
        arr.push(element);
      }

    });

    console.log("RESSS", arr);
    setCallData(arr);

    group = group === 'all' ? undefined : group;
    const status = activeStatusFilter?.title;
    console.log('statusssss', status)
    console.log('limittttt', limit);
    // const { items } = await Api.get<
    //   IPaginationResponse<Driver>,
    //   IPaginationOptions
    // >(Endpoint.DRIVER_LIST, { page: 0, limit, status, group, search });
    // console.log('limit DAta', items);

    if (callData) {
      // console.log('ITemsssss', items);
      setDrivers(callData);
    }

    if (activeGroupFilter.title === DriverStatus.Blocked) {
      setChangeStatus(DriverStatus.Active)
    } else if (activeGroupFilter.title === DriverStatus.Active) {
      setChangeStatus(DriverStatus.Blocked)
    } else {
      setChangeStatus(undefined)
    }

    if (callData.length > 0 && !selectedDriver) {
      setSelectedDriver(callData[0]);
    }
  }, [search, activeGroupFilter, limit, activeStatusFilter, selectedDriver]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleScroll = useCallback(() => {
    if (pageYOffset > count * 150) {
      console.log("PAGEOFFSET");
      setLimit(limit + 5);
      setCount(count + 1);
    }
  }, [count, pageYOffset, limit])


  useEffect(() => {

    window.addEventListener('scroll', handleScroll);
    return (() => {
      window.removeEventListener('scroll', handleScroll)
    })
  }, [pageYOffset, handleScroll])


  const deleteDriver = async () => {
    await Api.delete(Endpoint.DRIVER, { id: selectedDriver?.id });
    setSelectedDriver(undefined);
    refresh();
  };

  const getProfilePic = (driver: Driver, type: ProfilePicSize): string => {
    return MaleImg;
  };

  const getFullName = (driver: Driver): string => {
    return [driver.firstName, driver.lastName].join(" ");
  };

  const addDriver = () => {
    setFormMode(FormMode.Adding);
  };

  const editDriver = () => {
    setFormMode(FormMode.Editing);
  };

  const cancelEditing = () => {
    setFormMode(undefined);
  };

  const onNext = () => {
    if (drivers) {
      const currentDriverIndex = drivers.findIndex(
        (driver) => !!selectedDriver && driver.id === selectedDriver.id
      );
      if (
        currentDriverIndex + 1 < drivers.length &&
        currentDriverIndex !== -1
      ) {
        setSelectedDriver(drivers[currentDriverIndex + 1]);
      }
    }
  };

  const onPrev = () => {
    if (drivers) {
      const currentDriverIndex = drivers.findIndex(
        (driver) => !!selectedDriver && driver.id === selectedDriver.id
      );
      if (currentDriverIndex - 1 > -1 && currentDriverIndex !== -1) {
        setSelectedDriver(drivers[currentDriverIndex - 1]);
      }
    }
  };

  const toListItems = (call?: any): ListItem<Driver> | undefined => {
    return {
      id: call?.sid as string,
      title: call?.from,
      imgSrc: getProfilePic(call, ProfilePicSize.List),
      // status: status ? status : ListItemStatus.Yellow,
      ref: call,
    };
  };

  const selectGroup = (target: GroupFilter) => {
    setSelectedDriver(undefined);
    setActiveGroupFilter(target);
  };

  const selectStatus = (target: StatusFilter | undefined) => {
    setSelectedDriver(undefined);
    setActiveStatusFilter(
      target?.title !== activeStatusFilter?.title ? target : undefined
    );
  };

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  const statusChange = async () => {
    if (selectedDriver?.status === DriverStatus.Active) {
      await Api.patch(Endpoint.DRIVER, { id: selectedDriver.id, status: selectedDriver.status = DriverStatus.Blocked })
    } else if (selectedDriver?.status === DriverStatus.Blocked) {
      await Api.patch(Endpoint.DRIVER, { id: selectedDriver.id, status: selectedDriver.status = DriverStatus.Active })
    }
  }

  return (
    <List>
      <ListFilter
        groupFilters={groupFilters}
        statusFilters={statusFilters}
        activeGroupFilter={activeGroupFilter}
        activeStatusFilter={activeStatusFilter}
        onGroupSelect={selectGroup}
        onStatusSelect={selectStatus}
      />
      <ListItems
        items={callData?.map(toListItems) as ListItem<Driver>[]}
        activeItem={toListItems(selectedDriver)}
        showSearch={true}
        showRefresh={true}
        onSearchChange={onSearchChange}
        onRefresh={() => {
          setDrivers(undefined);
          refresh();
        }}
        onClick={(item: any) => {
          setSelectedDriver(item.ref);
        }}
      />
    </List>
  );
}

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCheckBox } from "../../../components/form/input/checkbox.component";
import { FormInput } from "../../../components/form/input/input.component";
import { FormUpload } from "../../../components/form/input/upload.component";
import { FormSection } from "../../../components/form/section.component";
import { Role, RoleAssignment } from "../../../entities/role.entity";
import { AuthService } from "../../../services/auth.service";

export function AddService() {

  const [checked, setChecked] = useState<boolean>(false);
  const [defaultAccess, setDefaultAccess] = useState<boolean>(false);
  const setDefault = () => {
    const { user }: any = AuthService.getUser();
    const driverRole = (user?.role as Role)?.assignments?.map((item: RoleAssignment) => {
      if (item.module == 'service' && item.action == 'set-default') {
        console.log('Inside Set Default Access')
        setDefaultAccess(true)
      }
    })
  }
  useEffect(() => {
    setDefault();
  }, [])

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  return (
    <FormSection
      title=""
      subtitle=""
    >
      <div className="row">
        {/* <FormUpload
          label={t("service.modelSection.title")}
          name="newProfileFiles"
          originalName="profileFiles"
          description={t("service.modelSection.subTitle")}
          className="col-md-12"
          multiple={true}
          validation={{ required: true }}
          tabIndex={1}
          {...formProps}
        /> */}
      </div>
      <FormInput
        name="title"
        label={t("common.title")}
        type="text"
        tabIndex={2}
        autoFocus
        validation={{ required: true }}
        {...formProps}
      />
      {defaultAccess ? <FormCheckBox
        name="isDefault"
        label={t("common.default")}
        checked={checked}
        onChange={handleChange}
        tabIndex={3}
        validation={{ required: false }}
        {...formProps}
      /> : null}
    </FormSection>
  );

}

import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormUpload, UploadPreviewUrl } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { getUrl } from "../../../../components/form/util";
import { IFileType } from "../../../../entities/file-type.entity";
import { Vehicle } from "../../../../entities/vehicle.entity";
import { IFile } from "../../../../entities/file.entity";

interface props{ 
  vehicle?: Vehicle;
  fileTypes: IFileType[];
}

export function VehiclePostDocuments({ vehicle, fileTypes }:props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const fileToUrl = (fileType: IFileType): UploadPreviewUrl[] | undefined => {
    if (!vehicle) {
      return;
    }
    const files = vehicle.files?.filter((file: { file: IFile }) => file.file.fileType?.id === fileType.id).map((file: { file: IFile }) => file.file);
    if (!files || files.length === 0) {
      return undefined;
    }
    return files.map((file: IFile) => ({
      thumb: getUrl(`/vehicle/${vehicle.id}/${file.fileType!.id}/${file.id}/admin_single.${file.extension}`),
      full: getUrl(`/vehicle/${vehicle.id}/${file.fileType!.id}/${file.id}/admin_large.${file.extension}`),
    }))
  }

  return (
    <FormSection
      title={t("vehicle.documentSection.title")}
      subtitle={t("vehicle.documentSection.subTitle")}
    >
      <div className="row">
        {fileTypes.map((fileType, index) => (
          <FormUpload
            key={fileType.id}
            fileType={fileType}
            className="col-md-6"
            urls={fileToUrl(fileType)}
            // FormEditingOnclick={FormIdentityFrontOnclick}
            tabIndex={index + 22}
            {...formProps}
          />
        ))}
      </div>
    </FormSection>
  );

}


import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OptionsType } from "react-select";
import { Form } from "../../components/form/form.component";
import { FormHeader } from "../../components/form/header.component";
import { RadioOption } from "../../components/form/input/radio.component";
import { FormSections, SectionsWrapper } from "../../components/form/sections.component";
import { Currency } from "../../entities/currency.entity";
import { Settings } from "../../entities/settings.entity";
import { Zone } from "../../entities/zone.entity";
import { FormMode } from "../../enums/core.enum";
import { Enviroment } from "../../enums/environment.enum";
import { FormHeaderType, FormStyle } from "../../enums/form.enum";
import { Api, Endpoint } from "../../services/api.service";
import { SettingsService } from "../../services/settings.service";
import { debounce } from "../../util/function.util";
import { ZoneRoute } from "./routes";
import { BasicSettings } from "./section/basic.component";
import { ZoneInstructions } from "./section/instructions.component";
import { FindCity } from "./section/map.component";
import { MeterSection } from "./section/meter.component";
import { paymentMethods, PaymentSection } from "./section/payment.component";
export interface CityOption {
  label: string;
  id: string;
  geojson: Array<any>;
}

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  selectedZone?:Zone,
  onAfterSave?: (entity: Zone) => void;
}

const OSM_URL = "https://nominatim.openstreetmap.org/search.php";
export let leavy: number | undefined;
export let vat: number | undefined;

export function ZonePost({
  style,
  cancel,
  formMode,
  entityId,
  selectedZone,
  onAfterSave,
}: Props) {
  const [map, setMap] = useState<google.maps.Map>();
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
    lat: -33.847927,
    lng: 150.651776,
  });
  const bounds = new window.google.maps.LatLngBounds();
  const [cityloading, setCityLoading] = useState<boolean>(false);
  const [cities, setCities] = useState<OptionsType<CityOption>>([]);
  const [drawingModes, setDrawingModes] = useState<google.maps.drawing.OverlayType[]>([google.maps.drawing.OverlayType.POLYGON]);
  const [geography, setGeography] = useState<GeoJSON.Polygon>();
  const [currencies, setCurrencies] = useState<RadioOption[]>();
  const [levy, setLevy] = useState<number>();
  const [subUnits, setSubunits] = useState<number>();

  const { t } = useTranslation(["forms", "main"]);

  useEffect(()=>{
    if(selectedZone){
      setGeography(selectedZone.geography)
    }
  },[])

  useEffect(updatePolygon, [geography]);

  useEffect(() => {
    const getFormData = async () => {
      const data = await Api.get<any, { id: string }>(Endpoint.ZONE_DATA, { id: entityId as string });
  
      if (data) {
        setCurrencies(data.generalCurrency);
  
        const selectedCurrency = data.generalCurrency.filter((currency: any) => currency.selected)[0];
        setSubunits(selectedCurrency.subunits);

        if (data.levy && selectedCurrency) {
          setLevy(data.levy / selectedCurrency.subunits);
        }
      }
    }

    if (entityId) {
      getFormData();
    }
  }, [entityId]);

  function updatePolygon() {
    if (!map) {
      return;
    }

    map.data.forEach((data) => {
      map.data.remove(data);
    });

    if (!geography) {
      return;
    }

    setDrawingModes([]);

    map.data.setStyle({
      editable: true,
    });

    map.data.addGeoJson(
      {
        type: "Feature",
        geometry: geography,
      },
      { idPropertyName: "1" }
    );

    const feature = map.data.getFeatureById("1");
    if (feature) {
      map.data.overrideStyle(feature, {
        editable: true,
        draggable: true,
        clickable: true
      });
    }

    // Handle editing
    map.data.addListener('setgeometry', ({ feature }: { feature: google.maps.Data.Feature }) => {
      const newPolygon = new google.maps.Polygon();

      const latLngs: google.maps.LatLng[] = [];
      feature.getGeometry()?.forEachLatLng((latLng: google.maps.LatLng) => {
        latLngs.push(latLng);
      });
      newPolygon.setPaths(latLngs);

      setManualPolygon(newPolygon)
    })

    map.data.addListener(
      "rightclick",
      ({ feature, latLng }: google.maps.Data.MouseEvent) => {
        const newPolygon: google.maps.LatLng[] = [];

        feature.getGeometry()?.forEachLatLng((_latLng) => {
          if (
            _latLng.lat() !== latLng?.lat() ||
            _latLng.lng() !== latLng?.lng()
          ) {
            newPolygon.push(_latLng);
          }
        });

        const polygon = new google.maps.Data.Polygon([
          new google.maps.Data.LinearRing(newPolygon),
        ]);

        feature.setGeometry(polygon);
      }
    );

    const bounds = new google.maps.LatLngBounds();
    map.data.forEach((feature) => {
      feature.getGeometry()?.forEachLatLng((latlng) => {
        bounds.extend(latlng);
      });
    });

    map.fitBounds(bounds);
    setMapCenter(bounds.getCenter().toJSON());
  }

  const toSuperUnit = (value: number): number => {
    let unit = subUnits;

    if (!unit) {
      const settings: Settings | undefined = SettingsService.getSetting();
      unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
    }

    return Math.round(value * unit);
  };

  const toSubUnit = (value: number): number => {
    let unit = subUnits;

    if (!unit) {
      const settings: Settings | undefined = SettingsService.getSetting();
      unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
    }

    return value / unit;
  }

  const onBeforeSave = async (mode: FormMode, form: Zone) => {
    form.generalTimeZone = form.generalTimezone;
    form.id = form.id || undefined;
    form.levy = toSuperUnit(form.levy) as number 
    form.meterTax = parseFloat(form.meterTax?.toString())
    form.meterVAT = toSuperUnit(form?.meterVAT)
    form.paymentCCChargeValue = parseFloat(form.paymentCCChargeValue?.toString())
    form.waitSpeedThreshold = form?.waitSpeedThreshold
    const data = form.paymentMethods?.map((pay: any) => {
      return pay.id
    })
    form.paymentMethods = data;
    // if (!geography) {
    //   throw new ValidationError({
    //     field: "map",
    //     message: "Please select zone on",
    //   });
    // }

    form.geography = geography as GeoJSON.Polygon;
  };

  function onMapReady(_map: google.maps.Map) {
    const coordinates = geography?.coordinates.map(coordinate => {
      return coordinate
    })
    const coordinate = coordinates?.map(e => {
      return e.map(el => {
        bounds.extend({ lat: el[1], lng: el[0] })
        return { lat: el[0], lng: el[1] }
      })
    })
    _map?.fitBounds(bounds);
    setMapCenter(bounds.getCenter().toJSON());
    setMap(_map);
  }

  function setManualPolygon(polygon: google.maps.Polygon) {
    const coordinates = polygon
      .getPaths()
      .getArray()
      .map((ll) => ll.getArray())
      .map((ll) => ll.map((_ll) => [_ll.lng(), _ll.lat()]));

    coordinates[0].push(coordinates[0][0]);
    setGeography({
      type: "Polygon",
      coordinates,
    });
  }

  async function onSearchChange(search: string) {
    if (!search) {
      return;
    }
    if (search.trim().length < 3) {
      setCities([]);
      return;
    }

    setCityLoading(true);

    const response: any[] = await Axios.get(OSM_URL, {
      params: {
        format: "json",
        limit: 5,
        polygon_geojson: 1,
        q: search,
        polygon_threshold: 0.01, // Higher the threshold, simpler the polygon
        // Complex polygons is difficult to edit and makes for bigger polygon data
        // Bigger data takes longer to load when needed
      },
    });

    if (!response) { alert('Cannot create polygon.'); return; }

    const cities = response.filter((city) =>
      ["Polygon", "MultiPolygon"].includes(city.geojson.type)
    );

    setCities(
      cities.map((city) => ({
        id: city.osm_id,
        label: city.display_name,
        geojson: city.geojson,
      }))
    );

    setCityLoading(false);
  }

  const onSearchChangeDebounced = debounce(onSearchChange, 1000);

  async function onCitySelect(value: any) {
    if (!value || !map) {
      return;
    }

    let geography: GeoJSON.Polygon | undefined = {
      type: "Polygon",
      coordinates: [[]],
    };
    if (value.geojson.type === "MultiPolygon") {
      geography.coordinates = [
        value.geojson.coordinates.reduce(
          (largestPolygon: Array<any>, currentPolygon: Array<any>) =>
            currentPolygon[0].length > largestPolygon[0].length
              ? currentPolygon
              : largestPolygon,
          [[]]
        )[0],
      ];
    } else if (value.geojson.type === "Polygon") {
      geography.coordinates = value.geojson.coordinates;
    } else {
      geography = undefined;
    }

    setGeography(geography);
  }

  const processData = (zone: Zone): Zone => {
    setGeography(zone.geography);
    zone.levy = toSubUnit(zone.levy);
    zone.meterVAT = toSubUnit(zone.meterVAT);
    zone.generalTimezone = {id: zone.generalTimezone, name: zone.generalTimezone } as any;
    zone.paymentMethods = zone.paymentMethods.map((item) => paymentMethods.find(method => method.id === item)) as any;    
    return zone;
  }

  return (
    <Form<Zone>
      onBeforeSave={onBeforeSave}
      returnLink={ZoneRoute.List}
      endpoint={Endpoint.ZONE}
      cancel={cancel}
      formMode={formMode}
      entityId={entityId}
      onAfterSave={onAfterSave}
      processData={processData}
      className={style === FormStyle.Containerized && "ae-content-w"}
    >
      <SectionsWrapper>
        <FormHeader
          type={FormHeaderType.Title}
          title={t("main:entities.zone")}
        />
        <FormSections>
          <BasicSettings currencies={currencies} levy={levy} setLevy={setLevy} setCurrencies={setCurrencies} />
          
          {process.env.REACT_APP_NAME == Enviroment.Poles? null :
          <>
          <MeterSection />
          <PaymentSection />
          </>}
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper>
        <FormHeader type={FormHeaderType.Controls} />
        <FormSections>
          <FindCity
            cityloading={cityloading}
            cities={cities}
            geography={geography}
            onCitySelect={onCitySelect}
            onSearchChangeDebounced={onSearchChangeDebounced}
            mapCenter={mapCenter}
            onMapReady={onMapReady}
            drawingModes={drawingModes}
            setManualPolygon={setManualPolygon}
          />
          <ZoneInstructions />
        </FormSections>
      </SectionsWrapper>
    </Form>
  );
}

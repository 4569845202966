/// <reference types="googlemaps" />
import { Autocomplete } from "@react-google-maps/api";
import classNames from "classnames";
import React, { useRef } from "react";
import { UseFormMethods, ValidationRules } from "react-hook-form";
import { getErrorMessage } from "../util";

interface Props {
  label: string;
  name: string;
  validation: ValidationRules;
  className?: any;
  autoFocus?: boolean;
  tabIndex?: number;
  updatedCoordinates?: any;
}

export function FormAddressInput(props: Props & UseFormMethods) {
  const {
    register,
    validation,
    errors,
    setValue,
    label,
    name,
    className,
    autoFocus,
    tabIndex,
    updatedCoordinates,
  } = props;
  let autocompleteRef = useRef<Autocomplete>(null);
  const onPlaceChanged = () => {
    const autocomplete: google.maps.places.Autocomplete | null | undefined =
      autocompleteRef.current?.state.autocomplete;

    if (autocomplete) {
      const place = autocomplete.getPlace();
      const address: any = place.address_components;
      var city, state, country;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      console.log("address component",address)
      address?.map((component: any) => {
        var types = component.types;
        if ((types.indexOf("locality") > -1)) {
          city = component.long_name;
        }

        if ((types.indexOf("postal_town") > -1)) {
          city = component.long_name;
        }

        if (types.indexOf("administrative_area_level_1") > -1) {
          state = component.short_name;
        }

        if (types.indexOf("country") > -1) {
          country = component.long_name;
        }
      });
      console.log("address component",city)

      if (updatedCoordinates) updatedCoordinates(autocomplete.getPlace());
      const lat = place.geometry?.location?.lat();
      const lng = place.geometry?.location?.lng();
      console.log("place", place);
      setValue(`${name}.lat`, lat);
      setValue(`${name}.lng`, lng);
      setValue(`${name}.text`, place.formatted_address);
      setValue(`${name}.placeId`, place.place_id);
      setValue(`${name}.country`, country);
      setValue(`${name}.state`, state);
      setValue(`${name}.city`, city);
    } else {
      console.log(`Unable to fetch current coordinates!`);
    }
  };

  const onLoad = (ref: google.maps.places.Autocomplete) => {
    autocompleteRef.current?.setState({
      autocomplete: ref,
    });
  };

  return (
    <div
      className={classNames(
        "form-group",
        { "has-danger": !!errors[name] },
        className
      )}
    >
      <label className={classNames({ required: validation.required })}>
        {label}
      </label>

      <input type="hidden" name={`${name}.lat`} ref={register} />
      <input type="hidden" name={`${name}.lng`} ref={register} />
      <input type="hidden" name={`${name}.placeId`} ref={register} />
      <input type="hidden" name={`${name}.country`} ref={register(validation)} />
      <input type="hidden" name={`${name}.state`} ref={register(validation)} />
      <input type="hidden" name={`${name}.city`} ref={register(validation)} />
      <Autocomplete
        onPlaceChanged={onPlaceChanged}
        onLoad={onLoad}
        ref={autocompleteRef}
      >
        <input
          name={`${name}.text`}
          className="form-control"
          type="text"
          autoFocus={autoFocus}
          ref={register(validation)}
          autoComplete="turn-off"
          tabIndex={tabIndex}
        />
      </Autocomplete>

      {errors[`${name}`] && (
        <div className="help-block form-text with-errors form-control-feedback">
          <ul className="list-unstyled">
            <li>{getErrorMessage(errors[name].text, label)}</li>
          </ul>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormSection } from "../../../../components/form/section.component";
import { Network } from "../../../../entities/network.entity";
import { Settings } from "../../../../entities/settings.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";
interface props{ 
  settings:Settings|undefined

}
export function VehiclePostNetwork({settings}:props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [networks, setNetworks] = useState<Network[]>([]);
  
  useEffect(() => { init(); }, [])

  const init = async () => {
    const networkList = await Api.get<IPaginationResponse<Network>, IPaginationOptions>(Endpoint.NETWORK_LIST);
    setNetworks(networkList.items);
  }
  
  return (
    <FormSection
      title={ t("vehicle.networkSection.title") }
      subtitle={ t("vehicle.networkSection.subTitle") }
      className="col-sm-6"
    >
      <div className="row">
        <FormSelect<Network>
          label={ t("vehicle.networkSection.networksLabel") }
          name="networks"
          className="col-sm-12"
          showLabel={ true }
          multiple={ true }
          validation={{required:settings?.vehicleRequirementNetwork?true:false}}
          items={ networks }
          labelField="title"
          onChange={ () => {} }
          tabIndex={15}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}

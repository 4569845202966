import { GoogleMap } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormInput } from "../../../components/form/input/input.component";
import { ContentWrapper, ViewContent } from "../../../components/view/content.component";
import { ViewContentItem, ViewContentItems } from "../../../components/view/item.component";
import { Address } from "../../../entities/address.entity";
import { Booking, BookingStatus } from "../../../entities/booking.entity";
import { BookingAudit } from "../../../entities/bookingAudit.entity";
import { Currency } from "../../../entities/currency.entity";
import { Driver, Gender } from "../../../entities/driver.entity";
import { Passenger } from "../../../entities/passenger.entity";
import { Session } from "../../../entities/session.entity";
import { DiscountType, Trip, TripStatus } from "../../../entities/trip.entity";
import { Vehicle } from "../../../entities/vehicle.entity";
import { Zone } from "../../../entities/zone.entity";
import car from "../../../img/CAR.png";
import FemaleImg from "../../../img/female.png";
import MaleImg from "../../../img/male.png";
import pointA from '../../../img/Point_A.png';
import pointB from '../../../img/Point_B.png';
import listGps from '../../../img/list_gps.png';
import { Api, Endpoint } from "../../../services/api.service";
import { toSubUnit } from "../../../util/currency";
import { decodePolyLine, runSnapToRoad } from "../../../util/decodePolyline";
import { getDateFormatting, getGeneralTimeFormat, getGeneralZoneDistanceTitle, getGeneralZoneDistanceValue } from "../../../util/distance";
import { getStorageUrl } from "../../../util/file.util";
import { getCarSVG } from "../../dashboard/map/car.svg";
import { ProfilePicSize } from "../../driver/list/list.component";
import { GPS } from "../../../entities/gps.entity";
import { showToastError } from "../../../util/notification";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

type MapRender =
  { type: "Destination", pickupAddress: Address | null, dropOffAddress: Address | null } |
  { type: "Ongoing", driverId: string, gps: any, lastSeen?: any } |
  { type: "Completed", gps: any, lastSeen?: any, pickup: Address | null, dropOff: Address | null } |
  { type: "None", setUserLocation: boolean } |
  { type: "Clear" } |
  { type: "Vehicle", latitude: number, longitude: number, heading: number }

let code = "";
let recenterNeeded = false;
let mapCenter: google.maps.LatLng | undefined = undefined;
let carMarker: google.maps.Marker | undefined = undefined;
let trackLine: google.maps.Polyline | undefined = undefined;
let markerPickup: google.maps.Marker | undefined = undefined;
let markerDropOff: google.maps.Marker | undefined = undefined;
let trackMap: google.maps.Map | undefined = undefined;
let trackMapBounds: google.maps.LatLngBounds | undefined = undefined;
let trackRenderer: google.maps.DirectionsRenderer | undefined = undefined;
let vehicleId: any;
const BLUE = "#047bf8";
export function TrackVehicle(props: any) {
  const params: any = useParams();
  const formProps = useFormContext();
  const [inputCode, setInputCode] = useState<string | undefined>(params?.code ? params.code : undefined);
  const [plate, setPlate] = useState<string>();
  const [booking, setBooking] = useState<Booking>();
  const [passenger, setPassenger] = useState<Passenger>();
  const [bookingAudit, setBookingAudit] = useState<BookingAudit[]>([]);
  const [trip, setTrip] = useState<Trip>();
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [driver, setDriver] = useState<Driver | undefined>();
  const [symbol, setSymbol] = useState<string>();
  const [subUnit, setSubUnit] = useState<number>(100);
  const [lastSeen, setLastSeen] = useState<Date>()
  const [generalTimezone, setGeneralTimezone] = useState<string>();
  const [generalCurrency, setGeneralCurrency] = useState<Currency>();
  const TRACK = useRef<boolean>(false);
  const TRIP_ID = useRef<string>();

  const { t } = useTranslation('main');
  const history = useHistory();

  async function getDirections(pickup: google.maps.LatLng, dropoff: google.maps.LatLng) {
    return new Promise<google.maps.DirectionsResult>((resolve, reject) => {
      const directionService = new google.maps.DirectionsService();
      directionService.route({ destination: dropoff, origin: pickup, travelMode: google.maps.TravelMode.DRIVING }, (result, status) => {
        const { DirectionsStatus } = google.maps;
        switch (status) {
          case DirectionsStatus.INVALID_REQUEST:
            reject("Invalid Maps Request");
            break;
          case DirectionsStatus.MAX_WAYPOINTS_EXCEEDED:
            reject("Unable to process to many way points");
            break;
          case DirectionsStatus.NOT_FOUND:
            reject("Unable to process provided locations, please check if locations exists");
            break;
          case DirectionsStatus.OK:
            if (result) resolve(result);
            else reject("Unable to process provided locations,no result");
            break;
          case DirectionsStatus.OVER_QUERY_LIMIT:
            reject("Too many requests within the allowed time period");
            break;
          case DirectionsStatus.REQUEST_DENIED:
            reject("You are not allowed to use the directions service");
            break;
          case DirectionsStatus.UNKNOWN_ERROR:
            reject("Directions request could not be processed due to a server error. The request may succeed if you try again.")
            break;
          case DirectionsStatus.ZERO_RESULTS:
            reject('No route could be found between the origin and destination.');
            break;
        }
      });
    });
  }
  function showToast(message: string) {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  function BTListener(this: { code: string, type: "Trip" | "Both" }, turnOff?: boolean) {
    console.log("BTListener Activated");
    const outerContext = this;
    function update(this: { type: "Trip" | "Booking" }, data: Trip | Booking) {
      console.log("Updated Data:", {
        type: this.type, id: data.id, status: data.status, code: outerContext.code
      });
      if (this.type == "Trip") {
        data = data as Trip;
        if (outerContext.code == data.code) {
          let { session } = data;
          let { driver, vehicle } = session as Session;
          driver = driver as Driver;
          vehicle = vehicle as Vehicle;
          setDriver(driver);
          setVehicle(vehicle);
          vehicleId = vehicle.id

          setTrip(data);
          console.log("Updated Data gps:", data.gps)
          if (outerContext.type == "Trip") {
            renderMap({ type: "Clear" });
            showToast(`${data.code} TRIP STATUS UPDATED TO ${data.status.toUpperCase()}`);
            if (data.status == TripStatus.Metering) renderMap({ type: "Ongoing", driverId: driver.id!, gps: [] });
            if (data.status == TripStatus.Completed || data.status == TripStatus.Arrived) renderMap({ type: "Completed", gps: data.gps || [], dropOff: null, pickup: null });
          }
        }
      }
      else {
        data = data as Booking;
        if (outerContext.code == data.code) {
          let { bookingAudit, ...booking } = data;
          setBooking(booking);
          setBookingAudit(bookingAudit);
          if (outerContext.type == "Both") {
            if (data.status !== BookingStatus.Arrived) renderMap({ type: "Clear" });
            showToast(`${data.code} BOOKING STATUS UPDATED TO ${data.status.toUpperCase()}`);
            if (data.status == BookingStatus.DriverEnroute) renderMap({ type: "Ongoing", driverId: data.driverId, gps: [] });
            if (data.status == BookingStatus.DestinationEnroute) renderMap({ type: "Ongoing", driverId: data.driverId, gps: [] });
            if (data.status == BookingStatus.Completed || data.status == BookingStatus.Arrived) { renderMap({ type: "Completed", gps: data.gps || [], pickup: null, dropOff: null }); }
          }
        }
      }
    }

    if (turnOff) {
      Api.off('trip_update', update);
      Api.off('booking_update', update);
      return;
    }

    Api.on('booking_update', update.bind({ type: "Booking" }));
    Api.on('trip_update', update.bind({ type: "Trip" }));
  }
  async function renderMap(data: MapRender) {
    function update(this: { driver: string }, location: any) {
      console.log("location update = ", { driver: this.driver, location });
      if (location.driverId == this.driver) {
        const position = new google.maps.LatLng(location.latitude, location.longitude);
        const icon = {
          url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getCarSVG("black", location.heading)),
          scaledSize: new google.maps.Size(50, 100),
          anchor: new google.maps.Point(32, 50),
        }
        carMarker?.setMap(null)
        carMarker = new google.maps.Marker({ icon: icon, map: trackMap, position: position });
        // carMarker?.setPosition(position)
        // carMarker?.setOptions({
        //   map: trackMap,
        //   position : position
        // })
        // if(!trackLine){
        //   trackLine = new google.maps.Polyline({ strokeColor: BLUE, strokeWeight: 2, map: trackMap, path: [position] });
        // }
        // else{
        trackLine && trackLine.getPath().push(position);
        // }
        trackMapBounds && trackMapBounds.extend(position);
        !recenterNeeded && trackMap && trackMap.fitBounds(trackMapBounds!);
        !recenterNeeded && trackMap && trackMap.setHeading(location.heading);
        mapCenter = position;

      }

      // using last seen by vehicle id 
      if (location.vehicleId == vehicleId) {
        setLastSeen(location.createTime)
      }
    }
    switch (data.type) {
      case "Destination":
        if (data.pickupAddress && data.dropOffAddress) {
          try {
            const directions = await getDirections(
              new google.maps.LatLng(data.pickupAddress.lat, data.pickupAddress.lng),
              new google.maps.LatLng(data.dropOffAddress.lat, data.dropOffAddress.lng)
            );

            const route = directions.routes[0].bounds;
            trackRenderer = new google.maps.DirectionsRenderer({ map: trackMap! });
            trackRenderer.setDirections(directions);
            trackMap!.fitBounds(route, 10);
            mapCenter = trackMap!.getCenter();
          }
          catch (e: any) {
            console.log(e.message || e);
          }
        }
        else if (data.pickupAddress) {
          const point = new google.maps.LatLng(data.pickupAddress.lat, data.pickupAddress.lng)
          markerPickup = new google.maps.Marker({
            icon: pointA,
            position: point,
            map: trackMap
          });
          const bounds = new google.maps.LatLngBounds(point);
          trackMap!.fitBounds(bounds);
          mapCenter = trackMap!.getCenter();
          showToast('Dropoff Address was not provided with this booking!');
        }
        else { showToast('Pickup and Dropoff Address were not provided'); renderMap({ type: "None", setUserLocation: true }); return; }
        break;
      case "Ongoing":
        if (trackMap) {
          trackMapBounds = new google.maps.LatLngBounds();
          data.gps = decodePolyLine(data.gps.encode_polyline)
          console.log("After Data:", data)
          const pathTravelled = data?.gps?.map((item: any) => {
            const point = new google.maps.LatLng(item[0], item[1])
            trackMapBounds!.extend(point);
            return point;
          });
          let position = undefined;
          if (data.gps?.length > 0) {
            const last = data.lastSeen;
            position = new google.maps.LatLng(last.latitude, last.longitude);
            mapCenter = position;
            const icon = {
              url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getCarSVG("black", data.lastSeen.heading)),
              scaledSize: new google.maps.Size(50, 100),
              anchor: new google.maps.Point(32, 50),
            }
            // carMarker = new google.maps.Marker({ icon: icon, map: trackMap, position: position });
            trackLine = new google.maps.Polyline({ strokeColor: BLUE, strokeWeight: 2, map: trackMap, path: pathTravelled });
            trackMap!.fitBounds(trackMapBounds);
          }

          Api.on('gps_latest_location', update.bind({ driver: data.driverId }));
          Api.post(Endpoint.DASHBOARD_TRACK_SESSION_GPS, { drivers: [data.driverId] });
        }
        break;
      case "Completed":
        Api.off('gps_latest_location', update);
        Api.delete(Endpoint.DASHBOARD_TRACK_SESSION_GPS, { passenger: '' });

        // SHOW DETAIL POINTS LOADING BUTTON ON MAP
        trackMap!.controls[google.maps.ControlPosition.RIGHT_CENTER].push(createPathDetailsButton(() => {}));

        console.log("data gps Inside :", data.gps)
        data.gps = decodePolyLine(data.gps);
        console.log("data After decoding in Completed Trip :", data.gps)

        if (data.gps?.length > 0) {
          markerPickup = new google.maps.Marker({
            icon: pointA, position: new google.maps.LatLng(parseFloat(data.gps[0][0]), parseFloat(data.gps[0][1])), map: trackMap!
          });

          const lastIndex = data.gps.length - 1;
          markerDropOff = new google.maps.Marker({
            icon: pointB, position: new google.maps.LatLng(parseFloat(data.gps[lastIndex][0]), parseFloat(data.gps[lastIndex][1])), map: trackMap!
          });

          console.log("markup", markerDropOff, markerPickup, data.gps[0][0])
          trackMapBounds = new google.maps.LatLngBounds();
          const positions = data.gps.map((item: any) => {
            const point = new google.maps.LatLng(item[0], item[1])
            trackMapBounds!.extend(point);
            return point;
          });

          trackLine = new google.maps.Polyline({
            path: positions, strokeColor: BLUE, strokeWeight: 2,
            draggable: false, clickable: false, editable: false, visible: true, map: trackMap!
          });

          if (TRACK.current) {
            runSnapToRoad(trackLine)
          }

          trackMap!.fitBounds(trackMapBounds);
          mapCenter = trackMap!.getCenter();
        }
        else {
          showToast("Not enough gps points for a route!");
          if (data.pickup && data.dropOff) {
            markerPickup = new google.maps.Marker({
              icon: pointA, position: new google.maps.LatLng(data.pickup.lat, data.pickup.lng), map: trackMap!
            });

            markerDropOff = new google.maps.Marker({
              icon: pointB, position: new google.maps.LatLng(data.dropOff.lat, data.dropOff.lng), map: trackMap!
            });

            trackMapBounds = new google.maps.LatLngBounds(markerPickup.getPosition()!);
            trackMapBounds.extend(markerDropOff.getPosition()!);
            trackMap!.fitBounds(trackMapBounds);
            mapCenter = trackMapBounds.getCenter();
          }
          else {
            if (data.pickup) {
              markerPickup = new google.maps.Marker({
                icon: pointA, position: new google.maps.LatLng(data.pickup.lat, data.pickup.lng), map: trackMap!
              });

              mapCenter = markerPickup.getPosition()!;
              trackMap!.setCenter(mapCenter);
            }
          }
        }
        break;
      case "Vehicle":
        carMarker = new google.maps.Marker({
          icon: car, position: new google.maps.LatLng(data.latitude, data.longitude), map: trackMap!
        });

        mapCenter = carMarker.getPosition()!;
        trackMap!.setCenter(carMarker.getPosition()!);
        trackMap!.setZoom(16);
        break;
      case "Clear":
        if (mapCenter) { mapCenter = undefined; }
        if (trackMapBounds) { trackMapBounds = undefined; }
        if (trackLine) { trackLine.setMap(null); trackLine = undefined; }
        if (carMarker) { carMarker.setMap(null); carMarker = undefined; }
        if (markerPickup) { markerPickup.setMap(null); markerPickup = undefined; }
        if (markerDropOff) { markerDropOff.setMap(null); markerDropOff = undefined; }
        if (trackRenderer) { trackRenderer.setMap(null); trackRenderer = undefined; }
        break;
      default:
        if (mapCenter) { mapCenter = undefined };
        if (trackMapBounds) { trackMapBounds = undefined; }
        if (trackLine) { trackLine.setMap(null); trackLine = undefined; }
        if (carMarker) { carMarker.setMap(null); carMarker = undefined; }
        if (markerPickup) { markerPickup.setMap(null); markerPickup = undefined; }
        if (markerDropOff) { markerDropOff.setMap(null); markerDropOff = undefined; }
        if (trackRenderer) { trackRenderer.setMap(null); trackRenderer = undefined; }

        setBooking(undefined); setBookingAudit([]);
        setPassenger(undefined); setTrip(undefined); setDriver(undefined);
        setVehicle(undefined); setSubUnit(100); setSymbol(undefined);

        Api.off('gps_latest_location', update);
        Api.delete(Endpoint.DASHBOARD_TRACK_SESSION_GPS, { passenger: '' });

        if (data.setUserLocation) navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
          mapCenter = new google.maps.LatLng(latitude, longitude);
          trackMap!.setOptions({
            center: mapCenter,
            zoom: 16
          });
        }, (err) => {
          console.log(err.message);
        });
    }
  }




  async function onCodeReceived(code: string) {
    try {
      if ((/[A-Z]{3}-\d{4}/g).test(code) == false) {
        throw new Error('Invalid Code Pattern, Your code can only use the format ABC-0000');
      }

      renderMap({ type: "None", setUserLocation: true });
      console.log("Nothing rendered");
      type Common = { generalCurrency: Zone["generalCurrency"], generalTimeZone: Zone["generalTimezone"], bookingAudits: BookingAudit[] };
      type Response = { data: 'bookingOnly' } & Common & Booking |
        { data: 'tripAndBooking' } & Trip & Common & { gps: any }

      let response = await Api.get<Response, { code: string }>(Endpoint.TRACK, { code: code });
      console.log("RESPONSE IN CODE RECEIVED ::", response)

      setGeneralCurrency(response.generalCurrency)
      // setSymbol(response.generalCurrency.symbol);
      // setGeneralTimezone(response.generalTimeZone);
      // setSubUnit(response.generalCurrency.subunits);
      // recommed by sohaib n shrehyar 

      // in case of schedule booking gps is not present we need to check if gps is present in the response or not
      if (response.lastSeen) {
        setLastSeen(response.lastSeen.createTime)
      }
      if (response.data == "bookingOnly") {
        let { passenger, bookingAudits } = response;
        passenger = passenger as Passenger;
        if ([BookingStatus.Abandoned, BookingStatus.NoDriver].includes(response.status) == false) {
          console.log("Listerner Should Be Attached"); BTListener.bind({ code: response.code, type: "Both" })();
        }
        setBooking(response);
        setPassenger(passenger);
        setBookingAudit(bookingAudits);
        renderMap({ type: "Destination", dropOffAddress: response.dropOffAddress, pickupAddress: response.pickupAddress });
      }
      else {
        let { gps, lastSeen, driver, vehicle, booking, bookingAudits, generalCurrency, generalTimeZone, ...trip } = response;
        driver = driver as Driver;
        vehicle = vehicle as Vehicle;
        vehicleId = vehicle.id
        if (booking) {
          booking = booking as Booking;
          booking.passenger = booking.passenger as Passenger;
          setPassenger(booking.passenger);
          setBookingAudit(bookingAudits);
          setBooking(booking);
          // ONLY SET AUDIT AND GPS LISTENER IF RIDE IS NOT COMPLETE, NO NEED IF ALREADY COMPLETED
          switch (booking.status) {
            case BookingStatus.NoDriver:
            case BookingStatus.Abandoned:
            case BookingStatus.AwaitingAccept:
            case BookingStatus.DriverCancelled:
            case BookingStatus.ScheduledPending:
            case BookingStatus.DispatchCancelled:
            case BookingStatus.PassengerCancelled:
            case BookingStatus.ScheduledPendingLastAttempt:
              renderMap({ type: "Destination", pickupAddress: booking.pickupAddress, dropOffAddress: booking.dropOffAddress });
              break;
            case BookingStatus.Completed:
            case BookingStatus.Arrived:
              let gpsData = response.gps.encode_polyline
              console.log("after decode:", gpsData)
              renderMap({ type: "Completed", lastSeen: lastSeen, gps: gpsData, pickup: booking.pickupAddress, dropOff: booking.dropOffAddress });
              break;
            case BookingStatus.DriverEnroute:
            case BookingStatus.ScheduledTaken:
            case BookingStatus.DestinationEnroute:
            case BookingStatus.ScheduledDriverEnroute:
              renderMap({ type: "Ongoing", driverId: driver.id!, gps, lastSeen: lastSeen });
              BTListener.bind({ code: booking.code, type: "Both" })();
              break;
          }
        }
        else {
          switch (trip.status) {
            case TripStatus.Cancelled:
            case TripStatus.QrScanCancelled:
              renderMap({ type: "None", setUserLocation: true });
              break;
            case TripStatus.Enroute:
            case TripStatus.Metering:
            case TripStatus.DriverEnroute:
              renderMap({ type: "Ongoing", driverId: driver.id!, gps, lastSeen: lastSeen });
              BTListener.bind({ code: trip.code, type: "Trip" })();
              break;
            case TripStatus.Completed:
            case TripStatus.Arrived:
              let gpsData = response.gps.encode_polyline
              renderMap({ type: "Completed", gps: gpsData, pickup: null, dropOff: null, lastSeen: lastSeen });
              break;
          }
          setBooking(undefined);
          setBookingAudit([]);
          setPassenger(undefined);
          showToast(`Showing ${trip.type} trip details`);
        }

        //@ts-ignore
        setTrip(trip);
        setDriver(driver);
        setVehicle(vehicle);
        vehicleId = vehicle.id
        TRIP_ID.current = trip.id;

      }
      return true;
    }
    catch (err: any) {
      console.log("Err while getting track response = ", err);
      console.log(err.message || err);
      renderMap({ type: "None", setUserLocation: true });
      return false;
    }
  }
  function createRecenterButton(remove: Function) {
    const div = document.createElement("div");
    div.setAttribute("style", "margin: 10px; height: 40px; width: 40px; justify-content: center; align-items: center; border-radius: 2px; box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;");

    const button = document.createElement("button");
    button.setAttribute("style", "width: 100%; height: 100%; border-radius: 2px; border: none; background-color: white");

    const image = document.createElement("img");
    image.setAttribute("src", "https://cdn.iconscout.com/icon/free/png-256/compass-2451562-2082565.png");
    image.setAttribute("style", "width: 100%; height: 100%; object-fit: contain");

    button.onclick = function () {
      if (mapCenter) {
        trackMap!.setCenter(mapCenter);
        remove();
      }
    }
    button.append(image);
    div.append(button);
    return div;
  }
  function createPathDetailsButton(remove: Function) {
    const div = document.createElement("div");
    div.setAttribute("style", "margin: 10px; height: 40px; width: 40px; justify-content: center; align-items: center; border-radius: 2px; box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;");

    const button = document.createElement("button");
    button.setAttribute("style", "width: 100%; height: 100%; border-radius: 2px; border: none; background-color: white");

    const image = document.createElement("img");
    image.setAttribute("src", listGps);
    image.setAttribute("style", "width: 100%; height: 100%; object-fit: contain");

    button.onclick = async function () {
      if (TRIP_ID.current) {
        showToast("Loading detailed gps points");
        try {
          const response = await Api.get<GPS[], any>(Endpoint.GPS_DETAIL, { id: TRIP_ID.current });
          console.log("response = ",response);
          if (response.length > 0) {
            const markers: google.maps.Marker[] = [];
            for (let point of response) {
              markers.push(new google.maps.Marker({ 
                clickable: false, draggable: false, label: String(point.sourceIndex), title: getGeneralTimeFormat(parseInt(point.timeFromLast)/1000),
                position: new google.maps.LatLng(point.latitude, point.longitude)
              }));
            }
            const markerCluster = new MarkerClusterer({ map: trackMap!, markers });
          }
        }
        catch(e: any) {
          showToastError(`Error while creating path: ${e?.message || e?.code || e}`);
        }
      }
      else alert('Cannot find trip to search locations!');
    }
    button.append(image);
    div.append(button);
    return div;
  }

  // useEffect(()=>{
  //   console.log("callback")
  //   if (code && code !== ""){
  //     onCodeReceived(code)

  //   }




  // },[toggleTrack])

  useEffect(() => {
    trackMap = new google.maps.Map(document.getElementById('googleMaps@tracker')!);
    google.maps.event.addListenerOnce(trackMap, "idle", async function () {
      console.log("Map Loaded and is IDLE");
      trackMap!.addListener("dragend", () => {
        if (recenterNeeded == false) {
          recenterNeeded = true;
          trackMap!.controls[google.maps.ControlPosition.RIGHT_CENTER].push(createRecenterButton(() => {
            trackMap!.controls[google.maps.ControlPosition.RIGHT_CENTER].pop();
            recenterNeeded = false;
          }));
        }
      });
      code = params.code;
      if (code && code !== "") {
        if (await onCodeReceived(code))
          //  setInputCode(code)
          console.log('Nothing')
      }
      else {
        if (inputCode) {
          onCodeReceived(inputCode)
        }

        if (plate) {
          onPlateChange()
        }
      }
    });

    return () => {
      BTListener.bind({ code: "", type: "Both" })(true);
      markerPickup = undefined; trackMap = undefined; trackRenderer = undefined;
      trackLine = undefined; trackMapBounds = undefined;
    }
  }, []);

  const onCodeNoChange = async () => {
    if (inputCode) {
      if (inputCode == code && typeof symbol !== "undefined") {
        showToast('Showing details for the same code!');
      }
      else {
        if (await onCodeReceived(inputCode)) {
          history.replace({ pathname: window.location.pathname.replace(code, inputCode) });
          code = inputCode;
        }
      }
    }
  };
  const onPlateChange = async () => {
    if (plate) {
      try {
        type Response = { type: "code" | "gps" | "driver" | "NA", value: string }
        const response = await Api.get<Response, any>(Endpoint.VEHICLE_TRACK, { plate: plate });
        console.log("response vehicle track", response)
        history.replace({ pathname: window.location.pathname.replace(code, plate) });
        code = plate;
        setInputCode(undefined);

        switch (response.type) {
          case "code":
            showToast("VEHICLE IS ON TRIP\nSHOWING TRIP DETAILS");
            renderMap({ type: 'None', setUserLocation: false });
            onCodeReceived(response.value);
            break;
          case "driver":
            renderMap({ type: 'None', setUserLocation: false });
            showToast("TRACKING VEHICLE WITH DRIVER");
            const driverParams = new URLSearchParams(response.value);
            const driver = Object.fromEntries(driverParams.entries());
            //@ts-ignore
            renderMap({
              type: "Ongoing", driverId: driver.id, gps: [{
                latitude: parseFloat(driver.lat), longitude: parseFloat(driver.lng), heading: parseFloat(driver.heading)
              }]
            });
            break;
          case "gps":
            renderMap({ type: "None", setUserLocation: false });
            showToast("SHOWING LAST KNOWN VEHICLE POSITION");
            const gpsParams = new URLSearchParams(response.value);
            const gps = Object.fromEntries(gpsParams.entries());
            renderMap({ type: "Vehicle", latitude: parseFloat(gps.lat), longitude: parseFloat(gps.lng), heading: parseFloat(gps.heading) });
            break;
          case "NA":
            showToast("No Information available for this plate!");
            renderMap({ type: "None", setUserLocation: true });
        }
      }
      catch (err: any) {
        console.log(err.message || err);
      }

    }
    else showToast('Please add plate number to track!');

  }
  const getProfilePic = (driver: Driver, type: ProfilePicSize): string => {
    if (driver.picFile) {
      return `${getStorageUrl()}/driver/${driver.id}/pic/${driver.picFile?.id
        }/${type}.${driver.picFile?.extension}`;
    } else {
      return driver.gender === Gender.Female ? FemaleImg : MaleImg;
    }
  };

  return (
    <ContentWrapper className="container-fluid">
      <div className="row" style={{
        justifyContent: 'space-between'
      }}>
        <FormInput
          label={""}
          name="Track"
          type="text"
          className="col-sm-3 mb-0 ml-3"
          tabIndex={10}
          value={plate}
          placeholder={t('mainMenu.track.enterPlate')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPlate(e.target.value) }}
          {...formProps}
        />
        <button
          style={{ height: "100%", marginTop: "23px", padding: "8px" }}
          className="btn btn-primary text-bold col-sm-1"
          onClick={onPlateChange}
        >
          {t('mainMenu.track.track')}

        </button>
        <FormInput
          label={""}
          name="code"
          type="text"
          className="col-sm-3 ml-3"
          tabIndex={12}
          value={inputCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setInputCode(e.target.value) }}
          placeholder={t('mainMenu.track.enterBookingCode')}
          {...formProps}
        />
        <button
          style={{ height: "100%", marginTop: "23px", padding: "8px" }}
          className="btn btn-primary text-bold col-sm-1"
          onClick={onCodeNoChange}
        >
          {t('mainMenu.track.track')}
        </button>

        <div style={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "row",
          justifyContent: "flex-end"
        }} className="form-group col-sm-3 ml-3">
          <label style={{
            marginBottom: "-10px",
            marginRight: "25px"
          }}>
            Map Track
            <input
              type="checkbox"
              className="form-check-input"
              style={{ marginLeft: "10px" }}
              checked={TRACK.current}
              onChange={() => TRACK.current = !TRACK.current}
            />
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-md-7">
          <GoogleMap
            id="googleMaps@tracker"
            mapContainerStyle={{
              marginLeft: 20,
              height: 520,
              width: "98%",
              padding: 8,
              marginBottom: 10,
            }}
          ></GoogleMap>
          <div className="col">
            {trip && (
              <div className="row">
                <ViewContent className="col-md-6" title="Fare Details">
                  <ViewContentItems>
                    {booking && (

                      <ViewContentItem title="Estimate">{booking.estimate ? `${generalCurrency?.symbol} ${booking.estimate}` : "N/A"}</ViewContentItem>
                    )}
                    <ViewContentItem title="Total Fare">{toSubUnit(trip.totalFare || 0, generalCurrency)}</ViewContentItem>
                    <ViewContentItem title="Distance">{`${((trip.distance || 0) / getGeneralZoneDistanceValue()).toFixed(3)} ${getGeneralZoneDistanceTitle()}`}</ViewContentItem>
                    <ViewContentItem title="Ride Time">{getGeneralTimeFormat(trip.rideTime || 0)}</ViewContentItem>
                    <ViewContentItem title="Wait Time">{getGeneralTimeFormat(trip.waitTime || 0)}</ViewContentItem>
                    <ViewContentItem title="Start Charge">{toSubUnit(trip.startCharge || 0, generalCurrency)}</ViewContentItem>
                    <ViewContentItem title="Booking Charge">{toSubUnit(trip.bookingCharge || 0, generalCurrency)}</ViewContentItem>
                    <ViewContentItem title="Distance Charge">{toSubUnit(trip.distanceCharge || 0, generalCurrency)}</ViewContentItem>
                    <ViewContentItem title="Wait Charge">{toSubUnit(trip.waitCharge || 0, generalCurrency)}</ViewContentItem>
                  </ViewContentItems>
                </ViewContent>
                <ViewContent className="col-md-6" title="Fare Details">
                  <ViewContentItems>
                    <ViewContentItem title="Levy">{toSubUnit(trip.levy || 0, generalCurrency)}</ViewContentItem>
                    <ViewContentItem title="Tolls">{toSubUnit(trip.tolls || 0, generalCurrency)}</ViewContentItem>
                    <ViewContentItem title="Extra Charge">{toSubUnit(trip.extraCharge || 0, generalCurrency)}</ViewContentItem>
                    <ViewContentItem title="Tax">{toSubUnit(trip.tax || 0, generalCurrency)}</ViewContentItem>
                    <ViewContentItem title="CC Charge">{toSubUnit(trip.ccCharge || 0, generalCurrency)}</ViewContentItem>
                    <ViewContentItem title="Tip">{toSubUnit(trip.tip || 0, generalCurrency)}</ViewContentItem>
                    <ViewContentItem title="Type">{trip.type.toUpperCase()}</ViewContentItem>
                    <ViewContentItem title="Discount Type">{trip.discountType.toUpperCase()}</ViewContentItem>
                    {trip.discountType == DiscountType.Percentage && (
                      <ViewContentItem title="Discount Value">{toSubUnit((trip.discountValue || 0) / (trip.totalFare || 0), generalCurrency)}</ViewContentItem>
                    )}
                    {trip.discountType == DiscountType.Fixed && (
                      <ViewContentItem title="Discount Value">{toSubUnit(trip.discountValue || 0, generalCurrency)}</ViewContentItem>
                    )}
                    {[TripStatus.QrScanCancelled, TripStatus.Cancelled].includes(trip.status) && (
                      <ViewContentItem title="Status">
                        <span className="badge badge-danger">{trip.status.toUpperCase()}</span>
                      </ViewContentItem>
                    )}
                    {[TripStatus.DriverEnroute, TripStatus.Enroute, TripStatus.Metering, TripStatus.Arrived].includes(trip.status) && (
                      <ViewContentItem title="Status">
                        <span className="badge badge-warning">{trip.status.toUpperCase()}</span>
                      </ViewContentItem>
                    )}
                    {trip.status == TripStatus.Completed && (
                      <ViewContentItem title="Status">
                        <span className="badge badge-success">{trip.status.toUpperCase()}</span>
                      </ViewContentItem>
                    )}
                    <ViewContentItem title="Paid Via">{(trip.paidBy || "pending").toUpperCase()}</ViewContentItem>
                  </ViewContentItems>
                </ViewContent>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-5">
          <div className="row mb-2">
            <div className="col-md-12">
              {booking && (
                <ViewContent title="Booking Details">
                  <ViewContentItems>
                    <div className="row">
                      <div className="col-md-6">
                        {[BookingStatus.Pickup, BookingStatus.Dropoff, BookingStatus.Bidding,
                        BookingStatus.ScheduledPending, BookingStatus.AwaitingAccept,
                        BookingStatus.DriverEnroute, BookingStatus.DestinationEnroute, BookingStatus.Arrived
                        ].includes(booking.status) && (
                            <ViewContentItem title="Status">
                              <span className="badge badge-warning">
                                {(booking.status == BookingStatus.Pickup) ? 'booked' : booking.status == BookingStatus.Dropoff ? 'booked' : booking?.status}
                              </span>
                            </ViewContentItem>
                          )}

                        {[BookingStatus.Abandoned, BookingStatus.ScheduledTakenCancelled,
                        BookingStatus.DriverCancelled, BookingStatus.PassengerCancelled,
                        BookingStatus.NoDriver, BookingStatus.NotAccepted,
                        BookingStatus.DispatchCancelled
                        ].includes(booking.status) && (
                            <ViewContentItem title="Status">
                              <span className="badge badge-danger">{booking.status}</span>
                            </ViewContentItem>
                          )}

                        {booking.status == BookingStatus.Completed && (
                          <ViewContentItem title="Status">
                            <span className="badge badge-success">{booking.status}</span>
                          </ViewContentItem>
                        )}

                        {booking.pickupAddress && (
                          <ViewContentItem title="Pickup">
                            {booking.pickupAddress.text}
                          </ViewContentItem>
                        )}
                      </div>
                      <div className="col-md-6">
                        <ViewContentItem title="Type">
                          {booking.type}
                        </ViewContentItem>
                        <ViewContentItem title="Destination">
                          {booking.dropOffAddress ? booking.dropOffAddress.text : ""}
                        </ViewContentItem>
                      </div>
                      <div className="col-md-6">
                        <ViewContentItem title="date and time">
                          {getDateFormatting(new Date(booking.createTime), generalTimezone)}
                        </ViewContentItem>
                      </div>
                      {booking?.scheduledTimestamp ?
                        <div className="col-md-6">
                          <ViewContentItem title="Schedule Timestamp">
                            {getDateFormatting(new Date(booking.scheduledTimestamp), generalTimezone)}
                          </ViewContentItem>
                        </div> : ""}
                    </div>
                  </ViewContentItems>
                </ViewContent>
              )}
              {Object.keys(bookingAudit).length > 0 ? (
                <div style={{ marginTop: "10px" }}>
                  <ViewContent title="Booking History">
                    <div
                      style={{
                        maxHeight: "300px",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Status</th>
                            <th scope="col">Reason</th>
                            <th scope="col">Source</th>
                            <th scope="col">Date And Time</th>
                          </tr>
                        </thead>
                        {bookingAudit
                          ? bookingAudit.map((audit: any, i: number) => {
                            return (
                              <tbody key={i}>
                                <tr>
                                  <td>
                                    {[BookingStatus.Pickup, BookingStatus.Dropoff, BookingStatus.Bidding,
                                    BookingStatus.ScheduledPending, BookingStatus.AwaitingAccept,
                                    BookingStatus.DriverEnroute, BookingStatus.DestinationEnroute,
                                    BookingStatus.ScheduledPending, BookingStatus.ScheduledPendingLastAttempt, BookingStatus.ScheduledDriverEnroute,
                                    BookingStatus.ScheduledTaken,
                                    BookingStatus.Arrived].includes(audit?.status?.toLowerCase())
                                      ? (
                                        <span className="badge badge-warning">
                                          {(audit?.status == BookingStatus.Pickup) ? 'booked' : audit?.status == BookingStatus.Dropoff ? 'booked' : audit?.status}
                                        </span>
                                      ) : (" ")}

                                    {[
                                      BookingStatus.Abandoned, BookingStatus.ScheduledTakenCancelled,
                                      BookingStatus.DriverCancelled, BookingStatus.PassengerCancelled,
                                      BookingStatus.NoDriver, BookingStatus.NotAccepted,
                                      BookingStatus.DispatchCancelled, BookingStatus.ScheduledTakenCancelled
                                    ].includes(audit?.status?.toLowerCase()) ? (
                                      <span className="badge badge-danger">
                                        {audit?.status}
                                      </span>
                                    ) : ("")}

                                    {audit?.status?.toLowerCase() ==
                                      BookingStatus.Completed ? (
                                      <span className="badge badge-success">
                                        {audit?.status}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <span className="badge badge-success">
                                      {audit?.reason}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="badge badge-success">
                                      {audit?.source}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {audit.createTime && getDateFormatting(new Date(audit.createTime), generalTimezone)}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })
                          : ""}
                      </table>
                    </div>
                  </ViewContent>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row">
            {booking && !driver ? (
              <ViewContent className="col-md-12" title="Estimate">
                <ViewContentItems>
                  <ViewContentItem title="Estimate">
                    {booking.estimate ? `${generalCurrency?.symbol} ${booking.estimate}` : "N/A"}
                  </ViewContentItem>
                </ViewContentItems>
              </ViewContent>
            ) : (
              ""
            )}
          </div>
          <div className="row" style={{ marginBottom: "5px" }}>
            {passenger ? (
              <ViewContent className="col-md-12" title="Passenger Details">
                <ViewContentItems>
                  <ViewContentItem title="Passenger Name">{`${passenger.firstName} ${passenger.lastName}`}</ViewContentItem>
                  <ViewContentItem title="Email">{passenger?.email}</ViewContentItem>
                  <ViewContentItem title="Phone Number">{passenger?.phone}</ViewContentItem>

                </ViewContentItems>
              </ViewContent>

            ) : (
              ""
            )}
          </div>
          <div className="row">
            {vehicle ? (
              <ViewContent className="col-md-6" title="Vehicle Details">
                <ViewContentItems>
                  <ViewContentItem title="Reg">{vehicle?.plate}</ViewContentItem>
                  <ViewContentItem title="VIN">{vehicle?.vin}</ViewContentItem>
                  <ViewContentItem title="Make">{vehicle?.make}</ViewContentItem>
                  <ViewContentItem title="Model">{vehicle?.model}</ViewContentItem>
                  <ViewContentItem title="Color">{vehicle?.color}</ViewContentItem>
                </ViewContentItems>
              </ViewContent>
            ) : (
              ""
            )}

            {driver ? (
              <ViewContent
                className="col-md-6"
                title="Driver Details"
                subtitle={driver.code}
                imgSrc={getProfilePic(driver, ProfilePicSize.Single)}
              >
                <ViewContentItems>
                  <ViewContentItem title="Name">{driver.firstName}</ViewContentItem>
                  <ViewContentItem title="Driver Id">{driver.code}</ViewContentItem>
                  {lastSeen ? <ViewContentItem title="Last Seen">{getDateFormatting(new Date(lastSeen as Date), generalTimezone)}</ViewContentItem> : ""}
                </ViewContentItems>
              </ViewContent>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </ContentWrapper>
  );
}

import { GoogleMap, Polygon } from "@react-google-maps/api";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContentWrapper,
  ViewContent
} from "../../../components/view/content.component";
import {
  ViewContentItem,
  ViewContentItems
} from "../../../components/view/item.component";
import { Currency } from "../../../entities/currency.entity";
import { Settings } from "../../../entities/settings.entity";
import { Zone } from "../../../entities/zone.entity";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsService } from "../../../services/settings.service";
import { CurrentZone } from "./list.component";

interface Props {
  selectedZone: Zone;
  onRefresh?: () => void,
  setSelectedZone?: (zone: undefined | Zone) => void
}

interface Id {
  id: String | undefined
}



export function SelectedItemContent({ selectedZone,
  onRefresh,
  setSelectedZone }: Props) {
  const { t } = useTranslation("forms");
  const [symbol, setSymbol] = useState<string | null>('')
  const [coords, setCoords] = useState<any>()
  const settings: Settings | undefined = SettingsService.getSetting();
  const [alertMsg,setAlertMsg] = useState("")
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: -33.847927,
    lng: 150.651776,
  });
  const [zoom, setZoom] = useState(12);
  const [map, setMap] = useState<google.maps.Map>()
  const zone = useContext(CurrentZone)
  const bounds = new window.google.maps.LatLngBounds();
  let arr: any[] = []
  
  const toSubUnit = (value: number): number => {
    const unit = selectedZone.generalCurrency.subunits || 100;
    return value / unit;
  }

  const onLoad = React.useCallback((map) => {
    map.fitBounds(bounds);
    setMap(map)

    setTimeout(() => {
      setZoom(12);
    }, 100);

  }, [])

  const onUnmount = React.useCallback(() => {
    setMap(undefined)
  }, []);

  const onclickRecover = async (selectedZone: Zone) => {

    try{
    const response = await Api.post<Zone, { id: string }>(Endpoint.ZONE_RECOVER, { id: selectedZone.id as string });
    if (onRefresh && setSelectedZone) {
      setSelectedZone(undefined);
      onRefresh();
    }
  }
  catch(err){
    setAlertMsg(`${err}`)
  }


  }


  useEffect(() => {
    const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
    setSymbol(currency)

    const coordinates = zone?.geography.coordinates.map(coordinate => {
      return coordinate
    })
    const coordinate = coordinates?.map(e => {
      return e.map(el => {
        bounds.extend({ lat: el[1], lng: el[0] })
        arr.push({ lat: el[1], lng: el[0] })
        return { lat: el[0], lng: el[1] }
      })
    })

    setCoords(arr)
    map?.fitBounds(bounds);
    setCenter(bounds.getCenter().toJSON());
  }, [zone, selectedZone, map])

  return (
    <ContentWrapper className="row">
      <ViewContent className="col-md-6" title={selectedZone.deletedTime ? selectedZone.title + (' (deleted)') : selectedZone.title}>
        <ViewContentItems>
          {alertMsg !== ""?
          <div className="alert alert-danger borderless">
          <h5 className="alert-heading">Overlapped Zone</h5>
          <p>{alertMsg}</p>
        </div>:null}
          {selectedZone.deletedTime ? 
        <div className="alert alert-success borderless">
          <h5 className="alert-heading">Zone Deleted</h5>
          <p>Do you want to recover this zone data?</p>
          <div className="alert-btn">
            <a className="btn btn-white-gold" href={'#href'} onClick={() => onclickRecover(selectedZone)}>
              <i className="os-icon os-icon-ui-92"></i>
              <span>{'Recover Zone'}</span>
            </a>
          </div>
        </div> : null
        }
          <ViewContentItem title={t("zone.speedThreshold")}>
            {selectedZone.waitSpeedThreshold} kmph
          </ViewContentItem>
          <ViewContentItem title={t("zone.levy")}>
            {symbol}{toSubUnit(selectedZone.levy)}
          </ViewContentItem>
          <ViewContentItem title={t("zone.unitLabel")}>
            {selectedZone.generalUnit}
          </ViewContentItem>
          <ViewContentItem title={t("zone.timezone")}>
            {selectedZone.generalTimezone}
          </ViewContentItem>
          <ViewContentItem title={t("zone.currencyLabel")}>
            {selectedZone.generalCurrency.name}
          </ViewContentItem>
          <ViewContentItem title={t("zone.meter.taxSection.title")}>
            {`${t("zone.meter.taxSection.taxLabel")}: ${selectedZone.meterTaxLabel} | ${t("zone.meter.taxSection.taxAmountLabel")}: ${selectedZone.meterTax} | ${t("zone.meter.taxSection.vatLabel")}: ${symbol} ${toSubUnit(selectedZone.meterVAT)}`}
          </ViewContentItem>
          <ViewContentItem title={t("zone.meter.taxSection.paymentMethods")}>
            {selectedZone.paymentMethods?.map(n => n).join(', ')}
          </ViewContentItem>
          <ViewContentItem title={t("zone.meter.taxSection.paymentCCType")}>
            {selectedZone.paymentCCChargeType}
          </ViewContentItem>
          <ViewContentItem title={t("zone.meter.taxSection.paymentCCValue")}>
            {selectedZone.paymentCCChargeValue}
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent>
      <ViewContent className="col-md-6" title='Zone Map'>
        <ViewContentItems>
          <ViewContentItem title=''>
            <GoogleMap
              mapContainerStyle={{ height: '500px', width: '100%' }}
              center={center}
              zoom={zoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              <Polygon
                path={coords}
                options={{
                  fillColor: "#000",
                  fillOpacity: 0.4,
                  strokeColor: "#000",
                  strokeOpacity: 1,
                  strokeWeight: 1
                }}
              />
            </GoogleMap>
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent>
    </ContentWrapper>
  );
}

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormSection } from "../../../../components/form/section.component";
import { Fleet } from "../../../../entities/fleet.entity";
import { Settings } from "../../../../entities/settings.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";
import { AuthService } from "../../../../services/auth.service";
interface props{
  settings: Settings|undefined
}
export function DriverPostFleet({settings}:props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [fleets, setFleets] = useState<Fleet[]>([]);
  
  useEffect(() => {
    init(); 
    return ()=>{
      setFleets([]);
    }}, [])

  const init = async () => {
    const fleetList = await Api.get<IPaginationResponse<Fleet>, IPaginationOptions>(Endpoint.FLEET_LIST);
    setFleets(fleetList.items);
  }
  
  return (
    <FormSection
      title={ t("driver.fleetSection.title") }
      subtitle={ t("driver.fleetSection.subTitle") }
    >
      <div className="row">
        <FormSelect<Fleet>
          label={ t("driver.fleetSection.fleetsLabel") }
          name="fleets"
          className="col-sm-12"
          showLabel={ true }
          multiple={ true }
          validation={{required:settings?.driverRequirementFleets?true:false}}
          items={ fleets }
          labelField="title"
          onChange={ () => {} }
          tabIndex={0}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}

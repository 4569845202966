import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import React from 'react'
import { Controller, UseFormMethods, ValidationRules } from 'react-hook-form'
import classNames from 'classnames'
import { getErrorMessage } from '../util'

interface Props {
    placeholder?: string;
    value?: any;
    onChange?: any;
    className?: any;
    validation?: ValidationRules;
    name: string
    tabIndex?: number
}

export function PhoneInputs(props: Props & UseFormMethods) {
    const { errors, placeholder, value, onChange, className, register, name, control, tabIndex } = props
    let { validation } = props;
    validation = validation || {};
    // `value` will be the parsed phone number in E.164 format.
    // Example: "+12133734253". 
    return (
        <div className={classNames('form-group',  { 'has-danger': errors && errors[name] }, className)}>
            <label className={classNames({ required: validation.required })}>{placeholder}</label>
            <Controller
                name={name}
                control={control}
                defaultValue=''
                rules={{
                    required: validation.required?true:false
                }}
                render={({ onChange, value}) => {
                    return(
                    <PhoneInput
                        value={props.value}
                        placeholder={placeholder}
                        ref={register}
                        onChange={(e) => {onChange && onChange(e);props.onChange(e)}}
                        name={name}
                        tabIndex={tabIndex}
                    />
                )}}
            />

            {errors && errors[name] && (
                <div className="help-block form-text with-errors form-control-feedback">
                    <ul className="list-unstyled"><li>{placeholder ? getErrorMessage(errors[name], placeholder) : ''}</li></ul>
                </div>
            )}
        </div >
    )
}
export function debounce(fn: Function, wait = 1) {
  let timeout: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), wait);
  }
}

export function dateToISOWithOffset(date: Date, type: "start" | "end"): string {
  if (type == "start") date.setHours(1, 0, 0, 0);
  else date.setHours(23, 59, 59);

  return convertToUTCForPostgres(date.toISOString());
}

function convertToUTCForPostgres(dateString: string) {
  const date = new Date(dateString);
  const utcString = date.toISOString();
  return utcString;
}
import { RouteProps } from 'react-router-dom';
import { SettingsSectionConsent } from './sections/consent.component';
import { SettingsSectionCurrency } from './sections/currency/list/currency.component';
import { SettingsSectionDriverRequirements } from './sections/driver-requirements.component';
import { SettingsSectionGeneral } from './sections/general.component';
import { SettingsSectionLanguage } from './sections/language.component';
import { SettingsSectionMeter } from './sections/meter.component';
// import { SettingsSectionNotification } from './sections/notification.component';
import { SettingsSectionPayment } from './sections/payment.component';
import { SettingsSectionPenalties } from './sections/penalties.component';
import { SettingsSectionShifts } from './sections/shifts.component';
import { SettingsSectionVehicleRequirements } from './sections/vehicle-requirements.component';
import { SettingsSectionPayouts } from './sections/payouts.component';

export enum SettingsRoute {
  General = '/settings/general',
  Meter = '/settings/meter',
  Payment = '/settings/payment',
  Shift = '/settings/shift',
  Penalty = '/settings/penalty',
  Payout = '/settings/payout',
  Consent = '/settings/consent',
  // Notifications = '/settings/notifications',
  Language = '/settings/language',
  Currency = '/settings/currency',
  DriverRequirement = '/settings/driverRequirement',
  VehicleRequirement = '/settings/vehicleRequirement',
}

export const SettingsRouteMap = [
  {
    path: SettingsRoute.General,
    component: SettingsSectionGeneral,
  },
  {
    path: SettingsRoute.Meter,
    component: SettingsSectionMeter,
  },
  {
    path: SettingsRoute.Payment,
    component: SettingsSectionPayment,
  },
  {
    path: SettingsRoute.Shift,
    component: SettingsSectionShifts,
  },
  {
    path: SettingsRoute.Penalty,
    component: SettingsSectionPenalties,
  },
  {
    path: SettingsRoute.Payout,
    component: SettingsSectionPayouts,
  },
  {
    path: SettingsRoute.Consent,
    component: SettingsSectionConsent,
  },
  // {
  //   path: SettingsRoute.Notifications,
  //   component: SettingsSectionNotification,
  // },
  {
    path: SettingsRoute.Language,
    component: SettingsSectionLanguage,
  },   {
    path: SettingsRoute.DriverRequirement,
    component: SettingsSectionDriverRequirements,
  },
  {
    path: SettingsRoute.VehicleRequirement,
    component: SettingsSectionVehicleRequirements,
  },
  {
    path: SettingsRoute.Currency,
    component: SettingsSectionCurrency,
  },
] as RouteProps[];

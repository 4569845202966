import React, { useEffect, useState } from "react"
import { MapSection } from "../components/section"
import { Trip as _Trip } from "../../../../entities/trip.entity"
import { Driver } from "../../../../entities/driver.entity"
import { toTitleCase } from "../../../../util/string.util";
import { formatDistance } from "date-fns";
import { decodePolyLine } from "../../../../util/decodePolyline";
import { Api, Endpoint } from "../../../../services/api.service";

interface Trip extends _Trip {
  driver: Driver;
}

export interface MapTripFilterData {
  from?: string;
  to?: string;
  searchText?: string;
  pickupLocation?: string;
  dropoffLocation?: string;
}

interface Props {
  setPolyline : any;
  setSelectedTrip : any;
  trips: Trip[];
  loading: boolean;
  expanded?: boolean;
  searchText:string;
  setSearchText:React.Dispatch<React.SetStateAction<string>>;
  onExpandChange?: (expanded: boolean) => void;
  onFilter?: (filter: MapTripFilterData) => void;
}

export function DashboardMapTrips({ setPolyline, setSelectedTrip, trips, loading, expanded, onExpandChange, onFilter, searchText, setSearchText }: Props) {

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [tripsState, setTripsState] = useState<Trip[]>([]);

  useEffect(()=>{
    if(trips.length > 0){
      setTripsState(trips.map(trip => ({ ...trip, isActive: false })))
    }
  },[trips])

  function getDriverName(trip: Trip) {
    if (trip.driver) {
      return [trip.driver.firstName, trip.driver.lastName].join(' ')
    }
    return 'N/A'
  }

  function getTripStatus(trip: Trip): string {
    return toTitleCase(trip.status);
  }

  function getTripTime(trip: Trip): string {
    return formatDistance(new Date(trip.createTime), new Date());
  }

  const samplePath = [
    { lat: 37.7749, lng: -122.4194 }, // San Francisco, CA
    { lat: 34.0522, lng: -118.2437 }, // Los Angeles, CA
    { lat: 41.8781, lng: -87.6298 },  // Chicago, IL
  ];

  async function getDecodedTripPath(trip : Trip) {
    const data = await Api.get<any, any>(Endpoint.C2_TRIP_DETAIL_BY_ID, { id: trip.id });
    
    const updatedTrips = tripsState.map((t : Trip) => ({
      ...t,
      isActive: t.id === data.id ? !t.isActive : false,
    }));
  
    setTripsState(updatedTrips);
  
    const decodedPath = decodePolyLine(data?.encodedPath);
    const result = decodedPath?.map((coord : String[]) => ({ lat: coord[0], lng: coord[1] }));
    setPolyline(result?.length > 0 ? result : []);
    setSelectedTrip(data);
  
    if (trip.isActive) {
      setSelectedTrip(null);
    }

  }

  function handleFilterSubmit(e: React.FormEvent<HTMLButtonElement>) {
    if (onFilter) {
      onFilter({
        from,
        to,
        searchText,
        pickupLocation,
        dropoffLocation
      });
    }
    e.preventDefault();
  }

  function renderBody() {
    if (loading) {
      return <></>
    }

    if (tripsState.length === 0) {
      return <div className='map-section-empty'>No trips found</div>
    }
    
    return (
      tripsState.map((trip: Trip) => (
        <a onClick={() => { getDecodedTripPath(trip); }} className={`map-section-item ${ trip.isActive ? "map-active":""}`} key={ trip.id }>
          <div className='map-section-item-description'>
            {trip.code}
            <br />
            {getDriverName(trip)}
            < br />
            <i>{getTripStatus(trip)}</i>
          </div>
          <div className='map-section-item-timestamp'>{getTripTime(trip)}</div>
        </a>
      ))
    );
  }

  return (
    <MapSection
      title='Trips'
      notificationCount={ tripsState.length }
      loading={ loading }
      expanded={ expanded }
      onFilter={ handleFilterSubmit }
      onExpandChange={ onExpandChange }
      filterChildren={
        <>
          <div className='map-section-filter'>
            <label>From</label>
            <input
              type="datetime-local"
              placeholder="From"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className='map-section-filter'>
            <label>To</label>
            <input
              type="datetime-local"
              placeholder="To"
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
          <div className='map-section-filter'>
            <label>Search</label>
            <input
              type="search"
              placeholder="Driver / Trip Code"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className='map-section-filter'>
            <label>Pickup</label>
            <input
              type="search"
              placeholder="123 Street / London / NW1 4NP"
              value={pickupLocation}
              onChange={(e) => setPickupLocation(e.target.value)}
            />
          </div>
          <div className='map-section-filter'>
            <label>Dropoff</label>
            <input
              type="search"
              placeholder="123 Street / London / NW1 4NP"
              value={dropoffLocation}
              onChange={(e) => setDropoffLocation(e.target.value)}
            />
          </div>
          <div className='map-section-filter'>
            <button type="submit" className='btn btn-primary'>Search</button>
          </div>
        </>
      }
      bodyChildren={ renderBody() }
    />
  )
}
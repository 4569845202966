import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormSection } from "../../../../components/form/section.component";
import { Operator } from "../../../../entities/operator.entity";
import { Settings } from "../../../../entities/settings.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";
interface props{ 
  settings:Settings|undefined

}
export function VehiclePostOperator({settings}:props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const [operators, setOperators] = useState<Operator[]>([]);

  useEffect(() => { init(); }, [])

  const init = async () => {
    const operatorList = await Api.get<IPaginationResponse<Operator>, IPaginationOptions>(Endpoint.OPERATOR_LIST);
    setOperators(operatorList.items);
  }

  return (
    <FormSection
      title={t("vehicle.operatorSection.title")}
      subtitle={t("vehicle.operatorSection.subTitle")}
      className="col-sm-6"
    >
      <div className="row">
        <FormSelect<Operator>
          label={t("vehicle.operatorSection.operatorsLabel")}
          name="operator"
          className="col-sm-12"
          showLabel={true}
          validation={{required:settings?.vehicleRequirementOperator?t("vehicle.operatorSection.validationMessage"):false}}
          items={operators}
          labelField="title"
          onChange={() => { }}
          tabIndex={16}
          {...formProps}
        />
      </div>
    </FormSection>
  );

}

import { RouteProps } from "react-router-dom";
import IncidentList from "./incident/list/list.component";
import IncidentPost from "./incident/post/post.component";
import { ProtocolList } from "./protocols/list/list.component";
import { ProtocolPost } from "./protocols/post/post.component";
import { TrackVehicle } from "./track/track.component";
import { VideoMonitoring } from "./video/video.component";
import EmergencyList from './emergency/list/list.component';
import { TrackShift } from "./trackShift/trackShift";
import { ShiftTrackComponent } from "./trackShift/track-shift";

export enum SecurityRoute {
  IncidentList = '/incident/list',
  IncidentPost = '/incident/post',
  VideoMonitoring = '/security/video/monitoring',
  BookingTrackVehicle = '/security/track/:code?',
  ProtocolPost = '/security/protocols/post',
  ProtocolList ='/security/protocols/list',
  EmergencyList = '/security/emergency/list',
  TrackShift = '/security/track-shift/:code',
}

export const SecurityRouteMap = [
  {
    path: SecurityRoute.IncidentList,
    component: IncidentList,
  },
  {
    path: SecurityRoute.IncidentPost,
    component: IncidentPost,
  },
  {
    path: SecurityRoute.VideoMonitoring,
    component: VideoMonitoring,
  },
  {
    path: SecurityRoute.BookingTrackVehicle,
    component: TrackVehicle,
  },
  {
    path: SecurityRoute.TrackShift,
    component: ShiftTrackComponent,
  },
  {
    path: SecurityRoute.ProtocolPost,
    component: ProtocolPost,
  },
  {
    path:SecurityRoute.ProtocolList,
    component: ProtocolList,
  },
  {
    path: SecurityRoute.EmergencyList,
    component: EmergencyList
  }

] as RouteProps[];

import { formatDistance } from "date-fns";
import React, { useEffect, useState } from "react";
import { Driver } from "../../../../entities/driver.entity";
import { Transaction as _Transaction } from "../../../../entities/transaction.entity";
import { getCurrencyFormatting } from "../../../../util/distance";
import { MapSection } from "../components/section";

interface Transaction extends _Transaction {
  driver: Driver;
}

export interface MapTransactionFilterData {
  from?: string;
  to?: string;
  searchText?: string;
  provider?: string;
  reason?: string;
}

interface Props {
  setSelectedTransaction : any;
  transactions: Transaction[];
  loading: boolean;
  expanded?: boolean;
  searchText:string;
  setSearchText:React.Dispatch<React.SetStateAction<string>>;
  onExpandChange?: (expanded: boolean) => void;
  onFilter?: (filter: MapTransactionFilterData) => void;
}

export function DashboardMapTransactions({ setSelectedTransaction,transactions, loading, expanded, onExpandChange, onFilter, searchText, setSearchText }: Props) {

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [provider, setProvider] = useState<string>();
  const [reason, setReason] = useState<string>();
  const [transactionsState, setTransactionsState] = useState<Transaction[]>([]);

  useEffect(()=>{
    if(transactions.length > 0){
      setTransactionsState(transactions.map(transaction => ({ ...transaction, isActive: false })))
    }
  },[transactions])

  function getDriverName(transaction: Transaction) {
    if (transaction.driver) {
      return [transaction.driver.firstName, transaction.driver.lastName].join(' ')
    }
    return 'N/A'
  }

  function getTransactionAmount(transaction: Transaction): string {
    return getCurrencyFormatting(transaction.amount);
  }

  function getTransactionTime(transaction: Transaction): string {
    return formatDistance(new Date(transaction.createTime), new Date());
  }

  function getTransactionsTotal(): number {
    return transactions.reduce((total, transaction) => total + transaction.amount, 0);
  }

  function getTransactionsTotalEarnings(): number {
    return transactions.reduce((total, transaction) => total + transaction.earning, 0);
  }

  function handleFilterSubmit(e: React.FormEvent<HTMLButtonElement>) {
    if (onFilter) {
      onFilter({
        from,
        to,
        searchText,
        provider,
        reason,
      });
    }
    e.preventDefault();
  }

  async function getSelectedTransaction(transaction : Transaction) {    
    const updatedTransactions = transactionsState.map((t : Transaction) => ({
      ...t,
      isActive: t.id === transaction.id ? !t.isActive : false,
    }));
  
    setTransactionsState(updatedTransactions);
    setSelectedTransaction(transaction);
  
    if (transaction.isActive) {
      setSelectedTransaction(null);
    }

  }

  return (
    <MapSection
      title='Transactions'
      subtitle={`Total: ${getCurrencyFormatting(getTransactionsTotal())} / ${getCurrencyFormatting(getTransactionsTotalEarnings())}`}
      notificationCount={ transactions.length }
      loading={ loading }
      expanded={ expanded }
      onExpandChange={ onExpandChange }
      onFilter={ handleFilterSubmit }
      filterChildren={
        <>
          <div className='map-section-filter'>
            <label>From</label>
            <input
              type="datetime-local"
              placeholder="From"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className='map-section-filter'>
            <label>To</label>
            <input
              type="datetime-local"
              placeholder="To"
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
          <div className='map-section-filter'>
            <label>Search</label>
            <input
              type="search"
              placeholder="Driver / Transaction Code"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className='map-section-filter'>
            <label>Method</label>
            <select value={provider} onChange={(e) => setProvider(e.target.value)}>
              <option>All</option>
              <option value="cash">Cash</option>
              <option value="stripe">Credit Card</option>
            </select>
          </div>
          <div className='map-section-filter'>
            <label>Type</label>
            <select value={reason} onChange={(e) => setReason(e.target.value)}>
              <option>All</option>
              <option value="ride-payment">Trip Payment</option>
              <option value="driver-manual">Manual Payment</option>
            </select>
          </div>
          <div className='map-section-filter'>
            <button type="submit" className='btn btn-primary'>Search</button>
          </div>
        </>
      }
      bodyChildren={transactionsState.map((transaction: Transaction) => (
        <a onClick={() => { getSelectedTransaction(transaction); }} className={`map-section-item ${ transaction.isActive ? "map-active":""}`} key={ transaction.id }>
          <div className='map-section-item-description'>
            { transaction.code.toUpperCase() }
            <br />
            { getDriverName(transaction)} facilited a payment of { getTransactionAmount(transaction) }
          </div>
          <div className='map-section-item-timestamp'>{ getTransactionTime(transaction) }</div>
        </a>
      ))}
    />
  )
}
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormSection } from "../../../../components/form/section.component";
import { useTranslation } from "react-i18next";
import { PhoneInputs } from "../../../../components/form/input/phoneNumber";
import { Settings } from "../../../../entities/settings.entity";

interface Props {
  value: string;
  onChange: any;
  settings: Settings | undefined
}
export function DriverPostEmergency({ value, onChange, settings }: Props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  return (
    <FormSection
      title={t("driver.emergencySection.title")}
      subtitle={t("driver.emergencySection.subTitle")}
    >
      <div className="row">
        <FormInput
          label={t("common.fullName")}
          name="emergencyName"
          type="text"
          validation={{ required: settings?.driverRequirementEmergencyName ? true : false }}
          className="col-sm-6"
          tabIndex={0}
          {...formProps}
        />
        {/* <FormInput
          label={ t("common.phone") }
          name="emergencyPhone"
          type="tel"
          className="col-sm-6"
          tabIndex={12}
          { ...formProps }
        /> */}

        <PhoneInputs
          placeholder={t("common.phone")}
          value={value}
          name="emergencyPhone"
          onChange={onChange}
          validation={{ required: settings?.driverRequirementEmergencyPhone ? true : false }}
          tabIndex={0}
          className="col-sm-6"
          {...formProps}
        />
        <FormInput
          label={t("common.email")}
          name="emergencyEmail"
          type="email"
          validation={{ required: settings?.driverRequirementEmergencyEmail ? true : false }}
          className="col-sm-6"
          tabIndex={0}
          {...formProps}
        />
      </div>
    </FormSection>
  );

}

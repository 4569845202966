import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormUpload, UploadPreviewUrl, selectedUrl } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { getUrl } from "../../../../components/form/util";
import { Driver, DriverFileType } from "../../../../entities/driver.entity";
import { IFileType } from "../../../../entities/file-type.entity";
import { IFile } from "../../../../entities/file.entity";
import { Api, Endpoint } from "../../../../services/api.service";

interface id {
  id: string | undefined;
  picId: string
  fileType: DriverFileType
}

interface Props {
  driver?: Driver;
  fileTypes: IFileType[];
}

export function DriverPostVerification({ driver, fileTypes }: Props) {
  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  const { getValues } = formProps;

  const fileToUrl = (fileType: IFileType): UploadPreviewUrl[] | undefined => {
    if (!driver) {
      return;
    }
    const files = driver.files?.filter((file: { file: IFile }) => file.file.fileType?.id === fileType.id).map((file: { file: IFile }) => file.file);
    if (!files || files.length === 0) {
      return undefined;
    }
    return files.map((file: IFile) => ({
      thumb: getUrl(`/driver/${driver.id}/${file.fileType!.id}/${file.id}/admin_single.${file.extension}`),
      full: getUrl(`/driver/${driver.id}/${file.fileType!.id}/${file.id}/admin_large.${file.extension}`),
    }))
  }
  const FormIdentityFrontOnclick = async (e: any) => {
    try {
      const form: Driver = getValues() as Driver;
      let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/identity/`).toString()
      identityId = identityId?.substring(1, 37);
      if (form.identityFiles && form.identityFiles.length > 0 && identityId) {
        await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.IdentityFront });
      }
    }
    catch (error) {
      console.log("error in file")
    }
  }

  return (
    <FormSection
      title={t("driver.verificationSection.title")}
      subtitle={t("driver.verificationSection.subTitle")}>
      {fileTypes.map((fileType, index) => (
        <FormUpload
          key={fileType.id}
          fileType={fileType}
          urls={fileToUrl(fileType)}
          FormEditingOnclick={FormIdentityFrontOnclick}
          tabIndex={index + 22}
          {...formProps}
        />
      ))}
    </FormSection>
  );
}

import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormHeader } from "../../../../components/form/header.component";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormUpload, selectedUrl, UploadPreviewUrl } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { getUrl } from "../../../../components/form/util";
import { IFile } from "../../../../entities/file.entity";
import { Vehicle, VehicleFileType } from "../../../../entities/vehicle.entity";
import { Enviroment } from "../../../../enums/environment.enum";
import { FormHeaderType } from "../../../../enums/form.enum";
import { Api, Endpoint } from "../../../../services/api.service";
import { IFileType } from "../../../../entities/file-type.entity";
import { Settings } from "../../../../entities/settings.entity";

interface id {
  id: string | undefined;
  picId: string  
  fileType: VehicleFileType
}


interface props{ 
  settings: Settings;
}

export function VehiclePostModel({ settings }:props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const getUrls = (type: VehicleFileType): UploadPreviewUrl[] | undefined => {
    const vehicle: Vehicle = formProps.control.defaultValuesRef.current as Vehicle;
    switch (type) {
      case VehicleFileType.Profile:
        let profileId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/vehicle/${vehicle.id}/profile/`).toString()
        profileId = profileId?.substring(1, 37);
        const profileDocument = vehicle.profileFiles?.filter(file => file.id != profileId)
        return profileDocument ? profileDocument.map(file => fileToUrl(vehicle, file)) : [];
      case VehicleFileType.Registration:
        let registrationId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/vehicle/${vehicle.id}/registration/`).toString()
        registrationId = registrationId?.substring(1, 37);
        const registrationDocument = vehicle.registrationFiles?.filter(file => file.id != registrationId)
        return registrationDocument ? registrationDocument.map(file => fileToUrl(vehicle, file)) : [];
      case VehicleFileType.Insurance:
        let insuranceId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/vehicle/${vehicle.id}/insurance/`).toString()
        insuranceId = insuranceId?.substring(1, 37);
        const insuranceDocument = vehicle.insuranceFiles?.filter(file => file.id != insuranceId)
        return insuranceDocument ? insuranceDocument.map(file => fileToUrl(vehicle, file)) : [];
    }
    return;
  }

  const fileToUrl = (vehicle: Vehicle, file: IFile): UploadPreviewUrl => {
    return {
      thumb: getUrl(`/vehicle/${vehicle.id}/${file.fileType!.id}/${file.id}/admin_single.${file.extension}`),
      full: getUrl(`/vehicle/${vehicle.id}/${file.fileType!.id}/${file.id}/admin_large.${file.extension}`),
    }
  }

  const FormRegistrationOnClick = async(e: any) => {
    const form: Vehicle = formProps.getValues() as Vehicle;
    let registrationId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/vehicle/${form.id}/registration/`).toString()
    registrationId = registrationId?.substring(1, 37);
    if (form.registrationFiles && form.registrationFiles.length > 0 && registrationId) { 
      await Api.post<any, id>(Endpoint.VEHICLE_FILE_DELETE, { id: form.id, picId: registrationId, fileType: VehicleFileType.Registration });
    } 
  }

  const FormInsuranceOnClick = async(e: any) => {
    const form: Vehicle = formProps.getValues() as Vehicle;
    let insuranceId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/vehicle/${form.id}/insurance/`).toString()
    insuranceId = insuranceId?.substring(1, 37);
    if (form.insuranceFiles && form.insuranceFiles.length > 0 && insuranceId) { 
      await Api.post<any, id>(Endpoint.VEHICLE_FILE_DELETE, { id: form.id, picId: insuranceId, fileType: VehicleFileType.Insurance });
    } 
  }

  const FormProfileOnClick = async(e: any) => {
    const form: Vehicle = formProps.getValues() as Vehicle;
    let profileId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/vehicle/${form.id}/profile/`).toString()
    profileId = profileId?.substring(1, 37);
    if (form.profileFiles && form.profileFiles.length > 0 && profileId) { 
      await Api.post<any, id>(Endpoint.VEHICLE_FILE_DELETE, { id: form.id, picId: profileId, fileType: VehicleFileType.Profile });
    } 
  }

  return (
    <FormSection
      title={t("vehicle.modelSection.title")}
      subtitle={t("vehicle.modelSection.subTitle")}
    >
      <div className="row">
        <FormInput
          label={t("vehicle.modelSection.year")}
          name="year"
          type="number"
          className="col-sm-4"
          validation={{required:settings?.vehicleRequirementModel?true:false,  min: 1980, max: 2025}}
          tabIndex={5}
          {...formProps}
        />
        <FormInput
          label={t("vehicle.modelSection.make")}
          name="make"
          type="text"
          className="col-sm-4"
          validation={{required:settings?.vehicleRequirementMake?true:false}}
          tabIndex={6}
          {...formProps}
        />
        <FormInput
          label={t("vehicle.modelSection.model")}
          name="model"
          type="text"
          className="col-sm-4"
          validation={{required:settings?.vehicleRequirementModel?true:false}}
          tabIndex={7}
          {...formProps}
        />
      </div>
      <div>
      </div>
      <div className="row">
        <FormInput
          label={t("vehicle.modelSection.color")}
          name="color"
          type="text"
          className="col-sm-4"
          validation={{required:settings?.vehicleRequirementColor?true:false}}

          tabIndex={8}
          {...formProps}
        />
        {process.env.REACT_APP_NAME == Enviroment.Poles ? null :
          <>
            <FormInput
              label={t("vehicle.modelSection.numberOfPassenger")}
              name="numPassengers"
              type="text"
              className="col-sm-4"
              defualtValue={4}
              validation={{ required: true }}

              tabIndex={9}
              {...formProps}
            />
            <FormInput
              label={t("vehicle.modelSection.numberOfLuggages")}
              name="numLuggage"
              type="text"
              defualtValue={2}
              className="col-sm-4"
              validation={{ required: true }}
              
              tabIndex={10}
              {...formProps}
            />
          </>
        }
      </div>


    </FormSection>


  );

}


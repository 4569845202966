import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter, StatusFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Passenger, PassengerStatus } from "../../../entities/passenger.entity";
import { Role, RoleAssignment } from "../../../entities/role.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { AuthService } from "../../../services/auth.service";
import { getStorageUrl } from "../../../util/file.util";
import { checkAssignment } from "../../../util/roleAssignment";
import { PassengerPost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";

export enum ProfilePicSize {
  List = 'admin_list',
  Single = 'admin_single',
}

const groupFilters: GroupFilter[] = [
  { title: 'all', icon: 'phone-21' },
  { title: 'active', icon: 'documents-03' },
  { title: 'blocked', icon: 'ui-15' },
  { title: 'unverified', icon: 'alert-circle' },
];

// const statusFilters: StatusFilter[] = [
//   { title: "online", status: ListItemStatus.Green },
//   { title: "on-trip", status: ListItemStatus.Blue },
//   { title: "offline", status: ListItemStatus.Red },
// ];

export function PassengerList(props: any) {

  const { y: pageYOffset } = useWindowScroll();

  const [allCount, setAllCount] = useState<number>(0);
  const [unverifiedCount, setUnverifiedCount] = useState<number>(0);
  const [activeCount, setActiveCount] = useState<number>(0);
  const [blockedCount, setBlockedCount] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [changeStatus, setChangeStatus] = useState<PassengerStatus | undefined>(undefined)
  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [passengers, setPassengers] = useState<Passenger[] | undefined>(undefined);
  const [selectedPassenger, setSelectedPassenger] = useState<Passenger>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[1]);
  const [activeStatusFilter, setActiveStatusFilter] = useState<StatusFilter | undefined>(undefined);
  const [size, setSize] = useState<number>()
  const [addButtonDisplay,setAddButtonDisplay] = useState<boolean>(false)
  const { t } = useTranslation('list');


  useEffect(()=>{
    // check if given module and action is assigned to the role or not return boolean value
    setAddButtonDisplay(checkAssignment("passenger","list-all")|| checkAssignment("passenger","list-own"))
  },[])
  
  useEffect(() => {
    setSelectedPassenger(props.location.state)
  }, [props.location.state])

  const refresh = useCallback(async () => {
    let group: string | undefined = activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;
    const status = activeStatusFilter?.title;
    const { items, totalItems } = await Api.get<IPaginationResponse<Passenger>, IPaginationOptions>(Endpoint.PASSENGER_LIST, { page: 0, limit, group, status, search });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      if (props.location.state != null) {
        setSelectedPassenger(props.location.state);
        setPassengers(items);
      }
      else {
        setPassengers(items);
      }

    }

    const ITEMS = await Api.get<
      IPaginationResponse<Passenger>,
      IPaginationOptions
    >(Endpoint.PASSENGER_LIST, { limit: 10000000 });

    setAllCount(ITEMS.totalItems)
    setActiveCount(ITEMS.items.filter(item => item.status === 'active').length)
    setUnverifiedCount(ITEMS.items.filter(item => item.status === 'unverified').length)
    setBlockedCount(ITEMS.items.filter(item => item.status === 'blocked').length)

    if (selectedPassenger?.status === PassengerStatus.Blocked) {
      setChangeStatus(PassengerStatus.Active)
    } else if (selectedPassenger?.status === PassengerStatus.Active) {
      setChangeStatus(PassengerStatus.Blocked)
    } else {
      setChangeStatus(undefined)
    }

    if (items.length > 0 && !selectedPassenger) {
      setSelectedPassenger(items[0]);
    }
  }, [search, activeGroupFilter, limit, activeStatusFilter, selectedPassenger]);

  useEffect(() => { refresh(); }, [refresh]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const getProfilePic = (Passenger: Passenger, type: ProfilePicSize): string => {
    return `${getStorageUrl()}/Passenger/${Passenger.id}/pic/${Passenger.plateFile?.id}/${type}.jpeg`;
  }

  const getFullName = (Passenger: Passenger): string => {
    return [Passenger.firstName, Passenger.lastName].join(' ');
  }

  const toListItems = (Passenger?: Passenger): ListItem<Passenger> | undefined => {
    if (!Passenger) {
      return;
    }

    return {
      id: Passenger.id as string,
      title: getFullName(Passenger),
      status: ListItemStatus.Green,
      ref: Passenger
    }
  }

  const addPassenger = () => {
    setFormMode(FormMode.Adding);
  }

  const editPassenger = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (passengers) {
      const currentPassengerIndex = passengers.findIndex(Passenger => !!selectedPassenger && Passenger.id === selectedPassenger.id);
      if (currentPassengerIndex + 1 < passengers.length && currentPassengerIndex !== -1) {
        setSelectedPassenger(passengers[currentPassengerIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (passengers) {
      const currentPassengerIndex = passengers.findIndex(Passenger => !!selectedPassenger && Passenger.id === selectedPassenger.id);
      if (currentPassengerIndex - 1 > -1 && currentPassengerIndex !== -1) {
        setSelectedPassenger(passengers[currentPassengerIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedPassenger(undefined);
    setActiveGroupFilter(target);
  }

  const selectStatus = (target: StatusFilter | undefined) => {
    setSelectedPassenger(undefined);
    setActiveStatusFilter(target?.title !== activeStatusFilter?.title ? target : undefined);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  const statusChange = async () => {
    if (selectedPassenger?.status === PassengerStatus.Active) {
      await Api.patch(Endpoint.PASSENGER, { id: selectedPassenger.id, status: selectedPassenger.status = PassengerStatus.Blocked })
      refresh()
    } else if (selectedPassenger?.status === PassengerStatus.Blocked) {
      await Api.patch(Endpoint.PASSENGER, { id: selectedPassenger.id, status: selectedPassenger.status = PassengerStatus.Active })
      refresh()
    }
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  const deletePassenger = async () => {
    await Api.delete(Endpoint.PASSENGER, { id: selectedPassenger?.id });
    setSelectedPassenger(undefined);
    refresh();
  }

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'all', icon: 'phone-21', count: allCount },
                  { title: 'active', icon: 'documents-03', count: activeCount },
                  { title: 'blocked', icon: 'ui-15', count: blockedCount },
                  { title: 'unverified', icon: 'alert-circle', count: unverifiedCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                activeStatusFilter={activeStatusFilter}
                onGroupSelect={selectGroup}
                onStatusSelect={selectStatus}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={passengers?.map(toListItems) as ListItem<Passenger>[]}
                activeItem={toListItems(selectedPassenger)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={refresh}
                onClick={(item: ListItem<Passenger>) => { setSelectedPassenger(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                passengers !== undefined ? (
                  selectedPassenger ? (
                    <View
                      componentName={"passenger"}
                      changePassengerStatus={changeStatus}
                      showAdd={true}
                      showEdit={true}
                      showNext={true}
                      showPrev={true}
                      showAudit={true}
                      showDelete={true}
                      showStateChange={true}
                      onDelete={deletePassenger}
                      onAdd={addPassenger}
                      onEdit={editPassenger}
                      onStateChange={statusChange}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedPassenger.id)}
                    >
                      <SelectedItemContent
                        selectedPassenger={selectedPassenger}
                        getFullName={getFullName}
                        getProfilePic={getProfilePic}
                      />
                    </View>
                  ) : (
                    addButtonDisplay && <div className="mx-auto py-5 text-center">
                      <button onClick={addPassenger} className="btn btn-primary text-bold">{t("passenger.addPassenger")}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("passenger.wait")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedPassenger ? (
                  <PassengerPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedPassenger.id}
                  />
                ) : (
                  <PassengerPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: 'all', icon: 'phone-21', count: allCount },
              { title: 'active', icon: 'documents-03', count: activeCount },
              { title: 'blocked', icon: 'ui-15', count: blockedCount },
              { title: 'unverified', icon: 'alert-circle', count: unverifiedCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            activeStatusFilter={activeStatusFilter}
            onGroupSelect={selectGroup}
            onStatusSelect={selectStatus}
          />
          <ListItems
            items={passengers?.map(toListItems) as ListItem<Passenger>[]}
            activeItem={toListItems(selectedPassenger)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={refresh}
            onClick={(item: ListItem<Passenger>) => { setSelectedPassenger(item.ref); }}
          />
          {formMode === undefined ? (
            passengers !== undefined ? (
              selectedPassenger ? (
                <View
                  componentName={"passenger"}
                  changePassengerStatus={changeStatus}
                  showAdd={true}
                  showEdit={true}
                  showNext={true}
                  showPrev={true}
                  showAudit={true}
                  showDelete={true}
                  showStateChange={true}
                  onDelete={deletePassenger}
                  onAdd={addPassenger}
                  onEdit={editPassenger}
                  onStateChange={statusChange}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedPassenger.id)}
                >
                  <SelectedItemContent
                    selectedPassenger={selectedPassenger}
                    getFullName={getFullName}
                    getProfilePic={getProfilePic}
                  />
                </View>
              ) : (
                addButtonDisplay &&<div className="mx-auto py-5 text-center">
                  <button onClick={addPassenger} className="btn btn-primary text-bold">{t("passenger.addPassenger")}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("passenger.wait")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedPassenger ? (
              <PassengerPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedPassenger.id}
              />
            ) : (
              <PassengerPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
function setAssignments(assignData: RoleAssignment[]) {
  throw new Error("Function not implemented.");
}

function setModuleCodes(arg0: { module: import("../../../enums/core.enum").ModuleName; action: import("../../../interfaces/core.interface").AclCode; }[]) {
  throw new Error("Function not implemented.");
}


import { formatDistanceToNowStrict } from "date-fns";
import React, { useState } from "react";
import { Driver } from "../../../../entities/driver.entity";
import { Session as _Session } from "../../../../entities/session.entity";
import { toTitleCase } from "../../../../util/string.util";
import { MapSection } from "../components/section";
import Select, { ValueType } from "react-select";

interface Session extends _Session {
  driver: Driver;
}

export interface MapDriverFilterData {
  searchText?: string;
  activities?: string[];
}

interface Props {
  drivers?: Driver[];
  loading: boolean;
  expanded?: boolean;
  onExpandChange?: (expanded: boolean) => void;
  onFilter?: (filter: MapDriverFilterData) => void;
  setSelectedGps: React.Dispatch<React.SetStateAction<any>>
  setSelectedDriverDetails: React.Dispatch<React.SetStateAction<any>>
  onDriverSelected: (filter: MapDriverFilterData) => void;
  searchText:string;
  setSearchText:React.Dispatch<React.SetStateAction<string>>;
}

export function DashboardMapDrivers({ drivers, loading, expanded, onExpandChange, onFilter, setSelectedGps, setSelectedDriverDetails, onDriverSelected, setSearchText, searchText }: Props) {

  const [selectedDriver, setSelectedDriver] = useState<string>();
  const [activities, setActivities] = useState<ValueType<{ label: string; value: string; }, true>>([{ label: "Available", value: "online" },
  { label: "Hired", value: "on-trip" }]);

  const menuPortalTargetRef: React.MutableRefObject<HTMLElement | null> = React.useRef(null);

  React.useEffect(() => {
    menuPortalTargetRef.current = document.body;
  }, []);

  const customStyles = {
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),
  };
  const options = [
    { label: "Available", value: "online" },
    { label: "Hired", value: "on-trip" },
    { label: "Unavailable", value: "offline" }
  ];

  function getDriverName(driver: Driver): string {
    return [driver.firstName, driver.lastName].join(' ')
  }

  function getDriverStatus(driver: Driver): string {
    return toTitleCase(driver.activity);
  }
  function getDriverNameFromSession(session: Session): string {
    return [session.driver.firstName, session.driver.lastName].join(' ')
  }

  function getDriverStatusFromSession(session: Session): string {
    return toTitleCase(session.driver.activity);
  }

  function getSessionDuration(session: Session): string {
    const startTime = new Date(session.createTime);
    return formatDistanceToNowStrict(startTime);
  }

  function handleFilterSubmit(e: React.FormEvent<HTMLButtonElement>) {
    if (onFilter) {
      let tempActivities = activities?.map((item: { value: string }) => item.value);
      onFilter({
        searchText,
        activities: tempActivities,
      });
    }
    e.preventDefault();
  }

  function renderBody() {
    if (drivers !== undefined) {
      return renderDrivers();
    }
    return;
  }

  const onDriverItemClick = (driver: Driver) => {

    setSelectedGps(selectedDriver === driver.id ? undefined : driver.gps);
    setSelectedDriver(selectedDriver === driver.id ? undefined : driver.id);
    setSelectedDriverDetails(driver);
    
    let tempActivities = [{label:'drivercode',value:driver.code}]
    let squery = {
      searchText:driver.code, tempActivities
    }
    onDriverSelected(squery)
  }

  function renderDrivers() {
    if (!drivers || drivers.length === 0) {
      return <div className='map-section-empty'>No drivers found</div>
    }
    return drivers.map((driver: Driver) => (
      <div className={`map-section-item ${driver.id === selectedDriver ?
        "selected-driver" : ""}`} key={driver.id} onClick={() => onDriverItemClick(driver)}>
        <div className='map-section-item-description'>
          {getDriverName(driver)}
          <br />
          <i>{getDriverStatus(driver)}</i>
        </div>
      </div>
    ))
  }

  return (
    <MapSection
      title='Drivers'
      notificationCount={drivers !== undefined ? drivers.length : 0}
      loading={loading}
      expanded={expanded}
      onExpandChange={onExpandChange}
      onFilter={handleFilterSubmit}
      filterChildren={
        <>
          <div className='map-section-filter'>
            <label>Search</label>
            <input
              type="search"
              placeholder="Driver Name / Phone / Code"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className='map-section-filter'>
            <label>Status</label>
            <Select
              placeholder={"Status"}
              backspaceRemovesValue={true}
              isSearchable
              hideSelectedOptions={true}
              value={activities}
              isLoading={false}
              isMulti={true}
              options={options}
              closeMenuOnSelect={true}
              escapeClearsValue={false}
              autoFocus={false}
              styles={customStyles}
              menuPortalTarget={menuPortalTargetRef.current}
              noOptionsMessage={() => "No items to show"}
              onChange={(value) => setActivities(value as any)}
            />
          </div>
          <div className='map-section-filter'>
            <button type="submit" className='btn btn-primary'>Search</button>
          </div>
        </>
      }
      bodyChildren={renderBody()}
    />
  )
}
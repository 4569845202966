import { GoogleMap, OverlayView, useJsApiLoader, Polyline, Marker, DirectionsRenderer, Circle } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from 'react';
import { Driver } from '../../../entities/driver.entity';
import { GPS as _GPS } from '../../../entities/gps.entity';
import { Session as _Session } from '../../../entities/session.entity';
import { Transaction as _Transaction } from '../../../entities/transaction.entity';
import { Trip as _Trip } from '../../../entities/trip.entity';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { StatsFilterPeriod, getDatesForFilters } from '../../../interfaces/stats.interface';
import { Api, ApiType, Endpoint } from '../../../services/api.service';
import { dateToISOWithOffset } from '../../../util/function.util';
import MapStyles from './map.style.json';
import { DashboardMapDispatch } from "./section/dispatch.component";
import { DashboardMapDrivers, MapDriverFilterData } from './section/drivers.component';
import { DashboardMapNotifications } from './section/notifications.component';
import { DashboardMapTransactions, MapTransactionFilterData } from './section/transactions.component';
import { DashboardMapTrips, MapTripFilterData } from './section/trips.component';
import { toSubUnit } from "../../../util/currency";
import { Vehicle } from "../../../entities/vehicle.entity";
import { add } from 'date-fns';
import { getGeneralTimeFormat, getGeneralZoneDistanceTitle, getGeneralZoneDistanceValue } from "../../../util/distance";
import { Service } from "../../../entities/service.entity";
import { IPaginationOptions } from "../../../interfaces/paginate.interface";
import { Zone } from "../../../entities/zone.entity";
import { Address } from "../../../entities/address.entity";
import { Currency } from "../../../entities/currency.entity";
import { EstimateFareRequest } from "../../support/dto/request";

interface GPS extends _GPS {
  active: boolean;
  driverId?: string;
}

interface ShowDetailsInterface {
  value: Trip | Transaction | Driver;
  type: "Trip" | "Transaction" | "Driver";
  onClose?: () => void;
}

interface Notification {
  title: string;
  createTime: Date;
}

interface Trip extends _Trip {
  driver: Driver;
}

interface Session extends _Session {
  driver: Driver;
}

interface Transaction extends _Transaction {
  driver: Driver;
}


interface Driver2 {
  firstName: string;
  lastName: string;
  code: string;
  phone: string;
  activity: string;
  gps: any;
}
const LOCAL_STORAGE_CENTER_LAT = 'dashboard-map-center-lat';
const LOCAL_STORAGE_CENTER_LNG = 'dashboard-map-center-lng';
const LOCAL_STORAGE_ZOOM = 'dashboard-map-zoom';
let directionsService: google.maps.DirectionsService;
let searchDriversData: Driver[] = [];
export function DashboardMap() {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  })

  const { height } = useWindowDimensions();

  const [map, setMap] = useState<google.maps.Map>();

  const [loading, setLoading] = useState(false);
  const [gpsList, setGpsList] = useState<GPS[]>([]);
  const [dropOffMarker, setDropOffMarker] = useState<boolean>(false);
  const [pickupMarker, setPickupMarker] = useState<boolean>(false);
  const [center, setCenter] = useState<google.maps.LatLng>(new google.maps.LatLng(51.4985262, -0.1264053));
  const [zoom, setZoom] = useState<number>(11);
  const [selectedGps, setSelectedGps] = useState<GPS | undefined>(undefined);
  const [trafficDuration, setTrafficDuration] = useState<number>();
  const [transactionSearchText, setTransactionSearchText] = useState("");
  const [driverSearchText, setDriverSearchText] = useState("");
  const [tripSearchText, setTripSearchText] = useState("");

  const [distance, setDistance] = useState<number>();
  const [duration, setDuration] = useState<number>();
  const [estimate, setEstimate] = useState<number>(0);
  const [generalCurrency, setGeneralCurrency] = useState<Currency>();
  const [zoneCurrent, setZoneCurrent] = useState<Zone>();
  const [paxFirstName, setPaxFirstName] = useState('');
  const [paxLastName, setPaxLastName] = useState('');
  const [phone, setPhone] = useState('');

  const memoizedCenter = React.useMemo(() => new google.maps.LatLng({ lat: center.lat(), lng: center.lng() }), [center]);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [selectedTrip, setSelectedTrip] = useState<Trip>()
  const [drivers, setDrivers] = useState<Driver[]>();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction>()
  const [polyline, setPolyline] = useState<any>(null)
  const [directions, setDirections] = useState<google.maps.DirectionsResult>();
  const [expandedSection, setExpandedSection] = useState<'notifications' | 'trips' | 'drivers' | 'transactions' | undefined>();
  const [pickupPlace, setPickupPlace] = useState<google.maps.places.PlaceResult>();
  const [dropoffPlace, setDropoffPlace] = useState<google.maps.places.PlaceResult>();
  const [service, setService] = useState<{ value: string | undefined; label: string }>();
  const [circle, setCircle] = useState<{ lat: number, lng: number } | null>(null);
  const [selecteddriverDetails, setSelectedDriverDetails] = useState<Driver | Driver2>();
  useEffect(() => {
    if (!pickupPlace || !dropoffPlace) {
      if (pickupPlace && pickupPlace.geometry && pickupPlace.geometry.location) {
        setCenter(pickupPlace.geometry.location);
        setZoom(18);
      }
      if (dropoffPlace && dropoffPlace.geometry && dropoffPlace.geometry.location) {
        setCenter(dropoffPlace.geometry.location);
        setZoom(18);
      }
    }
    if (!pickupPlace && !dropoffPlace) return;
    // Set departure time to 10 minues from now
    const departureTime = add(new Date(), { minutes: 10, hours: 5 });
    if (pickupPlace?.geometry?.location || dropoffPlace?.geometry?.location || pickupMarker || dropOffMarker)
      directionsService?.route({
        // @ts-ignore
        origin: pickupPlace.geometry.location,
        // @ts-ignore
        destination: dropoffPlace?.geometry?.location,
        travelMode: google.maps.TravelMode.DRIVING,
        durationInTraffic: true,
        drivingOptions: { departureTime }
      }, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          setDirections(result);
          const route = result.routes[0].legs[0];
          if (!route || !route.distance || !route.duration) { return }
          setDistance(route.distance.value);
          setDuration(route.duration_in_traffic ? route.duration_in_traffic.value : route.duration.value);
          const trafficTime = route.duration_in_traffic ? route.duration_in_traffic.value - route.duration.value : 0;
          if (trafficTime > 0) setTrafficDuration(trafficTime);
          else setTrafficDuration(0);
        }
      });
  }, [pickupPlace, dropoffPlace, pickupMarker, dropOffMarker]);

  useEffect(() => {
    if (!distance || !duration || !service?.value) {
      console.log(`incomplete ${pickupPlace} ${distance} ${duration} ${trafficDuration} ${service?.value}`)
      return;
    }
    const loadEstimate = async () => {
      const response: any = await Api.post<number | undefined, EstimateFareRequest>(Endpoint.FARE_ESTIMATE, {
        lat: pickupPlace?.geometry?.location?.lat(),
        lng: pickupPlace?.geometry?.location?.lng(),
        distance: distance,
        duration: duration,
        traffic: trafficDuration,
        serviceId: service?.value,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }, ApiType.Rest);
      setEstimate(response);
    }
    loadEstimate();
  }, [pickupPlace, distance, duration, trafficDuration, service]);

  const onLoad = useCallback(async function callback(map: google.maps.Map) {
    setMap(map)

    init()

    const centerLat = localStorage.getItem(LOCAL_STORAGE_CENTER_LAT);
    const centerLng = localStorage.getItem(LOCAL_STORAGE_CENTER_LNG);
    const zoom = localStorage.getItem(LOCAL_STORAGE_ZOOM);
    if (centerLat && centerLng && zoom) {
      setCenter(new google.maps.LatLng(parseFloat(centerLat), parseFloat(centerLng)));
      setZoom(parseInt(zoom));
    }
    onDriverFilter({ activities: ["on-trip", "online"] });

    directionsService = new google.maps.DirectionsService();

    const data = await Api.get<Service[], IPaginationOptions>(Endpoint.SERVICE_PUBLIC_LIST, {}, ApiType.Rest);
    for (let i = 0; i < data.length; i++) {
      if (data[i].isDefault === true) {
        setService({ value: data[i].id, label: data[i].title })
      }
    }

  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(undefined)
  }, []);

  useEffect(() => {
    if (selectedGps) {
      const newCenter = new google.maps.LatLng(selectedGps.latitude, selectedGps.longitude);
      setCenter(newCenter);
    }
  }, [selectedGps]);

  useEffect(() => {
    if (map) {
      const center = map.getCenter();
      const zoom = map.getZoom();
      if (!center || !zoom) return;
      localStorage.setItem(LOCAL_STORAGE_CENTER_LAT, center.lat().toString());
      localStorage.setItem(LOCAL_STORAGE_CENTER_LNG, center.lng().toString());
      localStorage.setItem(LOCAL_STORAGE_ZOOM, zoom.toString());
    }
  }, [center, map, zoom]);

  async function init() {
    const filterPeriod = StatsFilterPeriod.Today;
    const { startDate: fromDate, endDate: toDate } = getDatesForFilters(filterPeriod);

    setLoading(true);

    const response = await Api.post<{
      trips: Trip[],
      transactions: Transaction[],
      sessions: Session[],
    }, {
      from: string,
      to: string,
    }>(Endpoint.C2_INIT, {
      from: dateToISOWithOffset(fromDate, "start"),
      to: dateToISOWithOffset(toDate, "end"),
    });

    setTrips(response.trips);
    setTransactions(response.transactions);

    setNotifications([
      ...response.trips.map((trip) => ({
        title: `${trip.driver.firstName} started trip ${trip.code}`,
        createTime: trip.createTime,
      })),
      ...response.transactions.map((transaction) => ({
        title: `${transaction.driver.firstName} carried out transaction ${transaction.code.toUpperCase()} of ${transaction.amount}`,
        createTime: transaction.createTime,
      })),
      ...response.sessions.map((session) => ({
        title: `${session.driver.firstName} started shift ${session.code}`,
        createTime: session.createTime,
      })),
    ]
      .sort((a, b) => new Date(b.createTime).getTime() - new Date(a.createTime).getTime())
      .slice(0, 10)
    );

    setLoading(false);
  }

  async function onDriverSelected(filter: MapDriverFilterData) {
    onTransactionFilter(filter);

    let tripfilter = {
      serchText: filter.searchText,
      from: '',
      to: ''

    }
    onTripFilter(tripfilter);
  }

  async function onTripFilter(filter: MapTripFilterData) {
    setLoading(true);

    const response = await Api.post<Trip[], MapTripFilterData>(Endpoint.C2_TRIP_SEARCH, filter);
    setTrips(response);
    setLoading(false);
  }

  async function onDriverFilter(filter: MapDriverFilterData) {
    setLoading(true);
    const response = await Api.post<Driver[], MapDriverFilterData>(Endpoint.C2_DRIVER_SEARCH, filter);
    const gpsTempList: GPS[] = response.map((item) => item.gps).filter((item: GPS) => item !== undefined);
    setGpsList(() => {
      const updatedGpsList = [...gpsTempList];
      return updatedGpsList;
    });
    setDrivers(response);
    setLoading(false);
    searchDriversData = response
  }

  async function onTransactionFilter(filter: MapTransactionFilterData) {
    setLoading(true);
    const response = await Api.post<Transaction[], MapTransactionFilterData>(Endpoint.C2_TRANSACTION_SEARCH, filter);
    setTransactions(response);
    setLoading(false);
  }

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: height ? height - 100 : 705,
        }}
        center={memoizedCenter}
        clickableIcons={false}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          styles: MapStyles,
        }}
        zoom={zoom}
        onLoad={(map) => {
          onLoad(map);
        }}
        onUnmount={onUnmount}
        onDragEnd={() => {
          const newCenter = map?.getCenter();
          if (!newCenter) return;
          setCenter(
            new google.maps.LatLng({
              lat: newCenter.lat(),
              lng: newCenter.lng(),
            })
          );
        }}
        onZoomChanged={() => {
          const zoom = map?.getZoom();
          if (zoom) setZoom(zoom);
        }}
      >
        {pickupPlace && (
          <Marker
            position={pickupPlace?.geometry?.location as google.maps.LatLng}
          />
        )}
        {dropoffPlace && (
          <Marker
            position={dropoffPlace?.geometry?.location as google.maps.LatLng}
          />
        )}
        {directions && <DirectionsRenderer options={{ directions }} />}
        {gpsList &&
          gpsList.length > 0 &&
          gpsList.map((gps: GPS, index: number) => (
            <OverlayView
              key={index}
              position={new google.maps.LatLng(gps.latitude, gps.longitude)}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div
                className="map-marker"
                onClick={() => {
                  if (selectedGps?.id === gps.id) {
                    setSelectedGps(undefined);
                    return;
                  }
                  setSelectedGps(gps);
                  setTripSearchText(gps.driver.code);
                  setTransactionSearchText(gps.driver.code);
                  onTripFilter({ searchText: gps.driver.code });
                  onTransactionFilter({ searchText: gps.driver.code });

                  let driver = {
                    firstName: gps.driver.firstName,
                    lastName: gps.driver.lastName,
                    code: gps.driver.code,
                    phone: gps.driver.phone,
                    activity: gps.driver.activity,
                    gps: {
                      vehicle: gps.vehicle,

                    }
                  }
                  setSelectedDriverDetails(driver as Driver2)
                }}
              >
                <h4>
                  {gps.driver.firstName} {gps.driver.lastName}
                </h4>
                <div
                  className={`map-dot driver driver-status-${gps.driver.activity}`}
                >
                  <div className="ringring"></div>
                  <div className="circle"></div>
                </div>

              </div>
            </OverlayView>
          ))}

        {polyline && (
          <Polyline path={polyline} options={{ strokeColor: "#FF0000" }} />
        )}

        {circle && (
          <Circle
            radius={5000} // Radius in meters (5 km)
            options={{
              fillColor: "rgba(44,91,100,0.6)", // Circle fill color
              strokeColor: "rgba(44,91,100,1)", // Circle border color
              strokeWeight: 2,
              center: circle, // Circle border weight
            }}
          />
        )}
        <div className="map-ui-wrapper-left">
          <DashboardMapDispatch
            setCircle={setCircle as React.Dispatch<React.SetStateAction<{ lat: number; lng: number; } | null>>}
            map={map as google.maps.Map}
            setPickupPlace={setPickupPlace as React.Dispatch<
              React.SetStateAction<google.maps.places.PlaceResult>
            >}

            setDropoffPlace={setDropoffPlace as React.Dispatch<
              React.SetStateAction<google.maps.places.PlaceResult>
            >}
            setDropOffMarker={setDropOffMarker}
            setPickupMarker={setPickupMarker}
            setZoneCurrent={setZoneCurrent}
            setGeneralCurrency={setGeneralCurrency}
            setPhone={setPhone}
            setPaxFirstName={setPaxFirstName}
            setPaxLastName={setPaxLastName}
            phone={phone}
            paxFirstName={paxFirstName}
            paxLastName={paxLastName}
            distance={distance}
            duration={duration}
            service={service}
            zoneCurrent={zoneCurrent}
            estimate={estimate} />
        </div>
        <div className="map-ui-wrapper">
          <div className="map-ui-wrapper-right">
            <div className="d-flex">
              {selectedTrip && (
                <ShowDetails
                  value={selectedTrip}
                  type={"Trip"}
                  onClose={() => {
                    setSelectedTrip(undefined);
                  }}
                />
              )}
              {selectedTransaction && (
                <ShowDetails
                  value={selectedTransaction}
                  type={"Transaction"}
                  onClose={() => {
                    setSelectedTransaction(undefined);
                  }}
                />
              )}
              {
                selecteddriverDetails && (
                  <ShowDetails
                    value={selecteddriverDetails as Driver}
                    type="Driver"
                    onClose={() => {
                      setSelectedDriverDetails(undefined)
                    }} />
                )
              }
              <div className="map-sections">
                <DashboardMapNotifications
                  notifications={notifications}
                  loading={loading}
                  expanded={expandedSection === "notifications"}
                  onExpandChange={(expanded) =>
                    setExpandedSection(
                      expanded ? "notifications" : expandedSection
                    )
                  }
                />
                <DashboardMapTrips
                  searchText={tripSearchText}
                  setSearchText={setTripSearchText}
                  setPolyline={setPolyline}
                  trips={trips}
                  loading={loading}
                  expanded={expandedSection === "trips"}
                  onExpandChange={(expanded) =>
                    setExpandedSection(expanded ? "trips" : expandedSection)
                  }
                  onFilter={onTripFilter}
                  setSelectedTrip={setSelectedTrip}
                />
                <DashboardMapDrivers
                  setSearchText={setDriverSearchText}
                  searchText={driverSearchText}
                  drivers={drivers}
                  loading={loading}
                  setSelectedGps={setSelectedGps}
                  expanded={expandedSection === "drivers"}
                  onExpandChange={(expanded) =>
                    setExpandedSection(expanded ? "drivers" : expandedSection)
                  }
                  onDriverSelected={onDriverSelected}
                  setSelectedDriverDetails={setSelectedDriverDetails}
                  onFilter={onDriverFilter}
                />
                <DashboardMapTransactions
                  transactions={transactions}
                  loading={loading}
                  searchText={transactionSearchText}
                  setSearchText={setTransactionSearchText}
                  expanded={expandedSection === "transactions"}
                  onExpandChange={(expanded) =>
                    setExpandedSection(
                      expanded ? "transactions" : expandedSection
                    )
                  }
                  onFilter={onTransactionFilter}
                  setSelectedTransaction={setSelectedTransaction}
                />
              </div>
            </div>
          </div>
        </div>
      </GoogleMap>

    )
  );
}

function ShowDetails({ value, type, onClose }: ShowDetailsInterface) {

  switch (type) {
    case "Trip":
      return (
        <div className="map-details">
          <h4>
            <div>Trip Details</div>
            <div><a className='os-icon os-icon-close' onClick={onClose}></a></div>
          </h4>
          <table>
            <tbody>
              <tr>
                <td>Driver Name</td>
                <td>{value?.driver.firstName} {value?.driver.lastName}</td>
              </tr>
              <tr>
                <td>Driver Code</td>
                <td>{value?.driver.code.toUpperCase()}</td>
              </tr>
              <tr>
                <td>Driver Phone</td>
                <td>{value?.driver.phone}</td>
              </tr>
              <tr>
                <td>Vehicle</td>
                <td>{`${(value?.vehicle as Vehicle).year} ${(value?.vehicle as Vehicle).make} ${(value?.vehicle as Vehicle).model}`}</td>
              </tr>
              <tr>
                <td>Plate</td>
                <td>{(value?.vehicle as Vehicle).plate}</td>
              </tr>
              <tr>
                <td>Distance</td>
                <td>{`${((value.distance || 0) / getGeneralZoneDistanceValue()).toFixed(3)} ${getGeneralZoneDistanceTitle()}`}</td>
              </tr>
              <tr>
                <td>Wait Time</td>
                <td>{getGeneralTimeFormat(value.waitTime || 0)}</td>
              </tr>
              <tr>
                <td>Ride Time</td>
                <td>{getGeneralTimeFormat(value.rideTime || 0)}</td>
              </tr>
              <tr>
                <td>Total Fare</td>
                <td>{toSubUnit(value?.totalFare || 0)}</td>
              </tr>
              <tr>
                <td>Tip</td>
                <td>{toSubUnit(value?.tip || 0)}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>{value?.type}</td>
              </tr>
              <tr>
                <td>Paid Via</td>
                <td>{(value?.paidBy || "pending").toUpperCase()}</td>
              </tr>

            </tbody>
          </table>
        </div>
      );
    case "Transaction":
      return (
        <div className="map-details">
          <h4>
            <div>Transaction Details</div>
            <div><a className='os-icon os-icon-close' onClick={onClose}></a></div>
          </h4>
          <table>
            <tbody>
              <tr>
                <td>TXN Ref</td>
                <td>{value?.code.toUpperCase()}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>{value?.createTime ? new Date(value?.createTime).toLocaleDateString() : ""}</td>
              </tr>
              <tr>
                <td>Time</td>
                <td>{value?.createTime ? new Date(value?.createTime).toLocaleTimeString() : ""}</td>
              </tr>
              <tr>
                <td>Trip Code</td>
                <td>{(value?.trip as Trip)?.code || "N/A"}</td>
              </tr>
              {
                value?.trip?.passenger && <tr>
                  <td>Passenger</td>
                  <td>
                    {
                      value?.trip?.passenger?.firstName || ''
                    }
                    {' '}
                    {
                      value?.trip?.passenger?.lastName || ''
                    }
                  </td>
                </tr>
              }
              <tr>
                <td>Type</td>
                <td>{value?.reason === 'driver-manual' ? 'Manual Payment' : 'Trip Payment'}</td>
              </tr>
              <tr>
                <td>Paid via</td>
                <td>{value?.provider === 'stripe' ? 'Credit Card' : 'Cash'}</td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>{toSubUnit((value?.amount || 0) - (value?.tip || 0))}</td>
              </tr>
              <tr>
                <td>+ Tip</td>
                <td>{toSubUnit(value?.tip || 0)}</td>
              </tr>
              <tr>
                <td>= Total Amount</td>
                <td>{toSubUnit((value?.amount || 0))}</td>
              </tr>
              <tr>
                <td>Total Amount</td>
                <td>{toSubUnit((value?.amount || 0))}</td>
              </tr>
              <tr>
                <td>- Service Fee</td>
                <td>{toSubUnit((value?.applicationFee || 0))}</td> 
              </tr>
              <tr>
                <td>= Earning</td>
                <td>{toSubUnit(value?.earning || 0)}</td>
              </tr>
              {value.refunded ? <>
                <tr>
                  <td>Refunded</td>
                  <td>{value.refunded}</td>
                </tr>
                <tr>
                  <td>Refunded By</td>
                  <td>{value?.refundedBy}</td>
                </tr>
                <tr>
                  <td>Refund Date</td>
                  <td>{new Date(value?.refundedAt).toLocaleDateString()}</td>
                </tr>
                <tr>
                  <td>Refund Time</td>
                  <td>{new Date(value?.refundedAt).toLocaleTimeString()}</td>
                </tr>
              </> : ""}

              <tr>
                <td>Paid</td>
                <td>{value?.isPaid ? 'Yes' : 'No'}</td>
              </tr>

              {value?.providerPaymentId && <tr>
                <td>Provider Link</td>
                <td><a href={`https://dashboard.stripe.com/payments/${value?.providerPaymentId}`} target="_blank">Click to open</a></td>
              </tr>}


            </tbody>

          </table>
        </div>
      );
    case "Driver":
      return (
        <div className="map-details">
          <h4>
            <div> Driver Details</div>
            <div><a className='os-icon os-icon-close' onClick={onClose}></a></div>
          </h4>
          <div className="map-marker-details">
            <table>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{value?.firstName} {value?.lastName}</td>
                </tr>
                <tr>
                  <td>Code</td>
                  <td>{value?.code}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{value?.phone}</td>
                </tr>
                <tr>
                  <td>Plate</td>
                  <td>{value?.gps?.vehicle?.plate}</td>
                </tr>
                <tr>
                  <td>Vehicle</td>
                  <td>
                    {value?.gps?.vehicle?.year} {value?.gps?.vehicle?.make}{" "}
                    {value?.gps?.vehicle?.model}
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{value?.activity}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    default:
      return <></>;
  }

} 

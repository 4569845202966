import React, { useCallback, useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormPropsContext } from "../../../../components/form/form.component";
import { FormCheckBox } from "../../../../components/form/input/checkbox.component";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormRadio } from "../../../../components/form/input/radio.component";
import { FormUpload, UploadPreviewUrl } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { getUrl } from "../../../../components/form/util";
import { Driver } from "../../../../entities/driver.entity";
import { Settings } from "../../../../entities/settings.entity";
import { FormMode } from "../../../../enums/core.enum";
import { urlShow } from "../post.component";

interface props {
  picShow?: boolean
  FormEditingOnclick?: (e: any) => void,
  FormAddingOnclick?: () => void,
  settings: Settings | undefined
}

export function DriverPostPublic({ FormEditingOnclick, FormAddingOnclick, settings }: props) {
  const { t } = useTranslation('forms');
  const { mode } = useContext(FormPropsContext);
  const formProps = useFormContext();
  const { getValues } = formProps;
  const urlShows = useContext(urlShow)

  const getProfilePicUrls = useCallback((): UploadPreviewUrl[] | undefined => {
    const form: Driver = getValues() as Driver;
    if (urlShows === false) {
      if (form.id && mode === FormMode.Editing) {
        if (getProfilePic(form) && getProfilePic(form) !== undefined) {
          return [getProfilePic(form) as UploadPreviewUrl];
        }
        else {
          return undefined;
        }
      }
    }
    return undefined;
  }, [urlShows])

  useEffect(() => {
    getProfilePicUrls()
  }, [getProfilePicUrls])

  const getProfilePic = (driver: Driver): UploadPreviewUrl | undefined => {
    const pic = driver.picFile;
    if (driver.id && pic?.id && pic?.extension) {
      return {
        thumb: getUrl(`/driver/${driver.id}/pic/${pic?.id}/admin_single.${pic?.extension}`),
        full: getUrl(`/driver/${driver.id}/pic/${pic?.id}/admin_large.${pic?.extension}`),
      }
    }
    else {
      return undefined;
    }
  }

  return (
    <FormSection
      title={t("driver.publicSection.title")}
      subtitle={t("driver.publicSection.subTitle")}>
      <div className="row" style={{ height: '25vh' }}>
        {/* <FormUpload
          cropMode={true}
          urls={getProfilePicUrls()}
          label={t("driver.publicSection.profilePic")}
          name="newPicFile"
          originalName="picFile"
          className="col-md-4 col-sm-12"
          validation={{ required: settings?.driverRequirementPicFile ? true : false }}
          FormEditingOnclick={FormEditingOnclick}
          tabIndex={0}
          aspectRatio={"square"}
          {...formProps}
        /> */}
      </div>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <FormInput
              label={t("common.code")}
              name="code"
              type="text"
              tabIndex={-1}
              {...formProps}
            />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <FormInput
            label={t("common.firstName")}
            name="firstName"
            type="text"
            autoFocus
            tabIndex={0}
            validation={{ required: settings?.driverRequirementFirstName ? true : false }}
            {...formProps}
          />
        </div>
        <div className="col-md-4 col-sm-12">
          <FormInput
            label={t("common.lastName")}
            name="lastName"
            type="text"
            validation={{ required: settings?.driverRequirementLastName ? true : false }}
            tabIndex={0}
            {...formProps}
          />
        </div>
      </div>
      <div className="col-md-4 col-sm-12">
        <FormRadio label={t("common.gender")} name="gender" validation={{ required: settings?.driverRequirementGender ? true : false }} {...formProps}
          options={[
            { label: t("common.genderMale"), value: 'male' },
            { label: t("common.genderFemale"), value: 'female' },
          ]}
          tabIndex={0}
        />
        <FormCheckBox
          validation={{ required: false }}
          label={t('common.isDemo')}
          name="isDemo"
          tabIndex={0}
          {...formProps} />
      </div>
    </FormSection>
  );
}
import { IEntityBase, IEntityCreatedBy, IEntityTimestamp } from "../interfaces/entity.interface";
import { Address } from "./address.entity";
import { Consent } from "./consent.entity";
import { DriverCheckList } from "./driverCheckList.entity";
import { Feature } from "./feature.entity";
import { IFile } from "./file.entity";
import { Fleet } from "./fleet.entity";
import { GPS } from "./gps.entity";
import { Group } from "./group.entity";
import { Network } from "./network.entity";
import { Operator } from "./operator.entity";

export enum DriverStatus {
  New = 'new',
  PendingApproval = 'pending-approval',
  PendingData = 'pending-data',
  Failed = 'failed',
  Active = 'active',
  Blocked = 'blocked',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
}

export enum Activity {
  Online = 'online',
  Offline = 'offline',
  OnTrip = 'on-trip',
}

export enum DriverFileType {
  Pic = 'pic',
  License = 'license',
  IdentityFront = 'identityFront',
  IdentityBack = 'identityBack',
  Police = 'police',
  History = 'history',
  Insurance = 'insurance',
  Medicare = "medicare",
  Vevo = "vevo",
  Proficiency = "proficiency"
}

export enum StripeStatus {
  NoAccount = "no-account",
  PendingVerification = "pending-verification",
  Overdue = "overdue",
  Failed = "failed",
  Active = "active",
  Warning = "warning"
}

interface DropzoneFile extends File {
  dataUrl?: string;
}

export interface Driver
  extends IEntityBase,
  IEntityTimestamp,
  IEntityCreatedBy {
  [key: string]: any;
  code: string;
  firstName: string;
  lastName: string;
  gender: Gender | undefined;
  email: string;
  phone: string;
  dateOfBirth: Date | string;
  address?: Address;
  type?: string;
  balance?: number;
  businessRegistration?: string;
  features?: Feature[] | { id: string }[];
  fleets?: Fleet[] | { id: string }[];
  networks?: Network[] | { id: string }[];
  consent: Consent;
  operator: Operator;
  groups?: Group[] | { id: string }[];
  driverCheckList: DriverCheckList[];
  licenseNumber: string;
  licenseType: string;
  licenseExpiryDate: Date | string;
  emergencyName?: string;
  emergencyEmail?: string;
  emergencyPhone?: string;
  bankName?: string;
  bankAccountNumber?: string;
  bankAccountRouting?: string;
  bankAccountTitle?: string;

  activity?: Activity;

  picFile?: IFile;
  newPicFile?: DropzoneFile;

  licenseFiles?: IFile[];
  newLicenseFiles: DropzoneFile[];

  identityFiles?: IFile[];
  newIdentityFiles: DropzoneFile[];

  policeFiles?: IFile[];
  newPoliceFiles: DropzoneFile[];

  historyFiles?: IFile[];
  newHistoryFiles: DropzoneFile[];

  vevoFiles?: IFile[];
  newVevoFiles: DropzoneFile[];

  medicareCardFiles?: IFile[];
  newMedicareCardFiles: DropzoneFile[];

  englishLanguageProficiencyFiles?: IFile[];
  newEnglishLanguageProficiencyFiles: DropzoneFile[];
  ip?: string;
  status?: DriverStatus;
  deletedTime: Date;
  stripeStatus: StripeStatus;
  etag1: string;
  etag2: string;
}

import { formatDistance } from "date-fns";
import React from "react";
import { MapSection } from "../components/section";

interface Notification {
  title: string;
  createTime: Date;
}

interface Props {
  notifications: Notification[];
  loading: boolean;
  expanded?: boolean;
  onExpandChange?: (expanded: boolean) => void;
}

export function DashboardMapNotifications({ notifications, loading, expanded, onExpandChange }: Props) {

  function getNotificationTime(notification: Notification): string {
    return formatDistance(new Date(notification.createTime), new Date());
  }

  return (
    <MapSection
      title='Notifications'
      loading={ loading }
      expanded={ expanded }
      onExpandChange={ onExpandChange }
      notificationCount={ notifications.length }
      filterChildren={
        <>
          <div className='map-section-filter'>
            <label>From</label>
            <input type='datetime-local' placeholder='From' />
          </div>
          <div className='map-section-filter'>
            <label>To</label>
            <input type='datetime-local' placeholder='To' />
          </div>
          <div className='map-section-filter'>
            <label>Search</label>
            <input type='search' placeholder='Driver / Trip / Payment Reference' />
          </div>
        </>
      }
      bodyChildren={ notifications.map((notification: Notification) => (
        <a className='map-section-item' key={ notification.title }>
          <div className='map-section-item-description'>{ notification.title }</div>
          <div className='map-section-item-timestamp'>{ getNotificationTime(notification) }</div>
        </a>
      )) }
    />
  )
}
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import { StatsComponent } from '../../../components/stats/stats.component';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Operator } from '../../../entities/operator.entity';
import { getDatesForFilters, StatsFilterPeriod, StatsRequest, StatsResponse } from '../../../interfaces/stats.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { getCurrencyFormatting, getGeneralDistanceFormat, getGeneralTimeFormat } from '../../../util/distance';

interface Props {
  selectedOperator: Operator
  onRefresh?: () => void,
  setSelectedOperator?:(operator:undefined|Operator)=>void
}

export function SelectedItemContent({ selectedOperator, onRefresh, setSelectedOperator }: Props) {
  const { t } = useTranslation(['forms', 'dashboard']);
  const [operator, setOperator] = useState<Operator>();
  const [open, setOpen] = useState(false); 
  const [stats, setStats] = useState<StatsResponse>();
  const [time, setTime] = useState<StatsFilterPeriod>(StatsFilterPeriod.Today);

  const refreshStats = useCallback(async (customStart?: Date, customEnd?: Date) => {
    let startDate: Date, endDate: Date, timeline = time;
    if (customStart && customEnd) {
      startDate = customStart; endDate = customEnd; timeline = StatsFilterPeriod.Custom;
    }
    else {
      ({ startDate, endDate } = getDatesForFilters(time));
    }
    const response = await Api.get<StatsResponse, StatsRequest>(Endpoint.STATS, {
      startDate, endDate, timeline, operator: selectedOperator.id
    });

    console.log('stats:', response)
    setStats(response)
  }, [time, operator])

  useEffect(() => {
    refreshStats();
  }, [refreshStats]);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const getLocaleDateOfConsent = (operator: Operator | undefined): string => {
    return operator && operator.createTime ? new Date(operator.createTime).toLocaleDateString() : '';
  }

  const onclickRecover = async (selectedOperator: Operator) => {
    console.log('Recover This Operator:', selectedOperator);
    const response = await Api.post<Operator, { id: string }>(Endpoint.OPERATOR_RECOVER, { id: selectedOperator.id as string });
    console.log('Recover This Operator Response:', response)
    if (onRefresh && setSelectedOperator) {
      setSelectedOperator(undefined);
      onRefresh();
    }
  }
  const onclickActivate = async (selectedOperator: Operator) => {
    console.log('Activate This Operator:', selectedOperator);
    const response = await Api.post<Operator, { id: string }>(Endpoint.OPERATOR_ACTIVATE, { id: selectedOperator.id as string });
    console.log('Activate This Operator Response:', response)
    if (onRefresh && setSelectedOperator) {
      setSelectedOperator(undefined);
      onRefresh();
    }
  }
  const init = useCallback(async () => {
    setOperator(selectedOperator);
    const operator = await Api.get<Operator, { id: string }>(Endpoint.OPERATOR, { id: selectedOperator.id as string });
    console.log(operator);
    setOperator(operator);
  }, [selectedOperator]);
  useEffect(() => {
    init();
  }, [selectedOperator]);

  function onCustomSelect(startDate: Date, endDate: Date) {
    refreshStats(startDate, endDate);
  }

  return (
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={selectedOperator.title}
      >
        <ViewContentItems>
          {selectedOperator.deletedTime ? <div className="alert alert-success borderless">
            <h5 className="alert-heading">Operator Deleted</h5>
            <p>Do you want to recover this operator account?</p>
            <div className="alert-btn">
              <a className="btn btn-white-gold" href={'#href'} onClick={() => onclickRecover(selectedOperator)}>
                <i className="os-icon os-icon-ui-92"></i>
                <span>{'Recover Account'}</span>
              </a>
            </div>
          </div> : null
          }        
          {selectedOperator.status=='blocked' ? <div className="alert alert-danger borderless">
          <h5 className="alert-heading">Operator Blocked</h5>
          <p>Do you want to activate this operator account?</p>
          <div className="alert-btn">
            <a className="btn btn-white-gold" href={'#href'} onClick={() => onclickActivate(selectedOperator)}>
              <i className="os-icon os-icon-ui-92"></i>
              <span>{'Activate Account'}</span>
            </a>
          </div>
        </div> : null
        }
          <ViewContentItem title={t('operator.basicSection.title')}>
            {selectedOperator.title}
          </ViewContentItem>

          <ViewContentItem title={t('operator.basicSection.contactPerson')}>
            {selectedOperator.contactPerson}
          </ViewContentItem>

          <ViewContentItem title={t('operator.basicSection.contactPhone')}>
            {selectedOperator.contactPhone}
          </ViewContentItem>

          <ViewContentItem title={t('operator.basicSection.contactEmail')}>
            {selectedOperator.contactEmail}
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.contactAddress')}>
            {selectedOperator.contactAddress?.text}
          </ViewContentItem>
          <ViewContentItem title="Consent">
            {operator?.consent ? <div style={{ color: "#047bf8" }} onClick={onOpenModal}>Version {operator?.consent.operatorVersion} ({getLocaleDateOfConsent(operator)})</div> : null}
            <Modal open={open} onClose={onCloseModal} center>
              <h2 style={{ marginTop: '20px' }}>Operator Agreement</h2>
              <p>{operator?.consent?.operatorAgreement}</p>
              <h2 style={{ marginTop: '5px' }}>Operator Use Of Information</h2>
              <p>{operator?.consent?.operatorUseOfInformation}</p>
            </Modal>
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.businessRegistration')}>
            {selectedOperator.businessRegistration}
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.accreditionNumber')}>
            {selectedOperator.accreditionNumber}
          </ViewContentItem>
          <ViewContentItem title={t(`${process.env.REACT_APP_ROUTING_LABEL}`)}>
            {selectedOperator.bankSwiftCode}
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.bankName')}>
            {selectedOperator.bankName}
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.bankAccountNumber')}>
            {selectedOperator.bankAccountNumber}
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.bankAccountTitle')}>
            {selectedOperator.bankAccountTitle}
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent>

      <div className="col-md-6">
        <StatsComponent currentRange={time} onRangeChange={(e) => setTime(e.target.value)} onCustomSelect={onCustomSelect} data={!stats ? [] : [
          { label: t('driver.stats.rides'), value: stats.tripCount, isPositive: stats.tripCount >= stats.prevTripCount, percentage: stats.tripCountPercentage },
          { label: t('driver.stats.distance'), value: getGeneralDistanceFormat(stats.distance), isPositive: stats.distance >= stats.prevDistance, percentage: stats.distancePercentage },
          { label: t('driver.stats.earnings'), value: getCurrencyFormatting(stats.revenue), isPositive: stats.revenue >= stats.prevDistance, percentage: stats.revenuePercentage },
          { label: t('driver.stats.shiftTime'), value: getGeneralTimeFormat(stats.rideTime), isPositive: stats.rideTime >= stats.prevRideTime, percentage: stats.rideTimePercentage }
        ]} />
      </div>
    </ContentWrapper>
  );
}
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink, Redirect, RouteComponentProps } from "react-router-dom";
import { Loading } from "../../components/alerts/loading.component";
import { FormInput } from "../../components/form/input/input.component";
import { User } from "../../entities/user.entity";
import { Device } from "../../entities/device.entity";
import { Api, ApiType, Endpoint } from "../../services/api.service";
import { AuthService } from "../../services/auth.service";
import { DashboardRoute } from "../dashboard/routes";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { Settings } from "../../entities/settings.entity";
import { SettingsService } from "../../services/settings.service";
import { UploadPreviewUrl } from "../../components/form/input/upload.component";
import { getUrl } from "../../components/form/util";

interface LoginRequest {
  email: string;
  password: string;
  rememberMe: boolean;
  firebaseToken?: string;
  deviceFingerprint?: string;
}

interface LoginVerificationResponse {
  verificationCode: string
}
interface LoginVerificationRequest {
  verificationCode: string
  deviceFingerprint: string
}

interface LoginResponse {
  accessToken: string;
  chatToken: string;
  user: User;
  device: Device;
  deviceStatus: DeviceStatus

}

export enum DeviceStatus {
  Active = 'active',
  Unverified = 'unverified',
  Inactive = 'inactive',
  Blocked = 'blocked'
}


export enum LoginSteps {
  Login = "login",
  Verification = 'verification',
  FingerPrint = 'fingerprint'
}

/**
 * Login screen
 */
export function AuthLogin({ history }: RouteComponentProps) {
  const [processing, setProcessing] = useState<boolean>(false);
  const [, setErrorMessage] = useState<string | null>();
  const [step, setStep] = useState<LoginSteps>(LoginSteps.Login);
  const [email, setEmail] = useState<string>();
  const [deviceFingerprint, setDeviceFingerprint] = useState<string>();
  const [user, setUser] = useState<User>();
  const [device, setDevice] = useState<Device>();
  const [accessToken, setAccessToken] = useState<string>();
  const [rememberMe, setRememberMe] = useState<boolean>()
  const [settingLogo, setSettingLogo] = useState<string>();


  const form = useForm();
  const { setError, handleSubmit } = form;
  const childProps = { ...form };

  useLayoutEffect(() => {
    getProfilePic()
  }, [])

  const getProfilePic = async () => {
    const setting = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    const company = setting?.companyLogo;
    const logo = {
      thumb: getUrl(`/settings/${setting?.id}/companyLogo/${company?.id}/admin_single.${company?.extension}`),
      full: getUrl(`/settings/${setting?.id}/companyLogo/${company?.id}/admin_large.${company?.extension}`),
    }
    setSettingLogo(logo.thumb)
  }

  const { t } = useTranslation("main");
  useEffect(() => {
    loadFingerprint()

  }, [])

  const loadFingerprint = async () => {

    try {
      const fpPromise = await FingerprintJS.load()
      const visitorId = await fpPromise.get()
      const deviceFingerprint = visitorId.visitorId
      if (deviceFingerprint) {
        setDeviceFingerprint(deviceFingerprint)
      }
      else {
        throw new Error(
          "Please disable adblock as it prevents us from identifying your device for security purposes"
        );
      }
    }
    catch (err: any) {
      alert("Please disable adblock as it prevents us from identifying your device for security purposes")
    }
  }

  async function save({ email, password, rememberMe }: LoginRequest) {
    setErrorMessage(null);
    setProcessing(true);

    try {
      // login user
      if (deviceFingerprint !== undefined) {
        const { accessToken, chatToken, user, deviceStatus, device } = await Api.post<LoginResponse, LoginRequest>(
          Endpoint.LOGIN,
          { email, password, rememberMe, deviceFingerprint },
          ApiType.Rest
        );
        setAccessToken(accessToken)
        setUser(user)
        setDevice(device)
        setStep(LoginSteps.Verification)
        setProcessing(false);
        if (deviceStatus === DeviceStatus.Active) {

          AuthService.setAccessToken(accessToken as string, rememberMe as boolean);
          Api.init();
          AuthService.setUser(user as User);
          AuthService.setDevice(device as Device);
          history.push(DashboardRoute.Overview);
        }
      }
      else {

        throw new Error(
          "Please disable adblock as it prevents us from identifying your device for security purposes"
        );

      }
    }
    catch (err: any) {
      if (deviceFingerprint === undefined) {
        setError("device", { type: "auth", message: "Please disable adblock as it prevents us from identifying your device for security purposes" });
        setErrorMessage(err.message);
        setProcessing(false);
        alert("Please disable adblock as it prevents us from identifying your device for security purposes")

      }
      else {
        setError("email", { type: "auth", message: "Invalid credentials" });
        setError("password", { type: "auth", message: "Invalid credentials" });
        setErrorMessage(err.message);
        console.log("Login Error", err);
        setProcessing(false);
      }
    }

  }

  async function checkVerification(form: LoginVerificationResponse) {
    setErrorMessage(null);
    setProcessing(true);

    try {

      const response = await Api.post<any, LoginVerificationRequest>(
        Endpoint.VERIFICATION_CODE,
        {
          verificationCode: form.verificationCode,
          deviceFingerprint: deviceFingerprint as string
        },
        ApiType.Rest
      );

      if (response === true) {

        setProcessing(false);

        AuthService.setAccessToken(accessToken as string, rememberMe as boolean);
        Api.init();
        AuthService.setUser(user as User);
        AuthService.setDevice(device as Device);
        history.push(DashboardRoute.Overview);
      }
    } catch (err: any) {

      setProcessing(false);
      alert("Verification Code is incorrect")
    }

  }

  return (
    <>
      {step === LoginSteps.Login ?
        <>
          <div className="row">
            {AuthService.isLoggedIn() && <Redirect to="/" />}
            {(process.env.REACT_APP_BKG)?.toLowerCase() === "white" ? <div className="auth-subcontainer left col-md-6">
              { settingLogo && <img src={settingLogo} className="logo" alt="Logo" /> }
              <div className="warning">
                <h2>{t("auth.login.warning")}</h2>
                <p>{t("auth.login.warningMsg")}</p>
              </div>
            </div> : <div className="auth-subcontainer left col-md-6" style={{ background: "black" }}>

              { settingLogo && <img src={settingLogo} className="logo" alt="Logo" /> }
              <div className="warning">
                <h2>{t("auth.login.warning")}</h2>
                <p style={{ background: "#b4bac5" }}>{t("auth.login.warningMsg")}</p>
              </div>

            </div>}
            <div className="auth-subcontainer right col-md-6">
              <form
                className="row"
                onSubmit={handleSubmit((form) => save(form as LoginRequest))}
                onChange={() => setErrorMessage(null)}
              >
                <fieldset className="form-group">
                  <legend>
                    <span>{process.env.REACT_APP_NAME}</span>
                  </legend>
                  <div className="form-desc">{t("auth.login.loginMsg")}</div>
                  <FormInput
                    label={t("auth.login.email")}
                    name="email"
                    type="email"
                    autoFocus
                    tabIndex={1}
                    validation={{ required: true }}
                    {...childProps}
                  />
                  <FormInput
                    label={t("auth.login.password")}
                    name="password"
                    type="password"
                    tabIndex={2}
                    validation={{ required: true }}
                    {...childProps}
                  />
                  <div
                    style={{
                      marginBottom: "10px",
                      textDecoration: "underline",
                    }}
                  >
                    <NavLink activeClassName="active" to="/auth/forgot">
                      {t("auth.login.forgot-password")}
                    </NavLink>
                  </div>
                  {!processing ? (
                    <div className="buttons-w" >
                      <button className="btn btn-primary" type="submit">
                        {t("auth.login.title")}
                      </button>

                      <div className="form-check-inline">
                        <label className="form-check-label" style={{ marginTop: "-22px", marginRight: '5px' }}>
                          {t("auth.login.remeber-me")}
                        </label>
                        <FormInput
                          name="rememberMe"
                          label=""
                          type="checkbox"
                          className="form-check-input"
                          validation={{ required: false }}
                          {...childProps}
                        />

                      </div>
                    </div>

                  ) : (
                    <Loading loading={processing} />
                  )}
                </fieldset>
              </form>
            </div>
          </div>
        </> : null}
      {step === LoginSteps.Verification ?
        <>
          <div className="row">
            {(process.env.REACT_APP_BKG)?.toLowerCase() === "white" ? <div className="auth-subcontainer left col-md-6">
              { settingLogo && <img src={settingLogo} className="logo" alt="Logo" /> }
              <div className="warning">
                <h2>{t("auth.login.warning")}</h2>
                <p>{t("auth.login.warningMsg")}</p>
              </div>
            </div> : <div className="auth-subcontainer left col-md-6" style={{ background: "black" }}>

              { settingLogo && <img src={settingLogo} className="logo" alt="Logo" /> }
              <div className="warning">
                <h2>{t("auth.login.warning")}</h2>
                <p style={{ background: "#b4bac5" }}>{t("auth.login.warningMsg")}</p>
              </div>

            </div>}
            <div className="auth-subcontainer right col-md-6">
              <form
                className="row"
                onSubmit={handleSubmit((form) => checkVerification(form as LoginVerificationResponse))}
                onChange={() => setErrorMessage(null)}
              >
                <fieldset className="form-group">
                  <legend>
                    <span>{process.env.REACT_APP_NAME}</span>
                  </legend>
                  <div className="form-desc">{t("auth.login.loginMsg")}</div>
                  <FormInput
                    label={t("auth.login.verificationCode")}
                    name="verificationCode"
                    type="text"
                    autoFocus
                    tabIndex={1}
                    validation={{ required: true }}
                    {...childProps}
                  />

                  {!processing ? (
                    <div className="buttons-w" >
                      <button className="btn btn-primary" type="submit">
                        {t("auth.login.submit")}
                      </button>


                    </div>

                  ) : (
                    <Loading loading={processing} />
                  )}
                </fieldset>
              </form>
            </div>
          </div>

        </> : null}

    </>
  );
}

